/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaAnchor } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import { useDialog } from "src/context/DialogProvider";
import useApi from "src/hook/useApi";
import { UserVesselsGetResponse } from "src/model/api/response/UserVesselsGetResponse";
import { VesselSummaryGetResponse } from "src/model/api/response/VesselSummaryGetResponse";
import { formatDate, formatDateBetween, getBizError } from "src/util/ecUtil";

import MaintenanceHistoryArea from "./MaintenanceHistoryArea";
import OwnShipList from "./OwnShipList";
import TaskList from "./TaskList";
import TopLogo from "./TopLogo";

function OwnShipListHome() {
  //API使用宣言
  const api = useApi();

  //ダイアログ使用宣言
  const showDialog = useDialog();
  // 翻訳
  const { t: tc } = useTranslation("OwnShipListHome");

  //クエリパラメータの取り出し
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const queryImoNo = query.get("imono") ?? ""; // IMO番号

  //個船リスト
  const [userVesselList, setuserVesselList] = useState<
    UserVesselsGetResponse[]
  >([]);

  const [selectedVesselName, setSelectedVesselName] = useState<string>(""); //選択中船名

  //個船情報
  const [vesselSummary, setVesselSummary] =
    useState<VesselSummaryGetResponse>();

  //個船リスト全体のstyle
  const ownShipListStyle: React.CSSProperties = {
    backgroundColor: "#F5F5F5",
    height: "100%",
    width: `calc(100% - 268px)`,
    position: "absolute",
    top: "0px",
    left: "280px",
  };

  //LCMのstyle
  const lcmStyle = {
    width: "71%",
    height: "18%",
    marginRight: "auto",
    borderRadius: "15px",
    backgroundColor: "white",
    boxShadow: "6px 10px 20px #0000000D",
  };

  // LCMのリンクのstyle
  const lcmLinkCss = css`
    color: inherit;
    text-decoration: none;
    :hover {
      color: inherit;
      font-weight: bold;
    }
  `;

  //Maintenance Historyのstyle
  const maintenanceHistoryStyle: React.CSSProperties = {
    position: "absolute",
    width: "25%",
    height: "85%",
    top: "78px",
    left: "73%",
    borderRadius: "15px",
    backgroundColor: "white",
    boxShadow: "6px 10px 20px #0000000D",
  };

  //hover時のcss
  const hoverCss = css`
    background-color: #f4f5f8;
    :hover {
      background-color: #e1e3ea;
      .viewmore {
        background-color: #5d5d5d;
        span {
          filter: invert(1);
        }
      }
    }
  `;

  const monthDayFormat = "MM/DD";

  //ViewMoreボタン
  function viewMore(id: string) {
    return (
      <Nav.Item css={hoverCss} style={{ borderRadius: "15px" }}>
        <Nav.Link
          className="viewmore"
          as={NavLink}
          to={viewMoreUrl(id)}
          style={{
            color: "black",
            borderRadius: "15px",
            fontSize: "13px",
          }}
          data-cy="VIEW MOREリンク"
        >
          <span>VIEW MORE</span>
        </Nav.Link>
      </Nav.Item>
    );
  }

  //View Moreクリック時の遷移先URL
  const viewMoreUrl = (id: string): string => {
    switch (id) {
      case "QUOTATION":
        return `/quotation-list?imono=${queryImoNo}`;
      case "ORDER":
        return `/order-list?imono=${queryImoNo}`;
      default:
        return "";
    }
  };

  //金額を日本円表示に変換する
  function japanCurrencyFormatter(cost: number | undefined): string {
    if (cost && !isNaN(cost)) {
      const num = cost;
      return num.toLocaleString(undefined, {
        style: "currency",
        currency: "JPY",
      });
    }
    return "";
  }

  //見積か注文を表示する
  function QuotationOrOrderCard(title: string, data: any[], styleLeft: string) {
    return (
      <div
        className="mx-1 mt-2"
        style={{
          position: "absolute",
          width: "35%",
          height: "32%",
          left: styleLeft,
          marginRight: "auto",
          borderRadius: "15px",
          backgroundColor: "white",
          boxShadow: "6px 10px 20px #0000000D",
        }}
      >
        <div className="pt-3 px-4 d-flex justify-content-between">
          <div style={{ fontWeight: "bold" }}>{title}</div>
          {viewMore(title)}
        </div>
        <div
          className="overflow-auto"
          style={{ width: "100%", height: "70%", position: "relative" }}
        >
          <div
            style={{
              textAlign: "right",
              fontSize: "10px",
              paddingRight: "5%",
              paddingTop: "2%",
            }}
          >
            <span className="px-1">Request</span> <span>Return</span>
          </div>

          {data?.map((it: any, index: number) => {
            return (
              <div
                key={index}
                className="my-1 p-1"
                css={hoverCss}
                style={{
                  display: "flex",
                  width: "90%",
                  minHeight: "23%",
                  maxHeight: "auto",
                  margin: "auto",
                  borderRadius: "5px",
                  fontSize: "13px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div data-cy="見積注文情報">
                  <span className="px-2" data-cy="見積注文日">
                    {title === "QUOTATION"
                      ? formatDate(it.quotationDateTime, monthDayFormat)
                      : formatDate(it.orderDateTime, monthDayFormat)}
                  </span>
                  {it.quantity !== 0 ? (
                    <>
                      {it.productGroupName}
                      {it.quantity > 1 ? tc("など") : ""}
                      &nbsp;{it.quantity}
                      {tc("点")}
                    </>
                  ) : (
                    <>{tc("明細なし")}</>
                  )}
                </div>
                {requestAndReturnCircle(it.bemacReturnFlg)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  //見積/注文の状態を表す丸
  const requestAndReturnCircle = (bemacReturnFlg: boolean) => {
    return (
      <div className="d-flex px-2" data-cy="見積注文状態">
        <div
          style={{
            marginRight: "25px",
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: !bemacReturnFlg ? "#F7992C" : "white",
          }}
          data-cy="見積注文状態・依頼中"
        />
        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: bemacReturnFlg ? "#99B7E8" : "white",
          }}
          data-cy="見積注文状態・回答後"
        />
      </div>
    );
  };

  //クエリパラメータのimoNo変更時のみ実行
  useEffect(() => {
    api
      .get(`/api/v1/top-summary`)
      .then((response) => {
        setuserVesselList(response.data.userVesselList);
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E062" });
        }
      });
    api
      .get(`/api/v1/vessels/${queryImoNo}/summary`)
      .then((response) => {
        setVesselSummary(response.data);
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E062" });
        }
      });
    api
      .get(`/api/v1/vessels/${queryImoNo}`)
      .then((response) => {
        setSelectedVesselName(response.data.vesselName);
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E062" });
        }
      });
  }, [queryImoNo]);

  return (
    <>
      {/*Top・個船用ロゴ・ユーザーアイコン */}
      <TopLogo />

      <div style={ownShipListStyle}>
        <div
          className="px-1 pt-4"
          style={{
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          <FaAnchor className="px-1" size={"35px"} />
          <span className="px-3" data-cy="選択中船舶名">
            {selectedVesselName}
          </span>
        </div>

        {/* 個船リスト*/}
        <OwnShipList userVesselList={userVesselList} />

        {/* LCM */}
        <div className="mx-1 mt-3 mb-2" style={lcmStyle}>
          <div className="p-3" data-cy="定期点検タイトル">
            <span style={{ fontWeight: "bold" }}>LIFE CYCLE MAINTENANCE</span>
            <div style={{ display: "flex" }}>
              <div
                className="overflow-auto"
                style={{ width: "50%", height: "10vh" }}
              >
                {vesselSummary?.lcmStatus ? (
                  <div
                    className="py-2"
                    style={{ textDecoration: "underline", marginRight: "auto" }}
                  >
                    {vesselSummary?.lcmStatus}
                  </div>
                ) : (
                  <div className="pt-2"></div>
                )}
                <NavLink
                  to={`/lcm-top?show-contents=repair-schedule&imono=${queryImoNo}`}
                  css={lcmLinkCss}
                >
                  <div className="pb-2">
                    {tc("ドック")}
                    <span className="px-4" data-cy="LCMドック">
                      {vesselSummary?.dockName}
                    </span>
                  </div>
                  <div className="pb-2">
                    {tc("日時")}
                    <span className="px-4" data-cy="LCM日時">
                      {formatDateBetween(
                        vesselSummary?.dockScheduleStart,
                        vesselSummary?.dockScheduleEnd
                      )}
                    </span>
                  </div>
                </NavLink>
              </div>

              <div className="px-3">
                {vesselSummary?.inspectionDetail ? (
                  <div
                    className="pt-2 pb-1"
                    style={{ textDecoration: "underline" }}
                    data-cy="LCM詳細"
                  >
                    {vesselSummary?.inspectionDetail}
                  </div>
                ) : (
                  <div className="py-3"></div>
                )}
                <div>
                  {tc("想定コスト")}
                  <span
                    className="px-4"
                    style={{ fontSize: "24px", fontWeight: "bold" }}
                    data-cy="LCM想定コスト"
                  >
                    {japanCurrencyFormatter(vesselSummary?.inspectionCost)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*タスクリスト */}
        <div
          style={{ width: "71%", height: "30%", margin: "2.5vh 2vw 1.7vh 1vw" }}
        >
          <TaskList
            summary={vesselSummary}
            height={"100%"}
            imoNo={queryImoNo}
          />
        </div>

        {/*見積 */}
        {QuotationOrOrderCard("QUOTATION", vesselSummary?.quotations!, "0%")}

        {/*注文 */}
        {QuotationOrOrderCard("ORDER", vesselSummary?.orders!, "36%")}

        {/*Maintenance History */}
        <div
          className="mx-1 mt-2 p-3"
          style={maintenanceHistoryStyle}
          data-cy="不具合管理タイトル"
        >
          <MaintenanceHistoryArea vesselSummary={vesselSummary} />
        </div>
      </div>
    </>
  );
}

export default OwnShipListHome;
