import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import PageSizeSelectorEn from "./locales/en/component/common/PageSizeSelector.json";
import S3UploaderEn from "./locales/en/component/common/S3Uploader.json";
import SearchAreaEn from "./locales/en/component/common/SearchArea.json";
import HomeEn from "./locales/en/component/home/Home.json";
import MaintenanceHistoryAreaEn from "./locales/en/component/home/MaintenanceHistoryArea.json";
import OwnShipListHomeEn from "./locales/en/component/home/OwnShipListHome.json";
import TaskListEn from "./locales/en/component/home/TaskList.json";
import TopLogoEn from "./locales/en/component/home/TopLogo.json";
import InspectionResultEn from "./locales/en/component/lcm/InspectionResult.json";
import InspectionResultPictureEn from "./locales/en/component/lcm/InspectionResultPicture.json";
import LcmCalendarListEn from "./locales/en/component/lcm/LcmCalendarList.json";
import LcmTopEn from "./locales/en/component/lcm/LcmTop.json";
import PartsReplaceListEn from "./locales/en/component/lcm/PartsReplaceList.json";
import RecommendDetailEn from "./locales/en/component/lcm/RecommendDetail.json";
import RecommendListEn from "./locales/en/component/lcm/RecommendList.json";
import RepairCostSimulationEn from "./locales/en/component/lcm/RepairCostSimulation.json";
import RepairScheduleEn from "./locales/en/component/lcm/RepairSchedule.json";
import RepairScheduleMaintenanceCostEn from "./locales/en/component/lcm/RepairScheduleMaintenanceCost.json";
import RepairScheduleMaintenanceScheduleEn from "./locales/en/component/lcm/RepairScheduleMaintenanceSchedule.json";
import RepairScheduleRecommendProductEn from "./locales/en/component/lcm/RepairScheduleRecommendProduct.json";
import MaintenanceHistoryListEn from "./locales/en/component/maintenance/MaintenanceHistoryList.json";
import MaintenanceHistoryListDataAreaEn from "./locales/en/component/maintenance/MaintenanceHistoryListDataArea.json";
import OrderAnalysisEn from "./locales/en/component/order/OrderAnalysis.json";
import OrderAnalysisTopEn from "./locales/en/component/order/OrderAnalysisTop.json";
import OrderDetailEn from "./locales/en/component/order/OrderDetail.json";
import OrderDetailDeliveryEn from "./locales/en/component/order/OrderDetailDelivery.json";
import OrderDetailHeaderEn from "./locales/en/component/order/OrderDetailHeader.json";
import OrderDetailPriceEn from "./locales/en/component/order/OrderDetailPrice.json";
import OrderListEn from "./locales/en/component/order/OrderList.json";
import OrderProductListEn from "./locales/en/component/order/OrderProductList.json";
import QuotationDetailEn from "./locales/en/component/order/QuotationDetail.json";
import QuotationListEn from "./locales/en/component/order/QuotationList.json";
import QuotationOrderHistoryEn from "./locales/en/component/order/QuotationOrderHistory.json";
import CompanyDetailEn from "./locales/en/component/setting/CompanyDetail.json";
import ProductDetailEn from "./locales/en/component/setting/ProductDetail.json";
import ProductListEn from "./locales/en/component/setting/ProductList.json";
import ProductListModalEn from "./locales/en/component/setting/ProductListModal.json";
import ServiceNewsDetailEn from "./locales/en/component/setting/ServiceNewsDetail.json";
import ServiceNewsListEn from "./locales/en/component/setting/ServiceNewsList.json";
import ServiceNewsListModalEn from "./locales/en/component/setting/ServiceNewsListModal.json";
import UserDetailEn from "./locales/en/component/setting/UserDetail.json";
import UserListEn from "./locales/en/component/setting/UserList.json";
import messageEn from "./locales/en/message.json";
import messageJa from "./locales/ja/message.json";

const defaultLanguageClass = localStorage.getItem("lang") ?? "ja";

i18n.use(initReactI18next).init({
  lng: defaultLanguageClass, // デフォルト言語
  fallbackLng: "ja", // 指定辞書に無い場合に使用する言語
  interpolation: {
    escapeValue: false, // Reactではエスケープ不要
  },
  defaultNS: "message", // デフォルト名前空間
  resources: {
    ja: {
      message: messageJa,
    },
    en: {
      message: messageEn,
      PageSizeSelector: PageSizeSelectorEn,
      S3Uploader: S3UploaderEn,
      SearchArea: SearchAreaEn,
      Home: HomeEn,
      MaintenanceHistoryArea: MaintenanceHistoryAreaEn,
      OwnShipListHome: OwnShipListHomeEn,
      TaskList: TaskListEn,
      TopLogo: TopLogoEn,
      InspectionResult: InspectionResultEn,
      InspectionResultPicture: InspectionResultPictureEn,
      LcmCalendarList: LcmCalendarListEn,
      LcmTop: LcmTopEn,
      PartsReplaceList: PartsReplaceListEn,
      RecommendDetail: RecommendDetailEn,
      RecommendList: RecommendListEn,
      RepairCostSimulation: RepairCostSimulationEn,
      RepairSchedule: RepairScheduleEn,
      RepairScheduleMaintenanceCost: RepairScheduleMaintenanceCostEn,
      RepairScheduleMaintenanceSchedule: RepairScheduleMaintenanceScheduleEn,
      RepairScheduleRecommendProduct: RepairScheduleRecommendProductEn,
      MaintenanceHistoryList: MaintenanceHistoryListEn,
      MaintenanceHistoryListDataArea: MaintenanceHistoryListDataAreaEn,
      OrderAnalysis: OrderAnalysisEn,
      OrderAnalysisTop: OrderAnalysisTopEn,
      OrderDetail: OrderDetailEn,
      OrderDetailDelivery: OrderDetailDeliveryEn,
      OrderDetailHeader: OrderDetailHeaderEn,
      OrderDetailPrice: OrderDetailPriceEn,
      OrderList: OrderListEn,
      OrderProductList: OrderProductListEn,
      QuotationDetail: QuotationDetailEn,
      QuotationList: QuotationListEn,
      QuotationOrderHistory: QuotationOrderHistoryEn,
      CompanyDetail: CompanyDetailEn,
      ProductDetail: ProductDetailEn,
      ProductList: ProductListEn,
      ProductListModal: ProductListModalEn,
      ServiceNewsDetail: ServiceNewsDetailEn,
      ServiceNewsList: ServiceNewsListEn,
      ServiceNewsListModal: ServiceNewsListModalEn,
      UserDetail: UserDetailEn,
      UserList: UserListEn,
    },
  },
});

export default i18n;
