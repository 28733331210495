import { ReactNode } from "react";
import { Card, Collapse, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
  children: ReactNode;
  className?: string;
  show: boolean;
  toolTip?: ReactNode;
  onChangeShow: (show: boolean) => void;
};
function SearchArea(props: Props) {
  // 翻訳
  const { t: tc } = useTranslation("SearchArea");

  const switchButtonStyle = {
    transform: props.show ? "rotate(180deg)" : "",
    transition: "transform 150ms ease",
    backgroundColor: "white",
  };
  const title = tc("検索条件");
  return (
    <div className={props.className + " mx-auto"} data-cy="検索条件エリア">
      <Card className="b-radius">
        <Card.Header
          className={
            (props.show ? "b-radius-top" : "b-radius") + "hover-pointer"
          }
          style={{
            backgroundColor: "white",
            marginLeft: "0px",
            marginRight: "0px",
          }}
          onClick={(e) => {
            props.onChangeShow(!props.show);
          }}
          data-cy="検索条件エリアヘッダ"
        >
          <Stack direction="horizontal">
            <div style={{ fontWeight: "bold" }}>
              {title}
              {props.toolTip ?? ""}
            </div>
            <div className="ms-auto">
              <div style={switchButtonStyle}>▼</div>
            </div>
          </Stack>
        </Card.Header>
        <Card.Body className="p-0 bg-white b-radius-bottom">
          <Collapse in={props.show}>
            <div>
              <div className="px-3" style={{ marginTop: "1rem" }}>
                {props.children}
              </div>
            </div>
          </Collapse>
        </Card.Body>
      </Card>
    </div>
  );
}

export default SearchArea;
