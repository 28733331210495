import {
  CellValueChangedEvent,
  ICellRendererParams,
  RowClassParams,
  RowDragEndEvent,
  RowDragEnterEvent,
  RowNode,
  ValueFormatterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { FullWidth } from "ag-grid-community/dist/lib/components/framework/componentTypes";
import { AgGridReact } from "ag-grid-react";
import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaUnderline } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi";
import { MdOutlineContentCopy } from "react-icons/md";
import { useSplashMessage } from "src/hook/useSplashMessage";
import { CompanyGetResponse } from "src/model/api/response/CompanyGetResponse";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { OrdersPostRequest } from "../../model/api/request/OrdersPostRequest";
import { OrdersPostRequestSub } from "../../model/api/request/OrdersPostRequestSub";
import { OrderGetResponse } from "../../model/api/response/OrderGetResponse";
import { OrderGetResponseSub } from "../../model/api/response/OrderGetResponseSub";
import { ProductGetResponse } from "../../model/api/response/ProductGetResponse";
import { ProductsGetResponse } from "../../model/api/response/ProductsGetResponse";
import { QuotationGetResponse } from "../../model/api/response/QuotationGetResponse";
import {
  AgDatePicker,
  agNumberFormatter,
  agNumberSetter,
  agYmdFormatter,
  defaultColDef,
} from "../../util/AgGridUtil";
import {
  OrderFactor,
  OrderStatus,
  ProductCodeConst,
} from "../../util/Constant";
import { calculateTax } from "../../util/TaxUtil";
import { formatDate } from "../../util/ecUtil";
import S3Uploader, { S3UploaderHandles } from "../common/S3Uploader";
import ProductListModal, {
  ProductListModalHandles,
} from "../setting/ProductListModal";
import OrderDetailDelivery from "./OrderDetailDelivery";
import OrderDetailHeader, {
  OrderDetailChangeEvent,
  OrderDetailType,
} from "./OrderDetailHeader";
import OrderDetailPrice from "./OrderDetailPrice";

interface FormValues {
  quotationNo: number | undefined;
  statusClassValue: string;
  orderClassValue: string;
  companyCode: string;
  companyName: string | undefined;
  imoNo: string | undefined;
  vesselName: string | undefined;
  vesselBuilder: string | undefined;
  yardNo: string | undefined;
  requestDeliveryDate: string | undefined;
  customerOrderNo1: string | undefined;
  customerOrderNo2: string | undefined;
  requestReplyDeadline: string | undefined;
  deliveryName: string;
  deliveryPostalCode: string;
  deliveryAddress: string;
  deliveryTel: string;
  dispatchFlg: boolean;
  dispatchPlace: string | undefined;
  dispatchDate: string | undefined;
  orderUserId: number | undefined;
  orderUserName: string | undefined;
  orderComment: string | undefined;
  orderDateTime: string | undefined;
  replyUserId: number | undefined;
  replyUserName: string | undefined;
  replyComment: string | undefined;
  replyDateTime: string | undefined;
  replyDeliveryDate: string | undefined;
  replyInstallmentDeliveryFlg: boolean;
  replySubtotalPrice: number | undefined;
  replyDeliveryPrice: number | undefined;
  replyDiscountPrice: number | undefined;
  replyTax: number | undefined;
  replyTaxPrice: number | undefined;
  replyTotalPrice: number | undefined;
  checkUserId: number | undefined;
  checkDateTime: string | undefined;
  r3Wbs: string | undefined;
  r3ShippingDate: string | undefined;
  r3Comment: string | undefined;
  s3Prefix: string | undefined;
  updateDateTime: string | undefined;
  shippedComment: string | undefined;
  quotationDateTime: string | undefined;
  quotationComment: string | undefined;
  dockName: string | undefined;
  dockScheduleStart: string | undefined;
  dockScheduleEnd: string | undefined;
  isChangeOrder: boolean;
  isRequestChangeOrder: boolean;
  rejectUserId: number | undefined;
  rejectDateTime: string | undefined;
  problemProduct: string | undefined;
  problemDetail: string | undefined;
  compQuotationFlg: boolean;
  isReject: boolean;
  isCompTempSave: boolean;
  bemacFreeComment: string | undefined;
  customerQuotationNo1: string | undefined;
  customerQuotationNo2: string | undefined;
}

// 明細データの型
type FormRowValues = {
  key: number;
  orderDetailNo: number;
  isDbRow: boolean;
  productCode: string;
  productGroupName: string | undefined;
  spec: string | undefined;
  unit: string | undefined;
  note: string | undefined;
  quantity: number | undefined;
  unitCost: number | undefined;
  detailSubTotal: number | undefined;
  replyQuantity: number | undefined;
  replyUnitCost: number | undefined;
  replyDetailSubTotal: number | undefined;
  replyDeliveryDate: string | undefined;
  shippingQuantity: number | undefined;
  confirmFlg: boolean;
  confirmUserId: number | undefined;
  confirmDateTime: string | undefined;
  cancelOrderQuantity: number | undefined;
  cancelUserId: number | undefined;
  cancelDateTime: string | undefined;
  r3WbsLv2: string | undefined;
  r3OrderNo: string | undefined;
  r3ShippingDate: string | undefined;
  referenceDetailNo: number | undefined;
  cancelSubTotal: number | undefined;
  isExpenses?: boolean;
  isDeliveryCost?: boolean;
  existFlg: boolean;
};

type ShowParam = {
  orderNo?: number;
  initialImoNo?: string;
  initialVesselName?: string;
  initialVesselBuilder?: string;
  initialYardNo?: string;
  quotationData?: OrderGetResponse;
  customerQuotationNo1?: string;
  customerQuotationNo2?: string;
};

export type OrderParam = {
  customerOrderNo1?: string;
  customerOrderNo2?: string;
  customerQuotationNo1?: string;
  customerQuotationNo2?: string;
};

// 公開する関数の定義
export interface OrderDetailHandles {
  show(param: ShowParam): void;
}

type saveButtonName =
  | "一時保存"
  | "注文中一時保存"
  | "発注"
  | "注文取消依頼"
  | "注文取消承認"
  | "注文停止"
  | "注文変更依頼"
  | "完工見積"
  | "完工見積一時保存"
  | "注文承認"
  | "注文変更"
  | "更新"
  | "出荷後キャンセル"
  | "差戻";
type Props = {
  onClickSaveButton?: (
    buttonName: saveButtonName,
    retOrderParm: OrderParam
  ) => void;
  fromQuotationDetail?: boolean;
  quotationOrderHistoryFlg?: boolean;
};

// ファイル添付見出し
const fileAttachTitleStyle: React.CSSProperties = {
  color: "white",
  backgroundColor: "#0d1532",
  alignItems: "center",
  textAlign: "center",
  padding: "0.375rem 0.75em",
  whiteSpace: "nowrap",
  borderRadius: "0.5rem",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.5",
};

// ヘッダ部見出しの幅
const itemTitleStyle = { width: "11rem" };

// エリアごとのCSSクラス
const areaClass = "p-1 bg-white b-detail-items";
const textAreaClass =
  "form-control textarea b-input-text-square b-input-comment-height";
/** 注文詳細コンポーネント */
const OrderDetail = forwardRef<OrderDetailHandles, Props>((props, ref) => {
  // ダイアログ使用宣言
  const showDialog = useDialog();
  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();
  // API使用宣言
  const api = useApi();
  // ユーザ情報
  const auth = useAuthUserContext();
  // メッセージ
  const { t } = useTranslation();
  // 翻訳
  const { t: tc } = useTranslation("OrderDetail");

  // state宣言
  const [orderNo, setOrderNo] = useState<number | undefined>();
  const [show, setShow] = useState(false);
  const [tempSubtotalPrice, setTempSubtotalPrice] = useState(0); // 仮注文金額
  const [shippingAllFlg, setShippingAllFlg] = useState(false); // 全出荷ボタンフラグ
  const [initialData, setInitialData] = useState<FormValues>(); // 画面表示時のデータ
  const [initialDetails, setInitialDetails] = useState<FormRowValues[]>([]); // 画面表示時の明細データ

  //クリップボードにコピー関数
  const copyToClipboard = async () => {
    //　明細の内容をコピー
    let copyText =
      "No" +
      "	" +
      "品目コード" +
      "	" +
      "品名" +
      "	" +
      "型式" +
      "	" +
      "単価" +
      "	" +
      "数量" +
      "	" +
      "単位" +
      "	" +
      "小計" +
      "	" +
      "明細備考" +
      "	" +
      "単価(BEMAC回答)" +
      "	" +
      "数量(BEMAC回答)" +
      "	" +
      "小計(BEMAC回答)" +
      "\n";
    inpDetails.forEach((inpData) => {
      copyText += (inpData.orderDetailNo ?? "") + "	";
      copyText += (inpData.productCode ?? "") + "	";
      copyText += (inpData.productGroupName ?? "") + "	";
      copyText += (inpData.spec ?? "") + "	";
      copyText += (inpData.unitCost ?? "") + "	";
      copyText += (inpData.quantity ?? "") + "	";
      copyText += (inpData.unit ?? "") + "	";
      copyText += (inpData.detailSubTotal ?? "") + "	";
      copyText += (inpData.note ?? "") + "	";
      copyText += (inpData.replyUnitCost ?? "") + "	";
      copyText += (inpData.replyQuantity ?? "") + "	";
      copyText += (inpData.replyDetailSubTotal ?? "") + "	";
      copyText += "\n";
    });
    await global.navigator.clipboard.writeText(copyText);
  };

  // 入力項目
  const [inpOrder, setInpOrder] = useState<FormValues>(generateDraft());
  // 入力項目：明細
  const [inpDetails, setInpDetails] = useState<FormRowValues[]>([]);

  // ユーザ入力有無
  const [formChanged, setFormChanged] = useState<boolean>(false);
  // 読み取り専用フラグ
  const [readOnly, setReadOnly] = useState(false);

  // 参照
  const gridRef = useRef<AgGridReact>(null); // 明細
  const productListModalRef = useRef<ProductListModalHandles>(null); // 商品一覧
  const uploaderCustomerRef = useRef<S3UploaderHandles>(null); // ファイル添付（顧客）
  const uploaderBemacRef = useRef<S3UploaderHandles>(null); // ファイル添付（BEMAC）
  const uploaderShippingRef = useRef<S3UploaderHandles>(null); // ファイル添付（送り状）

  function generateDraft(): FormValues {
    return {
      quotationNo: undefined,
      statusClassValue: OrderStatus.DRAFT,
      orderClassValue: OrderFactor.EC_PARTS,
      companyCode: auth.user().companyCode,
      companyName: auth.user().companyName,
      imoNo: "",
      vesselName: "",
      vesselBuilder: "",
      yardNo: "",
      requestDeliveryDate: "",
      customerOrderNo1: "",
      customerOrderNo2: "",
      requestReplyDeadline: "",
      deliveryName: "",
      deliveryPostalCode: "",
      deliveryAddress: "",
      deliveryTel: "",
      dispatchFlg: false,
      dispatchPlace: "",
      dispatchDate: "",
      orderUserId: auth.user().userId,
      orderUserName: auth.user().userName,
      orderComment: "",
      orderDateTime: "",
      replyUserId: auth.user().bemacFlg ? auth.user().userId : undefined,
      replyUserName: auth.user().bemacFlg ? auth.user().userName : undefined,
      replyComment: "",
      replyDateTime: "",
      replyDeliveryDate: "",
      replyInstallmentDeliveryFlg: false,
      replySubtotalPrice: undefined,
      replyDeliveryPrice: undefined,
      replyDiscountPrice: 0,
      replyTax: 0,
      replyTaxPrice: undefined,
      replyTotalPrice: undefined,
      checkUserId: undefined,
      checkDateTime: "",
      r3Wbs: "",
      r3ShippingDate: "",
      r3Comment: "",
      updateDateTime: "",
      shippedComment: "",
      quotationDateTime: undefined,
      quotationComment: undefined,
      dockName: "",
      dockScheduleStart: "",
      dockScheduleEnd: "",
      s3Prefix: generateS3Prefix(),
      isChangeOrder: false,
      isRequestChangeOrder: false,
      rejectUserId: undefined,
      rejectDateTime: undefined,
      problemProduct: undefined,
      problemDetail: undefined,
      compQuotationFlg: false,
      isReject: false,
      isCompTempSave: false,
      bemacFreeComment: "",
      customerQuotationNo1: undefined,
      customerQuotationNo2: undefined,
    };
  }

  // S3に登録するオブジェクトキーのプリフィクスを採番
  function generateS3Prefix() {
    const datetime = formatDate(new Date(), "YYYYMMDDHHmmss");
    return `order/${datetime}-${auth.user().userId}/`;
  }

  // 公開する関数の実装
  useImperativeHandle(ref, () => ({
    show(param: ShowParam) {
      // 初期化
      setFormChanged(false);
      setOrderNo(param.orderNo);
      setShippingAllFlg(false);

      if (param.orderNo) {
        // 注文番号が設定されていたらAPIから初期化する
        api
          .get<OrderGetResponse>(`/api/v1/orders/${param.orderNo}`)
          .then((response) => {
            const newOrder = mapOrderGetResponseToFormValues(response.data);
            const detailRows = mapOrderGetResponseSubToFormRowValues(
              response.data.orderDetail,
              true
            );

            // 見積番号が設定されていたら見積の情報を検索する
            if (newOrder.quotationNo) {
              api
                .get<QuotationGetResponse>(
                  `/api/v1/quotations/${newOrder.quotationNo}`
                )
                .then((responseQuotation) => {
                  newOrder.customerQuotationNo1 =
                    responseQuotation.data.customerQuotationNo1 === null
                      ? undefined
                      : responseQuotation.data.customerQuotationNo1;
                  newOrder.customerQuotationNo2 =
                    responseQuotation.data.customerQuotationNo2 === null
                      ? undefined
                      : responseQuotation.data.customerQuotationNo2;
                  saveInitialDataAndShow(newOrder, detailRows);
                })
                .catch((error) => showDialog({ error }));
            } else {
              saveInitialDataAndShow(newOrder, detailRows);
            }

            // BEMACユーザかつ注文中の場合一時保存のデータが存在する(注文変更トランを参照)可能性があるため、初期データを注文データで更新する
            if (
              auth.user().bemacFlg &&
              newOrder.statusClassValue === OrderStatus.ORDERED
            ) {
              api
                .get<OrderGetResponse>(
                  `/api/v1/orders/${param.orderNo}?notGetChangeDataFlg=true`
                )
                .then((response) => {
                  const newOrderInitial = mapOrderGetResponseToFormValues(
                    response.data
                  );
                  const detailRowsInitial =
                    mapOrderGetResponseSubToFormRowValues(
                      response.data.orderDetail,
                      true
                    );
                  saveInitialDataAndShow(
                    newOrderInitial,
                    detailRowsInitial,
                    true
                  );
                })
                .catch((error) => showDialog({ error }));
            }
          })
          .catch((error) => showDialog({ error }));
      } else if (param.quotationData) {
        // 見積データが設定されていたらそのまま表示する
        const newOrder = mapOrderGetResponseToFormValues(param.quotationData);
        const detailRows = mapOrderGetResponseSubToFormRowValues(
          param.quotationData.orderDetail,
          true
        );

        if (param.customerQuotationNo1) {
          newOrder.customerQuotationNo1 = param.customerQuotationNo1;
        }

        if (param.customerQuotationNo2) {
          newOrder.customerQuotationNo2 = param.customerQuotationNo2;
        }

        saveInitialDataAndShow(newOrder, detailRows);
      } else {
        // 新規作成の場合
        const newOrder = generateDraft();
        if (param.initialImoNo) {
          newOrder.imoNo = param.initialImoNo;
        }
        if (param.initialVesselName) {
          newOrder.vesselName = param.initialVesselName;
        }
        if (param.initialVesselBuilder) {
          newOrder.vesselBuilder = param.initialVesselBuilder;
        }
        if (param.initialYardNo) {
          newOrder.yardNo = param.initialYardNo;
        }
        const detailRows: FormRowValues[] = [];
        // stateに保存してモーダルを表示状態にする
        saveInitialDataAndShow(newOrder, detailRows);
      }
    },
  }));

  function mapOrderGetResponseToFormValues(src: OrderGetResponse) {
    const mapped: FormValues = {
      quotationNo: src.quotationNo ?? undefined,
      statusClassValue: src.statusClassValue,
      orderClassValue: src.orderClassValue ?? undefined,
      companyCode: src.companyCode,
      companyName: src.companyName ?? undefined,
      imoNo: src.imoNo ?? undefined,
      vesselName: src.vesselName ?? undefined,
      vesselBuilder: src.vesselBuilder ?? undefined,
      yardNo: src.yardNo ?? undefined,
      requestDeliveryDate: src.requestDeliveryDate ?? undefined,
      customerOrderNo1: src.customerOrderNo1 ?? undefined,
      customerOrderNo2: src.customerOrderNo2 ?? undefined,
      requestReplyDeadline: src.requestReplyDeadline ?? undefined,
      deliveryName: src.deliveryName,
      deliveryPostalCode: src.deliveryPostalCode,
      deliveryAddress: src.deliveryAddress,
      deliveryTel: src.deliveryTel,
      dispatchFlg: src.dispatchFlg,
      dispatchPlace: src.dispatchPlace ?? undefined,
      dispatchDate: src.dispatchDate ?? undefined,
      orderUserId: src.orderUserId,
      orderUserName: src.orderUserName,
      orderComment: src.orderComment ?? undefined,
      orderDateTime: src.orderDateTime ?? undefined,
      replyUserId: src.replyUserId ?? undefined,
      replyUserName: src.replyUserName ?? undefined,
      replyComment: src.replyComment ?? undefined,
      replyDateTime: src.replyDateTime ?? undefined,
      replyDeliveryDate: src.replyDeliveryDate ?? undefined,
      replyInstallmentDeliveryFlg: src.replyInstallmentDeliveryFlg,
      replySubtotalPrice: src.replySubtotalPrice ?? undefined,
      replyDeliveryPrice: src.replyDeliveryPrice ?? undefined,
      replyDiscountPrice: src.replyDiscountPrice ?? undefined,
      replyTax: src.replyTax ?? undefined,
      replyTaxPrice: src.replyTaxPrice ?? undefined,
      replyTotalPrice: src.replyTotalPrice ?? undefined,
      checkUserId: src.checkUserId ?? undefined,
      checkDateTime: src.checkDateTime ?? undefined,
      r3Wbs: src.r3Wbs ?? undefined,
      r3ShippingDate: src.r3ShippingDate ?? undefined,
      r3Comment: src.r3Comment ?? undefined,
      // s3Prefixが付いてなければ付けなおす
      s3Prefix: src.s3Prefix ?? generateS3Prefix(),
      updateDateTime: src.updateDateTime ?? undefined,
      shippedComment: src.shippedComment ?? undefined,
      quotationDateTime: src.quotationDateTime ?? undefined,
      quotationComment: src.quotationComment ?? undefined,
      dockName: src.dockName ?? undefined,
      dockScheduleStart: src.dockScheduleStart ?? undefined,
      dockScheduleEnd: src.dockScheduleEnd ?? undefined,
      isChangeOrder: false,
      isRequestChangeOrder: false,
      rejectUserId: src.rejectUserId ?? undefined,
      rejectDateTime: src.rejectDateTime ?? undefined,
      problemProduct: src.problemProduct ?? undefined,
      problemDetail: src.problemDetail ?? undefined,
      compQuotationFlg: src.compQuotationFlg,
      isReject: false,
      isCompTempSave: src.isCompTempSave,
      bemacFreeComment: src.bemacFreeComment ?? undefined,
      customerQuotationNo1: undefined,
      customerQuotationNo2: undefined,
    };
    return mapped;
  }

  function mapOrderGetResponseSubToFormRowValues(
    src: OrderGetResponseSub[],
    isDb: boolean
  ) {
    const mappedRows = src.map((it, index) => {
      const newRow: FormRowValues = {
        key: index,
        isDbRow: !auth.user().bemacFlg
          ? isDb
          : inpOrder.statusClassValue === OrderStatus.COMPLETED &&
            it.confirmFlg === false
          ? false
          : isDb,
        orderDetailNo: it.orderDetailNo,
        productCode: it.productCode,
        productGroupName: it.productGroupName ?? undefined,
        spec: it.spec ?? undefined,
        unit: it.unit ?? undefined,
        note: it.note ?? undefined,
        quantity: it.quantity,
        unitCost: it.unitCost ?? undefined,
        detailSubTotal: it.detailSubTotal ?? undefined,
        replyQuantity: it.replyQuantity ?? undefined,
        replyUnitCost: it.replyUnitCost ?? undefined,
        replyDetailSubTotal: it.replyDetailSubTotal ?? undefined,
        replyDeliveryDate: it.replyDeliveryDate ?? undefined,
        shippingQuantity: it.shippingQuantity ?? undefined,
        confirmFlg: it.confirmFlg,
        confirmUserId: it.confirmUserId ?? undefined,
        confirmDateTime: it.confirmDateTime ?? undefined,
        cancelOrderQuantity: it.cancelOrderQuantity ?? undefined,
        cancelUserId: it.cancelUserId ?? undefined,
        cancelDateTime: it.cancelDateTime ?? undefined,
        r3WbsLv2: it.r3WbsLv2 ?? undefined,
        r3OrderNo: it.r3OrderNo ?? undefined,
        r3ShippingDate: it.r3ShippingDate ?? undefined,
        referenceDetailNo: it.referenceDetailNo ?? undefined,
        cancelSubTotal: undefined,
        isExpenses: it.isExpenses,
        isDeliveryCost: it.isDeliveryCost,
        existFlg: true,
      };
      calculateRowSubTotal(newRow); // 小計計算
      return newRow;
    });
    return mappedRows;
  }

  // stateに保存してモーダルを表示状態にする
  function saveInitialDataAndShow(
    order: FormValues,
    detailRows: FormRowValues[],
    updateInitialOnly: boolean = false
  ) {
    if (
      !order.replyUserId &&
      !auth.isEngineer() &&
      auth.user().bemacFlg &&
      order.statusClassValue === OrderStatus.ORDERED
    ) {
      // 注文中はBEMACログインユーザを表示させる
      order.replyUserId = auth.user().userId;
      order.replyUserName = auth.user().userName;
    }
    // stateに保存
    if (!updateInitialOnly) {
      setInpOrder(order);
    }

    // 行追加可能時は新規行を追加
    // 技師以外
    // BEMAC・LCM部品注文以外で注文中、部品手配中、完了(LCM部品注文ば部品交換リストと紐づける為必ず商品一覧から選択する)
    // 顧客・作成中は追加可能
    if (
      !auth.isEngineer() &&
      ((auth.user().bemacFlg &&
        order.orderClassValue !== OrderFactor.LCM_PARTS &&
        (order.statusClassValue === OrderStatus.ORDERED ||
          OrderStatus.ARRANGING === order.statusClassValue ||
          OrderStatus.COMPLETED === order.statusClassValue)) ||
        (!auth.user().bemacFlg && order.statusClassValue === OrderStatus.DRAFT))
    ) {
      //Bemacユーザで注文中かつ技師派遣フラグONで経費行が存在しない場合、空白行と経費行も追加する
      if (
        auth.user().bemacFlg &&
        OrderStatus.ORDERED === order.statusClassValue &&
        order.dispatchFlg &&
        canAddExpensesRow(detailRows)
      ) {
        //「---」行、経費行用の空白行、新規入力用の空白行を追加
        addNewRow(detailRows, [ProductCodeConst.BLANK, ""]);
        if (!updateInitialOnly) {
          setInpDetails(detailRows);
        }

        //経費行を追加
        addExpensesRow(updateInitialOnly ? detailRows : undefined, true);
      } else {
        addNewRow(detailRows);
        if (!updateInitialOnly) {
          setInpDetails(detailRows);
        }
      }
    } else {
      if (!updateInitialOnly) {
        setInpDetails(detailRows);
      }
    }

    // 金額再計算
    if (!updateInitialOnly) {
      calculateTempPrice(detailRows);
      calculateReplyPrice(detailRows);

      // 読み取り専用モードの設定
      setReadOnlyMode(
        order.statusClassValue,
        order.companyCode,
        order.orderUserId
      );
    }

    // 初期データを退避
    setInitialData({ ...order });
    const copiedDetails = detailRows.map((it) => {
      return { ...it };
    });
    setInitialDetails(copiedDetails);

    // 表示
    setShow(true);
  }

  // 読み取り専用モードの設定
  function setReadOnlyMode(
    statusClassValue: string,
    companyCode: string,
    orderUserId: number | undefined
  ) {
    // 見積詳細からの表示の場合は読み取り専用
    if (props.fromQuotationDetail) {
      setReadOnly(true);
      return;
    }

    // 技師は読み取り専用
    if (auth.isEngineer()) {
      setReadOnly(true);
      return;
    }

    switch (statusClassValue) {
      case OrderStatus.STOPPED_SHIPPING: // 取消
      case OrderStatus.CHANGED: // 変更
      case OrderStatus.DENIED_CHANGE: // 停止
        // 編集不可
        setReadOnly(true);
        return;
    }

    if (
      // 顧客ユーザかつ取消依頼中、完了は編集不可
      !auth.user().bemacFlg &&
      (statusClassValue === OrderStatus.REQUESTED_CANCEL ||
        statusClassValue === OrderStatus.COMPLETED)
    ) {
      setReadOnly(true);
      return;
    }

    // 顧客の場合は自身が依頼者でなければ読み取り専用
    if (
      !auth.user().bemacFlg &&
      orderUserId &&
      auth.user().userId !== orderUserId
    ) {
      setReadOnly(true);
      return;
    }

    // その他は編集可
    setReadOnly(false);
  }

  // モーダル表示ハンドラ
  const handleShow = () => {
    // アップローダーの初期化
    uploaderCustomerRef.current?.init(`${inpOrder.s3Prefix}customer/`, false);
    uploaderBemacRef.current?.init(`${inpOrder.s3Prefix}bemac/`, false);
    uploaderShippingRef.current?.init(`${inpOrder.s3Prefix}shipping/`, false);
  };

  // モーダルクローズハンドラ
  const handleClose = () => {
    if (formChanged) {
      showDialog({
        id: "I001",
        confirm: true,
        callback(isOk) {
          if (isOk) {
            setShippingAllFlg(false);
            setInpDetails([]);
            setShow(false);
          }
        },
      });
    } else {
      setShippingAllFlg(false);
      setInpDetails([]);
      setShow(false);
    }
  };

  // 保存系ボタン
  function handleClickActionButton(name: saveButtonName) {
    // 明細の編集を停止
    gridRef.current?.api.stopEditing();

    // 見積詳細から開かれた場合
    if (props.fromQuotationDetail) {
      showDialog({
        id: "I014",
        confirm: true,
        callback: (isOk) => {
          if (isOk) {
            // 見積注文時はファイル登録を実施
            // 添付ファイルを処理
            Promise.all([
              uploaderCustomerRef.current!.save(),
              uploaderBemacRef.current!.save(),
              uploaderShippingRef.current?.save(),
            ]).then(() => {
              afterSaved(name);
            });
          }
        },
      });
      return;
    }

    // 注文承認、更新、出荷後キャンセルの場合は変更チェックの場合は変更チェック
    if (
      (name === "注文承認" || name === "更新" || name === "出荷後キャンセル") &&
      !validateApprove()
    ) {
      // 変更があったらエラー
      if (
        name === "注文承認" ||
        name === "更新" ||
        (!inpOrder.dispatchFlg && name === "出荷後キャンセル")
      ) {
        showDialog({ id: "E070" });
        return;
      } else {
        showDialog({ id: "E086" });
        return;
      }
    }

    if (name === "発注" && inpOrder.requestReplyDeadline) {
      const currentDateTime = parseInt(formatDate(new Date(), "YYYYMMDD"));
      const requestReplyDeadline: number = parseInt(
        inpOrder.requestReplyDeadline?.replaceAll(/-/g, "")
      );
      if (requestReplyDeadline < currentDateTime + 2) {
        // 確認ダイアログを表示
        showDialog({
          id: "E033",
          args: ["発注"],
          confirm: true,
          callback(isOk) {
            if (isOk) {
              saveData(name);
            }
          },
        });
      } else {
        showConfirmMsg(name);
      }
    } else if (
      name === "注文承認" &&
      (Number(inpOrder.replyDiscountPrice) === 0 ||
        Number(inpOrder.replyTax) === 0)
    ) {
      // 割引額もしくは消費税額が0のままだったらエラー
      showDialog({
        id: "E085",
        args: ["注文承認"],
        confirm: true,
        callback(isOk) {
          if (isOk) {
            showConfirmMsg(name);
          }
        },
      });
    } else {
      showConfirmMsg(name);
    }
  }

  function showConfirmMsg(name: saveButtonName) {
    // 確認メッセージ
    const messageId = confirmMessageId(name);
    if (messageId) {
      showDialog({
        id: messageId,
        confirm: true,
        callback: (isOk) => {
          if (isOk) {
            if (!auth.user().bemacFlg && name === "注文変更") {
              // モード切替
              setInpOrder((prev) => ({
                ...prev,
                isChangeOrder: true,
                statusClassValue: OrderStatus.DRAFT,
                orderDateTime: undefined,
              }));
              // 回答から依頼に値を移して明細を組み立てる
              const newDetails = inpDetails.map((detail) => {
                return {
                  ...detail,
                  quantity: detail.replyQuantity,
                  unitCost: detail.replyUnitCost,
                  detailSubTotal: detail.replyDetailSubTotal,
                };
              });
              setInpDetails(newDetails);

              calculateTempPrice(newDetails);
              calculateReplyPrice(newDetails);
            } else {
              saveData(name);
            }
          }
        },
      });
    } else {
      // メッセージなしで保存
      saveData(name);
    }
  }

  function confirmMessageId(name: saveButtonName) {
    const messageIdMap: { name: saveButtonName; messageId: string }[] = [
      { name: "発注", messageId: "I022" },
      { name: "注文取消依頼", messageId: "I023" },
      { name: "注文取消承認", messageId: "I065" },
      { name: "注文停止", messageId: "I063" },
      { name: "注文変更依頼", messageId: "I024" },
      { name: "完工見積", messageId: "I070" },
      { name: "注文承認", messageId: "I025" },
      { name: "注文変更", messageId: "I027" },
      { name: "更新", messageId: "I026" },
      { name: "出荷後キャンセル", messageId: "I029" },
      { name: "差戻", messageId: "I068" },
    ];
    return messageIdMap.find((it) => it.name === name)?.messageId;
  }

  function savedMessageId(name: saveButtonName) {
    const messageIdMap: { name: saveButtonName; messageId: string }[] = [
      { name: "一時保存", messageId: "I045" },
      { name: "注文中一時保存", messageId: "I045" },
      { name: "発注", messageId: "I047" },
      { name: "注文取消依頼", messageId: "I066" },
      { name: "注文取消承認", messageId: "I048" },
      { name: "注文停止", messageId: "I064" },
      { name: "注文変更依頼", messageId: "I049" },
      { name: "完工見積", messageId: "I071" },
      { name: "完工見積一時保存", messageId: "I045" },
      { name: "注文承認", messageId: "I050" },
      { name: "注文変更", messageId: "I052" },
      { name: "更新", messageId: "I051" },
      { name: "出荷後キャンセル", messageId: "I054" },
      { name: "差戻", messageId: "I069" },
    ];
    return messageIdMap.find((it) => it.name === name)?.messageId;
  }

  // データ保存
  function saveData(buttonName: saveButtonName) {
    // 添付ファイルを処理
    const uploadPromises = Promise.all([
      uploaderCustomerRef.current!.save(),
      uploaderBemacRef.current!.save(),
      uploaderShippingRef.current?.save(),
    ]).then(() => {
      // 添付ファイルの処理が正常終了したら注文登録

      // 見積明細
      // 最終行の新規行を除いてリストを再生成
      const newFormRows = [...inpDetails]
        .filter((it) => !isEmptyRow(it))
        .map((it) => {
          const formRow: FormRowValues = { ...it };
          // BEMACは依頼数量を入力できないので0で補完する
          if (auth.user().bemacFlg && !formRow.quantity) {
            formRow.quantity = 0;
            formRow.detailSubTotal = 0;
          }

          //品目コード=「---」の時は単価、数量を0で補完する
          if (
            formRow.productCode &&
            formRow.productCode === ProductCodeConst.BLANK
          ) {
            formRow.quantity = 0;
            formRow.unitCost = 0;
            formRow.replyQuantity = 0;
            formRow.replyUnitCost = 0;
          }

          // 確定出荷数量を編集
          if (buttonName === "発注" && inpOrder.compQuotationFlg) {
            formRow.cancelOrderQuantity = 0;
            formRow.confirmFlg = true;
          }
          const replyQuantity = formRow.replyQuantity ?? 0;
          const cancelOrderQuantity = formRow.cancelOrderQuantity ?? 0;
          formRow.shippingQuantity = formRow.confirmFlg
            ? replyQuantity - cancelOrderQuantity
            : 0;

          // 発注の場合は回答列に初期値セット
          if (buttonName === "発注") {
            if (formRow.productCode !== ProductCodeConst.EXPENSES) {
              formRow.replyQuantity = formRow.quantity;
              formRow.replyUnitCost = formRow.unitCost;
            }
            calculateRowSubTotal(formRow);
          }

          return formRow;
        });
      const requestSubs = newFormRows.map((it) => {
        // リクエスト明細に変換
        const newRow: OrdersPostRequestSub = {
          ...it,
        };
        return newRow;
      });

      const request: OrdersPostRequest = {
        ...inpOrder,
        orderNo: orderNo,
        statusClassValue: getNextStatus(buttonName),
        ordersPostRequestSub: requestSubs,
      };
      // 発注の場合は金額計算
      if (buttonName === "発注") {
        const price = calculateReplyPriceCore(
          inpOrder,
          newFormRows,
          inpOrder.replyDiscountPrice
        );
        request.replySubtotalPrice = price.subtotalPrice;
        request.replyTaxPrice = price.taxPrice;
        request.replyTotalPrice = price.totalPrice;
      }

      if (buttonName === "完工見積") {
        request.compQuotationFlg = true;
      }

      if (
        buttonName === "注文中一時保存" ||
        buttonName === "注文変更依頼" ||
        buttonName === "完工見積" ||
        buttonName === "完工見積一時保存"
      ) {
        // 注文変更依頼フラグON
        request.isRequestChangeOrder = true;
      }

      // 差戻の場合は差戻実施フラグをtrueにする
      if (buttonName === "差戻") {
        request.isReject = true;
      }
      // 注文登録API実行
      return api
        .post(`/api/v1/orders`, request)
        .then((response) => {
          const messageId = savedMessageId(buttonName);
          if (messageId) {
            splashMessage.show(messageId);
          }
          afterSaved(buttonName, response.data.orderNo);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            showDialog({ id: "E027" });
          } else {
            showDialog({ error });
            // 商品マスタの存在チェック
            checkProductCodes();
          }
        });
    });
    return uploadPromises;
  }

  /** 商品マスタの存在チェック */
  async function checkProductCodes() {
    // 注文明細から品目コードを重複なく取得する(空文字等は除く)
    const productCodes = Array.from(
      new Set(
        inpDetails
          .filter(
            (it) =>
              it.productCode.length > 0 &&
              it.productCode !== ProductCodeConst.BLANK
          )
          .map((it) => it.productCode)
      )
    );

    // 商品マスタから商品情報を取得する
    const notExistProductCodes: string[] = [];
    const promises = productCodes.map((productCode) =>
      api
        .get<ProductGetResponse>(`/api/v1/products/${productCode}`)
        .then(() => {})
        .catch((error) => {
          if (error.response.status === 404) {
            notExistProductCodes.push(productCode);
          } else {
            throw error;
          }
        })
    );
    await Promise.all(promises);
    // 注文明細で品目マスタに存在明細の存在フラグを落とす
    setInpDetails((prev) => {
      const rows = prev.map((row) => {
        row.existFlg = !notExistProductCodes.includes(row.productCode ?? "");
        return row;
      });
      return rows;
    });
  }

  function afterSaved(buttonName: saveButtonName, orderNo?: number) {
    setFormChanged(false);

    if (buttonName === "一時保存" || buttonName === "注文中一時保存") {
      setOrderNo(orderNo);

      api
        .get<OrderGetResponse>(`/api/v1/orders/${orderNo}`)
        .then((response) => {
          const newOrder = mapOrderGetResponseToFormValues(response.data);
          const detailRows = mapOrderGetResponseSubToFormRowValues(
            response.data.orderDetail,
            true
          );
          saveInitialDataAndShow(newOrder, detailRows);

          // BEMACユーザかつ注文中の場合一時保存のデータが存在する(注文変更トランを参照)可能性があるため、初期データを注文データで更新する
          if (
            auth.user().bemacFlg &&
            newOrder.statusClassValue === OrderStatus.ORDERED
          ) {
            api
              .get<OrderGetResponse>(
                `/api/v1/orders/${orderNo}?notGetChangeDataFlg=true`
              )
              .then((response) => {
                const newOrderInitial = mapOrderGetResponseToFormValues(
                  response.data
                );
                const detailRowsInitial = mapOrderGetResponseSubToFormRowValues(
                  response.data.orderDetail,
                  true
                );
                saveInitialDataAndShow(
                  newOrderInitial,
                  detailRowsInitial,
                  true
                );
              })
              .catch((error) => showDialog({ error }));
          }
        })
        .catch((error) => showDialog({ error }));
    } else {
      // 一時保存以外は画面を閉じる
      setShow(false);
    }
    if (props.onClickSaveButton) {
      const retOrderParam: OrderParam = {
        customerOrderNo1:
          inpOrder.customerOrderNo1 === ""
            ? undefined
            : inpOrder.customerOrderNo1,
        customerOrderNo2:
          inpOrder.customerOrderNo2 === ""
            ? undefined
            : inpOrder.customerOrderNo2,
        customerQuotationNo1:
          inpOrder.customerQuotationNo1 === ""
            ? undefined
            : inpOrder.customerQuotationNo1,
        customerQuotationNo2:
          inpOrder.customerQuotationNo2 === ""
            ? undefined
            : inpOrder.customerQuotationNo2,
      };

      props.onClickSaveButton(buttonName, retOrderParam);
    }
  }

  function getNextStatus(name: saveButtonName) {
    switch (name) {
      case "発注":
        return OrderStatus.ORDERED;
      case "注文取消依頼":
        return OrderStatus.REQUESTED_CANCEL;
      case "注文取消承認":
        return OrderStatus.STOPPED_SHIPPING;
      case "注文停止":
        return OrderStatus.DENIED_CHANGE;
      case "注文変更依頼":
      case "完工見積":
        return OrderStatus.REQUESTED_CHANGE;
      case "注文承認":
        return OrderStatus.ARRANGING;
      case "注文変更":
        return OrderStatus.CHANGED;
      case "更新":
        if (canChangeComplete()) {
          // 全明細出荷したら完了
          return OrderStatus.COMPLETED;
        }
        return inpOrder.statusClassValue;
      default:
        return inpOrder.statusClassValue;
    }
  }

  function canChangeComplete() {
    // 明細のうち一つでも出荷済みでなければfalse
    for (const row of inpDetails) {
      if (!row.confirmFlg && row.isDbRow) {
        return false;
      }
    }
    return true;
  }

  // OrderDetail共通コンポーネントエリアの変更イベント
  function handleChangeHeader(e: OrderDetailChangeEvent) {
    setFormChanged(true);

    setInpOrder((prev) => ({ ...prev, [e.name]: e.value }));

    if (e.name === "dispatchFlg") {
      // 技術者派遣の場合
      if (e.value) {
        // トグルONになったら経費入力のためにスペーサー行を追加(BEMACユーザのみ)
        if (auth.user().bemacFlg && canAddExpensesRow(inpDetails)) {
          setInpDetails((prev) => {
            const rows = [...prev];
            // 最終行（空行のはず）にスペーサーをセット
            rows[rows.length - 1].productCode = ProductCodeConst.BLANK;
            // 空行を2つ追加
            addNewRow(rows, [""]);
            return rows;
          });
          //経費行を追加
          addExpensesRow(undefined, false);
        }
      }
    }
    if (e.name === "replyDiscountPrice" || e.name === "replyTax") {
      // 割引額または税率の場合、合計金額再計算
      calculateReplyPrice(inpDetails);
    }
  }

  // テキストボックス変更
  function handleChangeInput(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setFormChanged(true);
    setInpOrder((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  // グリッドのセル値変更イベント
  const onCellValueChanged = (event: CellValueChangedEvent) => {
    setFormChanged(true);
    if (event.column.getColId() === "confirmFlg") {
      // 出荷ボタンの場合はスキップ
      return;
    }
    // 自身が最終行なら一行追加する
    if (event.rowIndex === inpDetails.length - 1 && canAddNewRow()) {
      setInpDetails((prev) => {
        const newRows = prev.concat();
        addNewRow(newRows);
        return newRows;
      });
    }
    // 品目コード変更時は商品単一取得API実行
    if (event.column.getColId() === "productCode") {
      const productCode = encodeURIComponent(event.value + "");
      if (productCode && productCode !== ProductCodeConst.BLANK) {
        const query = `onlyLcmCosts=${
          inpOrder.orderClassValue === OrderFactor.LCM_PARTS ? "true" : "false"
        }`;
        api
          .get<ProductGetResponse>(`/api/v1/products/${productCode}?${query}`)
          .then((response) => {
            setInpDetails((prev) => {
              const newRows = prev.map((it, index) => {
                if (index === event.rowIndex) {
                  const data = response.data;
                  const newRow = prev[event.rowIndex ?? 0];
                  newRow.productGroupName = data.productGroupName;
                  newRow.spec = data.spec;
                  newRow.unitCost = data.unitPrice ?? undefined;
                  newRow.unit = data.unit;
                  newRow.note = data.note;
                  calculateRowSubTotal(newRow); // 小計計算
                  return newRow;
                } else {
                  return it;
                }
              });
              // 金額再計算
              calculateTempPrice(newRows);
              calculateReplyPrice(newRows);

              return newRows;
            });
          })
          .catch((error) => {
            if (error.response.status === 404) {
              showDialog({ id: "E029" });
            } else {
              showDialog({ error });
            }
          });
      } else {
        //品目コードクリア時、または---に変更時はレコードの値もクリアする
        setInpDetails((prev) => {
          const newRows = prev.map((it, index) => {
            if (index === event.rowIndex) {
              let newRow = {
                productCode: productCode ?? "",
                key: it.key,
                isDbRow: it.isDbRow,
                orderDetailNo: it.orderDetailNo,
                productGroupName: undefined,
                spec: undefined,
                unit: undefined,
                note: undefined,
                quantity: undefined,
                unitCost: undefined,
                detailSubTotal: undefined,
                replyQuantity: undefined,
                replyUnitCost: undefined,
                replyDetailSubTotal: undefined,
                replyDeliveryDate: undefined,
                shippingQuantity: undefined,
                confirmFlg: false,
                confirmUserId: undefined,
                confirmDateTime: undefined,
                cancelOrderQuantity: undefined,
                cancelUserId: undefined,
                cancelDateTime: undefined,
                r3WbsLv2: undefined,
                r3OrderNo: undefined,
                r3ShippingDate: undefined,
                referenceDetailNo: undefined,
                cancelSubTotal: undefined,
                existFlg: true,
              };
              return newRow;
            } else {
              return it;
            }
          });
          // 金額再計算
          calculateTempPrice(newRows);
          calculateReplyPrice(newRows);
          return newRows;
        });
      }
    }

    // 数量、単価変更時は仮注文金額再計算
    if (
      event.column.getColId() === "quantity" ||
      event.column.getColId() === "unitCost"
    ) {
      setInpDetails((prev) => {
        const newRows = prev.concat();
        newRows.forEach((row) => {
          calculateRowSubTotal(row);
        });
        calculateTempPrice(newRows);
        return newRows;
      });
    }
    // BEMAC回答の数量、単価変更時は金額再計算
    if (
      event.column.getColId() === "replyQuantity" ||
      event.column.getColId() === "replyUnitCost"
    ) {
      setInpDetails((prev) => {
        const newRows = prev.concat();
        newRows.forEach((row) => {
          calculateRowSubTotal(row); // 小計計算
        });
        // 合計金額再計算
        calculateReplyPrice(newRows);
        return newRows;
      });
    }
    // キャンセル数、単価変更時はキャンセル金額計算
    if (
      event.column.getColId() === "cancelOrderQuantity" ||
      event.column.getColId() === "replyUnitCost"
    ) {
      setInpDetails((prev) => {
        const newRows = prev.concat();
        newRows.forEach((row) => {
          calculateRowSubTotal(row); // 小計計算
        });
        return newRows;
      });
    }
  };

  // 行の中の小計を計算
  function calculateRowSubTotal(row: FormRowValues) {
    // 注文明細小計
    if (row.unitCost === undefined || row.quantity === undefined) {
      row.detailSubTotal = undefined;
    } else {
      row.detailSubTotal = (row.unitCost ?? 0) * (row.quantity ?? 0);
    }
    // 注文回答明細小計
    if (row.replyUnitCost === undefined || row.replyQuantity === undefined) {
      row.replyDetailSubTotal = undefined;
    } else {
      row.replyDetailSubTotal =
        (row.replyUnitCost ?? 0) * (row.replyQuantity ?? 0);
    }
    // キャンセル数量
    if (
      row.replyUnitCost === undefined ||
      row.cancelOrderQuantity === undefined
    ) {
      row.cancelSubTotal = undefined;
    } else {
      row.cancelSubTotal =
        (row.replyUnitCost ?? 0) * (row.cancelOrderQuantity ?? 0);
    }
  }

  // 行追加可否
  function canAddNewRow() {
    if (readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (inpOrder.isChangeOrder) {
      // 注文変更モードは編集不可
      return false;
    }

    // BEMAC・注文中、部品手配中、完了は可
    if (
      auth.user().bemacFlg &&
      (inpOrder.statusClassValue === OrderStatus.ORDERED ||
        inpOrder.statusClassValue === OrderStatus.ARRANGING ||
        inpOrder.statusClassValue === OrderStatus.COMPLETED)
    ) {
      return true;
    }

    // 顧客・作成中は可
    if (
      !auth.user().bemacFlg &&
      inpOrder.statusClassValue === OrderStatus.DRAFT
    ) {
      return true;
    }

    return false;
  }

  // 新規行追加
  function addNewRow(rows: FormRowValues[], productCodes?: string[]) {
    const getMaxKey = (rows: FormRowValues[]) => {
      if (rows.length > 0) {
        return Math.max(...rows.map((it) => it.key));
      }
      return 0;
    };
    // 行を追加(複数も可)
    let maxKey = getMaxKey(rows);
    productCodes?.forEach((productCode) => {
      let newRow = {
        key: maxKey + 1,
        orderDetailNo: rows.length + 1,
        isDbRow: false,
        productCode: productCode,
        productGroupName: undefined,
        spec: undefined,
        unit: undefined,
        note: undefined,
        quantity: undefined,
        unitCost: undefined,
        detailSubTotal: undefined,
        replyQuantity: undefined,
        replyUnitCost: undefined,
        replyDetailSubTotal: undefined,
        replyDeliveryDate: undefined,
        shippingQuantity: undefined,
        confirmFlg: false,
        confirmUserId: undefined,
        confirmDateTime: undefined,
        cancelOrderQuantity: undefined,
        cancelUserId: undefined,
        cancelDateTime: undefined,
        r3WbsLv2: undefined,
        r3OrderNo: undefined,
        r3ShippingDate: undefined,
        referenceDetailNo: undefined,
        cancelSubTotal: undefined,
        existFlg: true,
      };
      rows.push(newRow);
      maxKey++;
    });

    // 最終行を追加
    const blankRow = {
      key: maxKey + 1,
      orderDetailNo: rows.length + 1,
      isDbRow: false,
      productCode: "",
      productGroupName: undefined,
      spec: undefined,
      unit: undefined,
      note: undefined,
      quantity: undefined,
      unitCost: undefined,
      detailSubTotal: undefined,
      replyUnitCost: undefined,
      replyQuantity: undefined,
      replyDetailSubTotal: undefined,
      replyDeliveryDate: undefined,
      shippingQuantity: undefined,
      confirmFlg: false,
      confirmUserId: undefined,
      confirmDateTime: undefined,
      cancelOrderQuantity: undefined,
      cancelUserId: undefined,
      cancelDateTime: undefined,
      r3WbsLv2: undefined,
      r3OrderNo: undefined,
      r3ShippingDate: undefined,
      referenceDetailNo: undefined,
      cancelSubTotal: undefined,
      existFlg: true,
    };
    rows.push(blankRow);
  }

  //経費行を追加する
  function addExpensesRow(
    details: FormRowValues[] | undefined, //初期値だけ設定したい場合にのみ指定する
    initialFlg: boolean
  ) {
    //経費
    const productCode = ProductCodeConst.EXPENSES;

    //商品単一取得API実行
    api
      .get<ProductGetResponse>(`/api/v1/products/${productCode}`)
      .then((response) => {
        if (!details) {
          setInpDetails((prev) => {
            const newRows = prev.map((it, index) => {
              if (index === prev.length - 2) {
                const data = response.data;
                const newRow = prev[prev.length ? prev.length - 2 : 0];
                createExpensesRow(newRow, data);
                return newRow;
              } else {
                return it;
              }
            });
            // 金額再計算
            calculateTempPrice(newRows);
            calculateReplyPrice(newRows);
            if (initialFlg) {
              const copiedDetails = newRows.map((it) => {
                return { ...it };
              });
              setInitialDetails(copiedDetails);
            }

            return newRows;
          });
        } else {
          // detailsは初期データのみを変更したい場合にのみ設定する
          const newRows = details.map((it, index) => {
            if (index === details.length - 2) {
              const data = response.data;
              const newRow = details[details.length ? details.length - 2 : 0];
              createExpensesRow(newRow, data);
              return newRow;
            } else {
              return it;
            }
          });
          const copiedDetails = newRows.map((it) => {
            return { ...it };
          });
          setInitialDetails(copiedDetails);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          showDialog({ id: "E029" });
        } else {
          showDialog({ error });
        }
      });
  }

  //経費行オブジェクト編集
  function createExpensesRow(newRow: FormRowValues, data: ProductGetResponse) {
    newRow.productCode = data.productCode;
    newRow.productGroupName = data.productGroupName;
    newRow.spec = data.spec;
    newRow.unitCost = data.unitPrice ?? undefined;
    newRow.unit = data.unit;
    newRow.note = data.note;
    newRow.detailSubTotal = (data.unitPrice ?? 0) * (newRow.quantity ?? 0);
    newRow.replyQuantity = 1;
    newRow.replyUnitCost = 0;
    calculateRowSubTotal(newRow); // 小計計算
  }

  //経費行追加可否判定
  function canAddExpensesRow(rows: FormRowValues[]) {
    return (
      rows.filter((it) => it.productCode === ProductCodeConst.EXPENSES)
        .length === 0
    );
  }

  // 合計金額再計算
  function calculateReplyPrice(gridRows: FormRowValues[]) {
    setInpOrder((prev) => {
      const price = calculateReplyPriceCore(
        prev,
        gridRows,
        prev.replyDiscountPrice
      );

      return {
        ...prev,
        replySubtotalPrice: price.subtotalPrice,
        replyTaxPrice: price.taxPrice,
        replyTotalPrice: price.totalPrice,
      };
    });
  }
  function calculateReplyPriceCore(
    order: FormValues,
    gridRows: FormRowValues[],
    replyDiscountPrice?: number
  ) {
    let subtotalPrice = 0;
    gridRows.forEach((row) => {
      subtotalPrice += row.replyDetailSubTotal ?? 0;
    });
    const subtotalMinusDiscount = subtotalPrice - (replyDiscountPrice ?? 0);
    const taxPrice = calculateTax(subtotalMinusDiscount, order.replyTax);
    const totalPrice = subtotalMinusDiscount + taxPrice;

    return { subtotalPrice, taxPrice, totalPrice };
  }

  /** キャンセル金額取得 */
  function getCancelTotalPrice() {
    let sum = 0;
    inpDetails.forEach((row) => {
      if (row.cancelSubTotal) {
        sum += row.cancelSubTotal;
      }
    });
    sum = sum + calculateTax(sum, inpOrder.replyTax); // 税込みにする
    return sum;
  }

  /** キャンセル後総計取得 */
  function getTotalPriceWithCancle() {
    let sum = 0;
    if (inpOrder.replyTotalPrice) {
      sum = inpOrder.replyTotalPrice - getCancelTotalPrice();
    }
    return sum;
  }

  function isEmptyRow(row: FormRowValues) {
    // 入力項目がすべて空の場合は空行とみなす
    if (row.productCode) {
      return false;
    }
    if (row.spec) {
      return false;
    }
    if (row.quantity) {
      return false;
    }
    if (row.unit) {
      return false;
    }
    if (row.replyUnitCost) {
      return false;
    }
    if (row.replyQuantity) {
      return false;
    }

    return true;
  }

  // 添付ファイル使用可否判定（顧客のみ）
  function editableFileUpload() {
    if (readOnly && !props.fromQuotationDetail) {
      // 読み取り専用モードで注文変更でない時は編集不可
      return false;
    }
    if (inpOrder.isChangeOrder) {
      // 注文変更モードの時は編集不可
      return false;
    }
    if (auth.user().bemacFlg) {
      // 顧客のみ編集可能
      return false;
    }
    if (inpOrder.statusClassValue === OrderStatus.ARRANGING) {
      // 部品手配中は編集不可
      return false;
    }

    // その他の場合編集可能
    return true;
  }
  // 添付ファイル（送り状）使用可否判定
  function editableShippingFileUpload() {
    if (readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (!auth.user().bemacFlg) {
      // 顧客は不可
      return false;
    }
    if (inpOrder.statusClassValue === OrderStatus.ARRANGING) {
      // 「手配中」の場合編集可能
      return true;
    }

    // その他の場合不可
    return false;
  }

  // お客様備考編集可否判定
  function editableUserComment() {
    if (auth.user().bemacFlg) {
      // BEMACは編集不可
      return false;
    }
    if (inpOrder.isChangeOrder) {
      // 注文変更モードの時は編集不可
      return false;
    }

    //部品手配中かつログインユーザ＝注文依頼者なら編集可(readOnlyより優先度高)
    if (
      OrderStatus.ARRANGING === inpOrder.statusClassValue &&
      inpOrder.orderUserId &&
      auth.user().userId === inpOrder.orderUserId
    ) {
      return true;
    }

    if (readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }

    //部品手配中は編集可(readOnlyより優先度高)
    if (OrderStatus.ARRANGING === inpOrder.statusClassValue) {
      return true;
    }

    // 作成中、注文中、否認は編集可
    return [
      OrderStatus.DRAFT,
      OrderStatus.ORDERED,
      OrderStatus.REQUESTED_CHANGE,
    ].includes(inpOrder.statusClassValue);
  }

  // お客様備考注釈表示条件(注文取消)
  function showsUserCommentCancel() {
    if (auth.user().bemacFlg) {
      // BEMACは非表示
      return false;
    }

    // 注文中、部品手配中の場合表示
    if (
      inpOrder.statusClassValue === OrderStatus.ORDERED ||
      inpOrder.statusClassValue === OrderStatus.ARRANGING
    ) {
      return true;
    }
    // その他は非表示
    return false;
  }

  // お客様備考注釈表示条件(注文停止)
  function showsUserCommentDeniedChange() {
    if (auth.user().bemacFlg) {
      // BEMACは非表示
      return false;
    }

    // 否認(注文変更依頼後)の場合表示
    if (inpOrder.statusClassValue === OrderStatus.REQUESTED_CHANGE) {
      return true;
    }
    // その他は非表示
    return false;
  }

  // 注文回答コメント編集可否判定
  function editableBemacReplyComment() {
    if (!auth.user().bemacFlg) {
      // 顧客は編集不可
      return false;
    }

    if (readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }

    //「注文中」→「取消依頼中」の場合編集可
    if (
      inpOrder.statusClassValue === OrderStatus.REQUESTED_CANCEL &&
      !inpOrder.replyDateTime
    ) {
      return true;
    }

    // 注文中のみ編集可
    return inpOrder.statusClassValue === OrderStatus.ORDERED;
  }

  // フリーメモ編集可否判定
  function editableBemacFreeComment() {
    if (!auth.user().bemacFlg) {
      // 顧客は編集不可
      return false;
    }

    if (readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }

    // 否認、停止、変更、取消時は編集不可
    return ![
      OrderStatus.REQUESTED_CHANGE,
      OrderStatus.CHANGED,
      OrderStatus.DENIED_CHANGE,
      OrderStatus.STOPPED_SHIPPING,
    ].includes(inpOrder.statusClassValue);
  }

  // 出荷完了コメント編集可否判定
  function editableBemacShippingComment() {
    if (!auth.user().bemacFlg) {
      // 顧客は編集不可
      return false;
    }
    if (readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }

    //注文取消依頼中かつ回答日時がnullでなければ編集可(=部品手配中経由の注文取消依頼)
    if (
      inpOrder.statusClassValue === OrderStatus.REQUESTED_CANCEL &&
      inpOrder.replyDateTime
    ) {
      return true;
    }

    // 部品手配中、完了のみ編集可
    return (
      inpOrder.statusClassValue === OrderStatus.ARRANGING ||
      inpOrder.statusClassValue === OrderStatus.COMPLETED
    );
  }

  // 商品一覧で部品を選択
  function handleSelectProducts(products: ProductsGetResponse[]) {
    setFormChanged(true);
    // 最終行の新規行を除いてリストを再生成
    const rows = inpDetails.concat();
    rows.pop();
    // 選択された部品を追加
    let nextKey = Math.max(...inpDetails.map((it) => it.key)) + 1;
    let nextNo = rows.length + 1;

    const vesselProductDetailNoList = inpDetails
      .filter((it) => it.referenceDetailNo)
      .map((row) => {
        return row.referenceDetailNo;
      });

    products.forEach((it) => {
      // LCMの場合部品交換リストからすでに選択済みの商品の場合除外
      if (
        inpOrder.orderClassValue === OrderFactor.LCM_PARTS &&
        vesselProductDetailNoList?.includes(it.detailNo)
      ) {
        return;
      }
      const newRow: FormRowValues = {
        key: nextKey++,
        isDbRow: false,
        orderDetailNo: nextNo++,
        productCode: it.productCode,
        productGroupName: it.productGroupName,
        spec: it.spec,
        unit: it.unit,
        note: it.note,
        quantity: auth.user().bemacFlg ? undefined : 1,
        unitCost: it.unitPrice,
        detailSubTotal: undefined,
        replyQuantity: auth.user().bemacFlg ? 1 : undefined,
        replyUnitCost: auth.user().bemacFlg ? it.unitPrice : undefined,
        replyDetailSubTotal: undefined,
        replyDeliveryDate: undefined,
        shippingQuantity: undefined,
        confirmFlg: false,
        confirmUserId: undefined,
        confirmDateTime: undefined,
        cancelOrderQuantity: undefined,
        cancelUserId: undefined,
        cancelDateTime: undefined,
        r3WbsLv2: undefined,
        r3OrderNo: undefined,
        r3ShippingDate: undefined,
        referenceDetailNo: it.detailNo,
        cancelSubTotal: undefined,
        existFlg: true,
      };
      calculateRowSubTotal(newRow); // 小計を計算
      // 追加
      rows.push(newRow);
    });
    // 新規行を追加
    addNewRow(rows);
    // stateに保存
    setInpDetails(rows);
    // 金額再計算
    calculateTempPrice(rows);
    calculateReplyPrice(rows);
  }

  // 注文書PDFボタンクリック
  function handleClickOutputPdf() {
    // 保存してなかったらエラー
    if (!orderNo || formChanged) {
      showDialog({
        id: "E084",
        args: ["注文"],
      });
      return;
    }

    api
      .get(`/api/v1/order-pdf/${orderNo}`, {
        responseType: "blob",
      })
      .then((it) => {
        const pdf = new Blob([it.data], { type: "application/pdf" });
        const pdfURL = URL.createObjectURL(pdf);
        window.open(pdfURL);
      })
      .catch((error) => {
        showDialog({ error });
      });
  }

  // 完工見積書PDFボタンクリック
  function handleClickOutputCompQuotationPdf() {
    // 保存してなかったらエラー
    if (!orderNo || formChanged) {
      showDialog({
        id: "E084",
        args: ["注文"],
      });
      return;
    }

    api
      .get(`/api/v1/comp-quotation-pdf/${orderNo}`, {
        responseType: "blob",
      })
      .then((it) => {
        const pdf = new Blob([it.data], { type: "application/pdf" });
        const pdfURL = URL.createObjectURL(pdf);
        window.open(pdfURL);
      })
      .catch((error) => {
        showDialog({ error });
      });
  }

  // 注文承認チェック（初期状態から変更がないか）
  function validateApprove() {
    const isChanged = (
      initial: string | number | boolean | undefined,
      current: string | number | boolean | undefined
    ) => {
      if (!initial && !current) {
        // ともに未入力
        return false;
      }
      if ((initial && !current) || (!initial && current)) {
        // 片方が未入力
        return true;
      }

      if (initial && current) {
        if (initial === current) {
          return false;
        } else {
          return true;
        }
      }
    };

    // 変更チェックする項目
    const names = [
      "dispatchFlg",
      "dispatchPlace",
      "dispatchDate",
      "replyInstallmentDeliveryFlg",
      "replyDiscountPrice",
      "replyTax",
    ];

    // 項目ごとループ
    for (const name of names) {
      // 項目名で値を取り出せるようにする
      const key = name as keyof FormValues;

      const initialValue = initialData ? initialData[key] : undefined;
      const currentValue = inpOrder[key];

      if (isChanged(initialValue, currentValue)) {
        return false;
      }
    }

    // 明細部のチェック
    const namesOfDetail = [
      "productCode",
      "spec",
      "quantity",
      "replyQuantity",
      "replyUnitCost",
      "replyDeliveryDate",
    ];
    if (inpDetails.length !== initialDetails.length) {
      // 明細数が異なる
      return false;
    }
    // 行ごとループ
    for (const index in inpDetails) {
      const initialRow = initialDetails[index];
      const currentRow = inpDetails[index];
      // 項目ごとループ
      for (const name of namesOfDetail) {
        // 項目名で値を取り出せるようにする
        const key = name as keyof FormRowValues;

        const initialValue = initialRow[key];
        const currentValue = currentRow[key];

        if (isChanged(initialValue, currentValue)) {
          return false;
        }
      }
    }
    return true;
  }

  // 仮注文金額再計算
  function calculateTempPrice(gridRows: FormRowValues[]) {
    let subtotal = 0;
    gridRows.forEach((row) => {
      subtotal += row.detailSubTotal ?? 0;
    });
    setTempSubtotalPrice(subtotal);
  }

  // 行ドラッグ可否判定
  function canDragRow() {
    if (
      readOnly ||
      (!auth.user().bemacFlg && OrderStatus.DRAFT !== inpOrder.statusClassValue)
    ) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (inpOrder.isChangeOrder) {
      // 注文変更モードの時は編集不可
      return false;
    }
    return true;
  }

  // 技師一般ユーザかどうか
  function isGeneralEngineer() {
    return auth.isEngineer() && !auth.user().adminFlg;
  }

  // 仮注文金額エリア表示判定
  function showsTempPrice() {
    // 顧客ユーザかつ未承認
    return (
      !auth.user().bemacFlg &&
      !props.fromQuotationDetail &&
      !inpOrder.quotationNo &&
      !inpOrder.replyDateTime &&
      !inpOrder.replyDiscountPrice &&
      !inpOrder.replyTax &&
      inpOrder.statusClassValue !== OrderStatus.REQUESTED_CHANGE
    );
  }

  // セルの文字を赤くする判定
  function showsChangeColor(row: ICellRendererParams<FormRowValues>) {
    if (inpOrder.statusClassValue !== OrderStatus.REQUESTED_CHANGE) {
      // 否認以外は適用しない
      return false;
    }

    const data = row.data!;
    const isBemacRow = data.quantity === 0;
    const hasChangedCost = data.replyUnitCost !== data.unitCost;
    const hasChangedQuantity = data.replyQuantity !== data.quantity;
    const hasChangedSubTotal = data.replyDetailSubTotal !== data.detailSubTotal;

    // 依頼と回答で値が異なるものを赤文字にする
    switch (row.colDef?.field) {
      case "replyUnitCost":
        return isBemacRow || hasChangedCost;
      case "replyQuantity":
        return isBemacRow || hasChangedQuantity;
      case "replyDetailSubTotal":
        return isBemacRow || hasChangedSubTotal;
      default:
        return isBemacRow;
    }
  }

  // 列編集可否判定
  function editableColumn(row: ICellRendererParams<FormRowValues>) {
    if (readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (inpOrder.isChangeOrder) {
      // 注文変更モードの時は編集不可
      return false;
    }

    const data = row.data!;

    // 品目コード入力済みの場合は単位入力不可
    if (data.productCode && row.colDef?.field === "unit") {
      return false;
    }

    // 依頼数量はBEMACまたは品目コード=「---」の場合は入力不可
    if (row.colDef?.field === "quantity") {
      if (
        auth.user().bemacFlg ||
        data?.productCode === ProductCodeConst.BLANK
      ) {
        return false;
      }
    }

    if (inpOrder.statusClassValue === OrderStatus.DRAFT) {
      // 作成中は編集可
      return true;
    } else if (
      inpOrder.statusClassValue === OrderStatus.ORDERED ||
      inpOrder.statusClassValue === OrderStatus.ARRANGING ||
      inpOrder.statusClassValue === OrderStatus.COMPLETED
    ) {
      // 注文中、部品手配中、完了は自分が追加した行のみ編集可
      // （注文中、部品手配中、完了ならDB追加済み＝顧客が追加した行とみなせる）
      return auth.user().bemacFlg && !data.isDbRow;
    }

    // その他は編集不可
    return false;
  }

  // BEMAC回答列の編集可否判定
  function editableReplyColumn(rowData: ICellRendererParams<FormRowValues>) {
    if (readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }

    // 顧客は編集不可
    if (!auth.user().bemacFlg) {
      return false;
    }

    //経費行の場合は回答数量のみ編集不可
    if (
      rowData.data?.productCode === ProductCodeConst.EXPENSES &&
      rowData.colDef?.field === "replyQuantity"
    ) {
      return false;
    }

    //品目コード=「---」の場合は編集不可
    if (rowData.data?.productCode === ProductCodeConst.BLANK) {
      return false;
    }

    // 経費・送料は「注文中」「手配中」で未確定行の編集可
    if (
      (rowData.data?.isExpenses || rowData.data?.isDeliveryCost) &&
      (inpOrder.statusClassValue === OrderStatus.ORDERED ||
        inpOrder.statusClassValue === OrderStatus.ARRANGING) &&
      !rowData.data.confirmFlg
    ) {
      return true;
    }

    // 「注文中」、「部品手配中」、「完了」は編集可
    if (
      inpOrder.statusClassValue === OrderStatus.ORDERED ||
      inpOrder.statusClassValue === OrderStatus.ARRANGING ||
      inpOrder.statusClassValue === OrderStatus.COMPLETED
    ) {
      return true;
    }

    // その他は編集不可
    return false;
  }

  // R3関連項目入力可能判定
  function editableR3Values(rowData?: ICellRendererParams<FormRowValues>) {
    if ("---" === rowData?.data?.productCode) {
      return false;
    }
    if (readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }

    // 顧客は編集不可
    if (!auth.user().bemacFlg) {
      return false;
    }

    // 自身がBEMACユーザで「部品手配中」は編集可
    if (inpOrder.statusClassValue === OrderStatus.ARRANGING) {
      return true;
    }

    // その他は編集不可
    return false;
  }

  // 出荷納期表示判定
  function visibleReplyDeliveryDate() {
    if (auth.user().bemacFlg) {
      // BEMACは常に表示
      return true;
    } else {
      // 顧客は作成中、注文中は非表示
      if (
        inpOrder.statusClassValue === OrderStatus.DRAFT ||
        inpOrder.statusClassValue === OrderStatus.ORDERED
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  // 出荷納期入力可能判定
  function editableReplyDeliveryDate() {
    if (readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }

    // 顧客は編集不可
    if (!auth.user().bemacFlg) {
      return false;
    }

    // 自身がBEMACユーザで「注文中」は編集可
    if (inpOrder.statusClassValue === OrderStatus.ORDERED) {
      return true;
    }

    // その他は編集不可
    return false;
  }

  // グリッド内の削除ボタン使用可否
  function canDeleteRow(rowData: ICellRendererParams<FormRowValues>) {
    // 最終行または技師派遣フラグON時の経費行の場合は削除不可
    if (
      rowData.data?.orderDetailNo === inpDetails.length ||
      (rowData.data?.productCode === ProductCodeConst.EXPENSES &&
        inpOrder.dispatchFlg)
    ) {
      return false;
    }
    return editableColumn(rowData);
  }
  // 行ドラッグ
  let dragRowIndex = -1;
  const onRowDragEnter = useCallback((e: RowDragEnterEvent) => {
    dragRowIndex = e.overIndex;
  }, []);
  const onRowDragEnd = useCallback((e: RowDragEndEvent) => {
    setFormChanged(true);
    // 並び替え後の順番でNoをつけなおす
    const newDatas: FormRowValues[] = [];
    gridRef.current?.api.forEachNode((node, index) => {
      const row = node.data as FormRowValues;
      row.orderDetailNo = index + 1;
      newDatas.push(row);
    });
    // 最下行を移動させた場合は新規行を追加
    if (
      dragRowIndex !== e.overIndex &&
      dragRowIndex === newDatas.length - 1 &&
      canAddNewRow()
    ) {
      addNewRow(newDatas);
    }

    setInpDetails(newDatas);
  }, []);

  // 行削除
  function onClickDeleteButton(key: number) {
    setFormChanged(true);
    // 自身の行を削除し、その他の行のNoを採番しなおす
    const newRows = inpDetails
      .filter((it) => it.key !== key)
      .map((it, itIndex) => {
        it.orderDetailNo = itIndex + 1;
        return it;
      });
    setInpDetails(newRows);
    // 金額再計算
    calculateTempPrice(newRows);
    calculateReplyPrice(newRows);
  }

  // "BEMAC注文回答内容"表示条件
  function showsReplyColumns() {
    // BEMACユーザは常に表示
    if (auth.user().bemacFlg) {
      return true;
    }

    // 顧客の作成中は非表示
    if (inpOrder.statusClassValue === OrderStatus.DRAFT) {
      return false;
    }
    // BEMACが承認するまでは非表示
    if (inpOrder.statusClassValue === OrderStatus.ORDERED) {
      return false;
    }
    // その他は表示
    return true;
  }

  // 出荷後キャンセル内容「数量」列入力可能判定
  function editableCancelQuantity(rowData: ICellRendererParams<FormRowValues>) {
    if ("---" === rowData.data?.productCode) {
      return false;
    }

    if (readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }

    // 顧客は編集不可
    if (!auth.user().bemacFlg) {
      return false;
    }

    // 出荷してなければ編集不可
    if (!rowData.data?.confirmDateTime) {
      return false;
    }

    // 自身がBEMACユーザで「部品手配中」「完了」は編集可
    if (
      inpOrder.statusClassValue === OrderStatus.ARRANGING ||
      inpOrder.statusClassValue === OrderStatus.COMPLETED
    ) {
      return true;
    }

    // その他は編集不可
    return false;
  }

  // 出荷ボタン表示条件
  function showsShippingCheckColumn() {
    return showsShippingColumns();
  }

  // R3関連項目表示条件
  function showsR3Values() {
    // 顧客ユーザは常に非表示
    if (!auth.user().bemacFlg) {
      return false;
    }
    return showsShippingColumns();
  }

  // 出荷完了以降項目表示条件
  function showsShippingColumns() {
    // 部品手配中、出荷完了の場合表示
    // 部品手配中以降でも注文変更可能となるので、部品手配中以降での否認、停止、変更時も表示
    if (
      inpOrder.statusClassValue === OrderStatus.ARRANGING ||
      inpOrder.statusClassValue === OrderStatus.COMPLETED ||
      (inpOrder.statusClassValue === OrderStatus.REQUESTED_CHANGE &&
        inpOrder.replyDateTime) ||
      (inpOrder.statusClassValue === OrderStatus.CHANGED &&
        inpOrder.replyDateTime) ||
      (inpOrder.statusClassValue === OrderStatus.DENIED_CHANGE &&
        inpOrder.replyDateTime)
    ) {
      return true;
    }
    // その他は非表示
    return false;
  }

  // キャンセル列表示条件
  function showsCancelColumns() {
    // 顧客はキャンセルがなかったら非表示
    if (!auth.user().bemacFlg && getCancelTotalPrice() === 0) {
      return false;
    }

    return showsShippingColumns();
  }

  // 全出荷ボタンレンダリング
  function rendererShippingAll() {
    //  顧客は非表示
    if (!auth.user().bemacFlg) {
      return <></>;
    }
    //  完了、否認時は非表示
    if (
      inpOrder.statusClassValue === OrderStatus.COMPLETED ||
      inpOrder.statusClassValue === OrderStatus.REQUESTED_CHANGE
    ) {
      return <></>;
    }

    return (
      <input
        type="button"
        value={"全出荷"}
        data-cy="全出荷ボタン"
        className="btn btn-sm text-white"
        style={
          shippingAllFlg
            ? { backgroundColor: "#00b0f0" }
            : { backgroundColor: "#e76300" }
        }
        onClick={() => {
          gridRef.current?.api.forEachNode((node: RowNode<FormRowValues>) => {
            if (!node.data?.confirmDateTime && node.data?.isDbRow) {
              node.setDataValue("confirmFlg", !shippingAllFlg);
            }
          });
          setShippingAllFlg(!shippingAllFlg);
        }}
      />
    );
  }

  // 出荷ボタンレンダリング
  function rendererShipping(rowData: ICellRendererParams<FormRowValues>) {
    const row = rowData.data!;
    return (
      <input
        type="button"
        value={getShippingButtonText(row)}
        className="btn btn-sm text-white"
        disabled={
          !auth.user().bemacFlg ||
          inpOrder.statusClassValue === OrderStatus.COMPLETED ||
          inpOrder.statusClassValue === OrderStatus.REQUESTED_CHANGE ||
          row.confirmDateTime !== undefined ||
          (auth.user().bemacFlg && !row.isDbRow)
        }
        style={
          row.confirmFlg
            ? { backgroundColor: "#00b0f0" }
            : { backgroundColor: "#e76300" }
        }
        onClick={() => {
          const newValue = !row.confirmFlg;
          if (!newValue) {
            rowData.node.setDataValue("cancelOrderQuantity", undefined);
          }
          rowData.node.setDataValue("confirmFlg", !row.confirmFlg);
        }}
      />
    );
  }
  function getShippingButtonText(row: FormRowValues) {
    if (row.isExpenses) {
      if (row.confirmFlg) {
        if (row.confirmDateTime) {
          return tc("経費確定済");
        } else {
          return tc("経費確定");
        }
      } else {
        return tc("経費未確定");
      }
    } else if (row.isDeliveryCost) {
      if (row.confirmFlg) {
        if (row.confirmDateTime) {
          return tc("送料確定済");
        } else {
          return tc("送料確定");
        }
      } else {
        return tc("送料未確定");
      }
    } else {
      if (row.confirmFlg) {
        if (row.confirmDateTime) {
          return tc("出荷済");
        } else {
          return tc("出荷対象");
        }
      } else {
        return tc("未出荷");
      }
    }
  }

  // 削除ボタンレンダリング
  function rendererDeleteButton(rowData: ICellRendererParams<FormRowValues>) {
    if (canDeleteRow(rowData)) {
      return (
        <span>
          <a
            style={{ color: "black" }}
            href="#"
            onClick={(e) => onClickDeleteButton(rowData.data?.key ?? -1)}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <HiOutlineTrash size={20} style={{ margin: "10 10px" }} />
            </div>
          </a>
        </span>
      );
    } else {
      return <></>;
    }
  }

  // グリッドの列定義
  const columnDefs = [
    {
      headerName: "",
      children: [
        {
          headerName: "",
          field: "confirmFlg",
          width: 120,
          pinned: true,
          cellRenderer: rendererShipping,
          headerComponent: rendererShippingAll,
          hide: !showsShippingCheckColumn(),
        },
      ],
    },
    {
      // 明細：顧客発注内容
      headerName: tc("明細"),
      children: [
        {
          headerName: "No",
          field: "orderDetailNo",
          width: 90,
          pinned: true,
          rowDrag: canDragRow(),
          cellClassRules: {
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
        },
        {
          headerName: tc("品目コード"),
          field: "productCode",
          width: 120,
          pinned: true,
          editable: editableColumn,
          cellClassRules: {
            "b-editable-cell": (params: any) =>
              editableColumn(params) && params.data?.existFlg,
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
        },
      ],
    },
    {
      headerName: "",
      children: [
        {
          headerName: tc("品名"),
          field: "productGroupName",
          cellClassRules: {
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
        },
        {
          headerName: tc("型式"),
          field: "spec",
          editable: editableColumn,
          cellClassRules: {
            "b-editable-cell": (params: any) =>
              editableColumn(params) && params.data?.existFlg,
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
        },
        {
          headerName: tc("単価"),
          width: 100,
          field: "unitCost",
          cellStyle: { textAlign: "right" },
          cellClassRules: {
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
          valueFormatter: (e: ValueFormatterParams) => {
            if (
              isEmptyRow(e.data) ||
              e.data.productCode === ProductCodeConst.BLANK
            ) {
              return "";
            }
            return agNumberFormatter(e, tc("要問合せ"));
          },
        },
        {
          headerName: tc("数量"),
          width: 100,
          colId: "quantity",
          field: "quantity",
          cellStyle: { textAlign: "right" },
          editable: editableColumn,
          valueFormatter: (e: ValueFormatterParams) => {
            if (
              isEmptyRow(e.data) ||
              e.data.productCode === ProductCodeConst.BLANK
            ) {
              return "";
            }
            return agNumberFormatter(e);
          },
          cellClassRules: {
            "b-editable-cell": (e: ICellRendererParams<FormRowValues>) =>
              editableColumn(e) &&
              e.data?.productCode !== ProductCodeConst.BLANK &&
              e.data?.existFlg,
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
          valueSetter: (params: ValueSetterParams) =>
            agNumberSetter(params, "quantity"),
        },
        {
          headerName: tc("単位"),
          width: 100,
          colId: "unit",
          field: "unit",
          editable: editableColumn,
          cellClassRules: {
            "b-editable-cell": (params: any) =>
              editableColumn(params) && params.data?.existFlg,
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
        },
        {
          headerName: tc("小計"),
          width: 100,
          field: "detailSubTotal",
          cellStyle: { textAlign: "right" },
          cellClassRules: {
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
          valueFormatter: (e: ValueFormatterParams) => {
            if (
              isEmptyRow(e.data) ||
              e.data.productCode === ProductCodeConst.BLANK
            ) {
              return "";
            }
            return agNumberFormatter(e);
          },
        },
        {
          headerName: tc("明細備考"),
          field: "note",
          cellClassRules: {
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
        },
        {
          headerName: tc("削除"),
          colId: "deleteByUser",
          width: 80,
          cellRenderer: rendererDeleteButton,
          hide:
            auth.user().bemacFlg ||
            readOnly ||
            inpOrder.statusClassValue === OrderStatus.ARRANGING,
          cellClassRules: {
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
        },
      ],
    },
    {
      // 明細：BEMAC回答
      headerName: tc("BEMAC回答"),
      headerClass: "b-ag-column-reply",
      children: [
        {
          headerName: tc("単価"),
          width: 100,
          field: "replyUnitCost",
          cellStyle: { textAlign: "right" },
          valueFormatter: (e: ValueFormatterParams) => {
            if (
              isEmptyRow(e.data) ||
              e.data.productCode === ProductCodeConst.BLANK
            ) {
              return "";
            }
            return agNumberFormatter(e);
          },
          editable: editableReplyColumn,
          cellClassRules: {
            "b-editable-cell": (params: any) =>
              editableReplyColumn(params) && params.data?.existFlg,
            "b-ag-column-reply": (params: any) => !editableReplyColumn(params),
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
          hide: !showsReplyColumns() || isGeneralEngineer(),
          headerClass: "b-ag-column-reply",
          valueSetter: (params: ValueSetterParams) =>
            agNumberSetter(params, "replyUnitCost"),
        },
        {
          headerName: tc("数量"),
          width: 90,
          field: "replyQuantity",
          cellStyle: { textAlign: "right" },
          editable: editableReplyColumn,
          valueFormatter: (e: ValueFormatterParams) => {
            if (
              isEmptyRow(e.data) ||
              e.data.productCode === ProductCodeConst.BLANK
            ) {
              return "";
            }
            return agNumberFormatter(e);
          },
          cellClassRules: {
            "b-editable-cell": (params: any) =>
              editableReplyColumn(params) && params.data?.existFlg,
            "b-ag-column-reply": (params: any) => !editableReplyColumn(params),
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
          hide: !showsReplyColumns(),
          headerClass: "b-ag-column-reply",
          valueSetter: (params: ValueSetterParams) =>
            agNumberSetter(params, "replyQuantity"),
        },
        {
          headerName: tc("小計"),
          width: 100,
          field: "replyDetailSubTotal",
          cellClass: "b-ag-column-reply",
          cellStyle: { textAlign: "right" },
          valueFormatter: (e: ValueFormatterParams) => {
            if (
              isEmptyRow(e.data) ||
              e.data.productCode === ProductCodeConst.BLANK
            ) {
              return "";
            }
            return agNumberFormatter(e);
          },
          cellClassRules: {
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
          hide: !showsReplyColumns() || isGeneralEngineer(),
          headerClass: "b-ag-column-reply",
        },
        // R3関連（アシスタントユーザ入力）
        {
          headerName: "WBS(Lv.2)",
          field: "r3WbsLv2",
          width: 150,
          editable: editableR3Values,
          cellClassRules: {
            "b-editable-cell": (params: any) =>
              editableR3Values(params) && params.data?.existFlg,
            "b-ag-column-reply": (params: any) => !editableR3Values(),
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
          hide: !showsR3Values(),
          headerClass: "b-ag-column-reply",
        },
        {
          headerName: "受注伝票",
          field: "r3OrderNo",
          width: 150,
          editable: editableR3Values,
          cellClassRules: {
            "b-editable-cell": (params: any) =>
              editableR3Values(params) && params.data?.existFlg,
            "b-ag-column-reply": (params: any) => !editableR3Values(),
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
          hide: !showsR3Values(),
          headerClass: "b-ag-column-reply",
        },
        {
          headerName: "R3出荷日",
          field: "r3ShippingDate",
          width: 135,
          valueFormatter: agYmdFormatter,
          cellEditor: AgDatePicker,
          editable: editableR3Values,
          cellClassRules: {
            "b-editable-cell": (params: any) =>
              editableR3Values(params) && params.data?.existFlg,
            "b-ag-column-reply": (params: any) => !editableR3Values(),
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
          hide: !showsR3Values(),
          headerClass: "b-ag-column-reply",
        },
        {
          headerName: tc("納期"),
          field: "replyDeliveryDate",
          width: 135,
          editable: editableReplyColumn,
          cellClassRules: {
            "b-editable-cell": (params: any) =>
              editableReplyColumn(params) && params.data?.existFlg,
            "b-ag-column-reply": (params: any) => !editableReplyColumn(params),
            "b-changed-cell": showsChangeColor,
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
          valueFormatter: agYmdFormatter,
          cellEditor: AgDatePicker,
          hide: !inpOrder.replyInstallmentDeliveryFlg || !showsReplyColumns(),
          headerClass: "b-ag-column-reply",
        },
        {
          headerName: tc("削除"),
          colId: "deleteByBemac",
          width: 80,
          cellClass: "b-ag-column-reply",
          cellRenderer: rendererDeleteButton,
          hide: !auth.user().bemacFlg || readOnly,
          headerClass: "b-ag-column-reply",
          cellClassRules: {
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
        },
      ],
    },
    {
      // 明細：出荷後キャンセル
      headerName: "出荷後キャンセル",
      headerClass: "b-ag-column-cancel",
      children: [
        {
          headerName: tc("数量"),
          width: 90,
          field: "cancelOrderQuantity",
          editable: editableCancelQuantity,
          cellStyle: { textAlign: "right" },
          cellClassRules: {
            "b-editable-cell": (params: any) =>
              editableCancelQuantity(params) && params.data?.existFlg,
            "b-ag-column-cancel": (e: any) => !editableCancelQuantity(e),
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
          hide: !showsCancelColumns(),
          headerClass: "b-ag-column-cancel",
          valueSetter: (params: ValueSetterParams) =>
            agNumberSetter(params, "cancelOrderQuantity"),
        },
        {
          headerName: tc("小計"),
          width: 100,
          field: "cancelSubTotal",
          cellStyle: { textAlign: "right" },
          cellClass: "b-ag-column-cancel",
          valueFormatter: agNumberFormatter,
          hide: !showsCancelColumns() || isGeneralEngineer(),
          headerClass: "b-ag-column-cancel",
          cellClassRules: {
            "b-error-cell": (params: any) => !params.data?.existFlg,
          },
        },
      ],
    },
  ];

  // モーダルbody部レンダリング
  function modalBody() {
    return (
      <>
        <OrderDetailHeader
          readOnly={readOnly || inpOrder.isChangeOrder}
          type={OrderDetailType.Order}
          status={inpOrder.statusClassValue}
          orderFactor={inpOrder.orderClassValue ?? ""}
          quotationNo={inpOrder.quotationNo}
          quotationDateTime={inpOrder.quotationDateTime ?? ""}
          orderNo={orderNo}
          orderDateTime={inpOrder.orderDateTime}
          replyUserId={inpOrder.replyUserId ?? undefined}
          replyUserName={inpOrder.replyUserName ?? ""}
          companyCode={inpOrder.companyCode}
          companyName={inpOrder.companyName ?? ""}
          orderUserId={inpOrder.orderUserId}
          orderUserName={inpOrder.orderUserName}
          vesselName={inpOrder.vesselName ?? ""}
          imoNo={inpOrder.imoNo}
          vesselBuilder={inpOrder.vesselBuilder ?? ""}
          yardNo={inpOrder.yardNo ?? ""}
          customerOrderNo1={inpOrder.customerOrderNo1 ?? ""}
          customerOrderNo2={inpOrder.customerOrderNo2 ?? ""}
          requestDeliveryDate={inpOrder.requestDeliveryDate ?? ""}
          requestReplyDeadline={inpOrder.requestReplyDeadline ?? ""}
          dispatchFlg={inpOrder.dispatchFlg}
          dispatchPlace={inpOrder.dispatchPlace ?? ""}
          dispatchDate={inpOrder.dispatchDate ?? ""}
          replyInstallmentDeliveryFlg={inpOrder.replyInstallmentDeliveryFlg}
          dockName={inpOrder.dockName ?? ""}
          dockScheduleStart={inpOrder.dockScheduleStart ?? ""}
          dockScheduleEnd={inpOrder.dockScheduleEnd ?? ""}
          problemProduct={inpOrder.problemProduct ?? ""}
          problemDetail={inpOrder.problemDetail ?? ""}
          onChange={handleChangeHeader}
          reQuotationFlg={inpOrder.isChangeOrder}
          compQuotationFlg={inpOrder.compQuotationFlg}
          isCompTempSave={inpOrder.isCompTempSave}
          fromQuotationDetail={props.fromQuotationDetail}
          customerQuotationNo1={inpOrder.customerQuotationNo1 ?? ""}
          customerQuotationNo2={inpOrder.customerQuotationNo2 ?? ""}
          editableFileUpload={editableFileUpload()}
          quotationOrderHistoryFlg={props.quotationOrderHistoryFlg}
        >
          <div
            className={`d-flex flex-wrap h-100 ${
              showsR3Values() ? "align-content-between" : "align-content-end"
            }`}
          >
            {showsR3Values() && (
              <div className={areaClass} data-cy="R3エリア">
                <div className="input-group">
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    WBS
                  </span>
                  <input
                    type="text"
                    name="r3Wbs"
                    className="form-control"
                    disabled={!editableR3Values()}
                    defaultValue={inpOrder.r3Wbs}
                    maxLength={15}
                    onChange={handleChangeInput}
                    data-cy="WBSテキスト"
                  />
                </div>
                <div className="input-group">
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    R3出荷日
                  </span>
                  <input
                    type="date"
                    name="r3ShippingDate"
                    className="form-control"
                    disabled={!editableR3Values()}
                    defaultValue={formatDate(
                      inpOrder.r3ShippingDate,
                      "YYYY-MM-DD"
                    )}
                    onChange={handleChangeInput}
                    data-cy="R3出荷日テキスト"
                  />
                </div>
                <div className="input-group">
                  <span
                    className="b-input-group-text"
                    style={{ width: "11rem", height: "115px" }}
                  >
                    コメント
                  </span>
                  <textarea
                    name="r3Comment"
                    className="form-control textarea"
                    disabled={!editableR3Values()}
                    defaultValue={inpOrder.r3Comment}
                    maxLength={20}
                    onChange={handleChangeInput}
                    data-cy="R3コメントテキスト"
                  />
                </div>
              </div>
            )}
            {visibleReplyDeliveryDate() && (
              <div className={areaClass}>
                <div className="input-group">
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    {tc("出荷納期")}
                  </span>
                  <input
                    name="replyDeliveryDate"
                    type="date"
                    className="form-control"
                    disabled={!editableReplyDeliveryDate()}
                    value={formatDate(inpOrder.replyDeliveryDate, "YYYY-MM-DD")}
                    onChange={handleChangeInput}
                    data-cy="出荷納期テキスト"
                    style={{ width: "235px" }}
                  />
                </div>
                {inpOrder.replyInstallmentDeliveryFlg &&
                  editableReplyDeliveryDate() && (
                    <div>
                      <span
                        className="ms-1"
                        style={{ color: "red" }}
                        data-cy="出荷納期注記"
                      >
                        {tc("※分納/後送があるため、明細に入力してください。")}
                      </span>
                    </div>
                  )}
              </div>
            )}
          </div>
        </OrderDetailHeader>
        <div>
          {/* 明細 */}
          <div
            className="ag-theme-alpine w-100 mx-auto b-detail-items b-grid-outer b-header-row-white"
            data-cy="明細グリッド"
          >
            <AgGridReact
              domLayout="autoHeight"
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              suppressRowClickSelection={true}
              rowData={inpDetails}
              onCellValueChanged={onCellValueChanged}
              singleClickEdit
              ref={gridRef}
              rowDragManaged={true}
              onRowDragEnter={onRowDragEnter}
              onRowDragEnd={onRowDragEnd}
              stopEditingWhenCellsLoseFocus={true}
            />
            {/* 部品検索ボタン */}
            <div className="row">
              <div className="col-4">
                {auth.user().bemacFlg && (
                  <MdOutlineContentCopy
                    size="30px"
                    onClick={() => copyToClipboard()}
                    cursor="pointer"
                    style={{ marginTop: "30px" }}
                  />
                )}
              </div>
              <div className="col-4">
                <div className="text-center">
                  {canAddNewRow() && (
                    <input
                      type="button"
                      className="btn b-btn-primary"
                      value={tc("部品検索")}
                      data-cy="部品検索ボタン"
                      onClick={(e) => productListModalRef.current?.show()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* 金額 */}
          <div className="row mt-2">
            <div className="col-4">
              {!isGeneralEngineer() && (
                <OrderDetailPrice
                  readOnly={readOnly || inpOrder.isChangeOrder}
                  type={OrderDetailType.Order}
                  status={inpOrder.statusClassValue}
                  replySubtotalPrice={inpOrder.replySubtotalPrice}
                  replyDiscountPrice={inpOrder.replyDiscountPrice}
                  replyTax={inpOrder.replyTax}
                  replyTaxPrice={inpOrder.replyTaxPrice}
                  replyTotalPrice={inpOrder.replyTotalPrice}
                  onChange={handleChangeHeader}
                  reQuotationFlg={inpOrder.isChangeOrder}
                  showTempPrice={showsTempPrice()}
                  tempSubtotalPrice={tempSubtotalPrice}
                />
              )}
            </div>
            {/* ここから　キャンセル金額 */}
            <div className="col-4">
              {showsShippingColumns() &&
                !isGeneralEngineer() &&
                (auth.user().bemacFlg || getCancelTotalPrice() > 0) && (
                  <div
                    className="p-1 bg-white b-radius"
                    style={{ marginTop: "152px" }}
                    data-cy="キャンセル金額エリア"
                  >
                    <div className="input-group">
                      <span
                        className="b-input-group-text"
                        style={{ width: "10rem" }}
                      >
                        {tc("キャンセル金額")}
                      </span>
                      <span
                        className="form-control text-end"
                        style={{ color: "red" }}
                        data-cy="キャンセル金額"
                      >
                        {getCancelTotalPrice().toLocaleString()}
                      </span>
                    </div>
                    <div className="input-group">
                      <span
                        className="b-input-group-text"
                        style={{ width: "10rem" }}
                      >
                        {tc("キャンセル後総計")}
                      </span>
                      <span
                        className="form-control text-end"
                        data-cy="キャンセル後総計"
                      >
                        {getTotalPriceWithCancle().toLocaleString()}
                      </span>
                    </div>
                  </div>
                )}
            </div>
            {/* ここまで　キャンセル金額 */}
            <div className="col-4">
              <OrderDetailDelivery
                readOnly={readOnly || inpOrder.isChangeOrder}
                type={OrderDetailType.Order}
                status={inpOrder.statusClassValue}
                deliveryName={inpOrder.deliveryName}
                deliveryPostalCode={inpOrder.deliveryPostalCode}
                deliveryAddress={inpOrder.deliveryAddress}
                deliveryTel={inpOrder.deliveryTel}
                reQuotationFlg={inpOrder.isChangeOrder}
                onChange={handleChangeHeader}
              />
            </div>
          </div>
          {/* ファイル添付（見積・注文含む） */}
          <div className="row">
            <div className="col-6">
              <div className={`${areaClass} pt-2`}>
                <div className="pb-1">
                  <span
                    className="b-input-group-text"
                    style={{ display: "inline" }}
                  >
                    {tc("お客様 添付ファイル")}
                  </span>
                  {editableFileUpload() && (
                    <span className="ms-2" style={{ color: "red" }}>
                      {tc("※ファイル添付は、最大10件までです。")}
                    </span>
                  )}
                </div>
                <S3Uploader
                  editable={editableFileUpload()}
                  maxCount={10}
                  ref={uploaderCustomerRef}
                />
              </div>
            </div>
            <div className="col-6">
              <div className={`${areaClass} pt-2`}>
                <div className="pb-1">
                  <span
                    className="b-input-group-text-light_blue"
                    style={{ display: "inline" }}
                  >
                    {tc("BEMAC 添付ファイル")}
                  </span>
                </div>
                <S3Uploader
                  editable={false}
                  maxCount={10}
                  ref={uploaderBemacRef}
                />
              </div>
            </div>
          </div>

          {/* 備考、コメント */}
          <div className="row">
            <div className="col-6">
              <div className={areaClass}>
                <div>
                  <div
                    className="b-input-group-text"
                    style={{ borderRadius: "0.5rem 0.5rem 0 0" }}
                  >
                    {tc("見積時備考")}
                  </div>
                  <textarea
                    className={textAreaClass}
                    disabled
                    defaultValue={inpOrder.quotationComment}
                    data-cy="見積時備考テキスト"
                  />
                </div>
                <div className="mt-3">
                  {showsUserCommentCancel() && (
                    <span className="mt-2" style={{ color: "red" }}>
                      {tc(
                        "※注文取消をされる際は、理由を備考にご記入ください。"
                      )}
                    </span>
                  )}
                  {showsUserCommentDeniedChange() && (
                    <span className="mt-2" style={{ color: "red" }}>
                      {tc(
                        "※注文停止をされる際は、理由を備考にご記入ください。"
                      )}
                    </span>
                  )}
                  <div
                    className="b-input-group-text"
                    style={{ borderRadius: "0.5rem 0.5rem 0 0" }}
                  >
                    {tc("備考")}
                  </div>
                  <textarea
                    name="orderComment"
                    className={textAreaClass}
                    disabled={!editableUserComment()}
                    value={inpOrder.orderComment}
                    maxLength={500}
                    onChange={handleChangeInput}
                    data-cy="お客様備考テキスト"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className={areaClass}>
                <div>
                  <div
                    className="b-input-group-text-light_blue"
                    style={{ borderRadius: "0.5rem 0.5rem 0 0" }}
                  >
                    {tc("注文回答コメント")}
                  </div>
                  <textarea
                    name="replyComment"
                    className={textAreaClass}
                    disabled={!editableBemacReplyComment()}
                    value={inpOrder.replyComment}
                    maxLength={500}
                    onChange={handleChangeInput}
                    data-cy="注文回答コメントテキスト"
                  />
                </div>
                {(showsShippingColumns() ||
                  (OrderStatus.REQUESTED_CANCEL === inpOrder.statusClassValue &&
                    inpOrder.replyDateTime)) && (
                  <div className="mt-3">
                    <div
                      className="b-input-group-text-light_blue"
                      style={{ borderRadius: "0.5rem 0.5rem 0 0" }}
                    >
                      {tc("出荷完了コメント")}
                    </div>
                    <textarea
                      name="shippedComment"
                      className={textAreaClass}
                      disabled={!editableBemacShippingComment()}
                      value={inpOrder.shippedComment}
                      maxLength={500}
                      onChange={handleChangeInput}
                      data-cy="出荷完了コメントテキスト"
                    />
                  </div>
                )}
                {auth.user().bemacFlg && (
                  <div className="mt-3">
                    <div
                      className="b-input-group-text-light_blue"
                      style={{ borderRadius: "0.5rem 0.5rem 0 0" }}
                    >
                      {tc("フリーメモ")}
                    </div>
                    <textarea
                      name="bemacFreeComment"
                      className={textAreaClass}
                      disabled={!editableBemacFreeComment()}
                      value={inpOrder.bemacFreeComment}
                      maxLength={400}
                      onChange={handleChangeInput}
                      data-cy="フリーメモテキスト"
                    />
                  </div>
                )}
              </div>
            </div>
            {showsShippingColumns() && (
              <div className="row">
                <div className="col-12 px-0" style={{ marginLeft: "12px" }}>
                  <div className={`${areaClass} pt-2`}>
                    <div className="pb-1">
                      <span
                        className="b-input-group-text-light_blue"
                        style={{ display: "inline" }}
                      >
                        {tc("送り状添付")}
                      </span>
                      {auth.user().bemacFlg && (
                        <span className="ms-2" style={{ color: "red" }}>
                          {tc("※送り状添付は、最大2件までです。")}
                        </span>
                      )}
                    </div>
                    {(inpOrder.statusClassValue === OrderStatus.ARRANGING ||
                      inpOrder.statusClassValue === OrderStatus.COMPLETED) && (
                      <S3Uploader
                        editable={editableShippingFileUpload()}
                        maxCount={2}
                        ref={uploaderShippingRef}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <ProductListModal
          onDecide={handleSelectProducts}
          onlyLcmCosts={inpOrder.orderClassValue === OrderFactor.LCM_PARTS}
          imoNo={
            inpOrder.orderClassValue === OrderFactor.LCM_PARTS
              ? inpOrder.imoNo
              : undefined
          }
          ref={productListModalRef}
        />
      </>
    );
  }

  // モーダルfooter部レンダリング
  function modalFooter() {
    if (props.fromQuotationDetail) {
      return (
        <>
          {(!readOnly || props.fromQuotationDetail) && (
            <Button
              className="b-btn-primary"
              onClick={(e) => handleClickActionButton("発注")}
              data-cy="発注ボタン"
            >
              {tc("発注")}
            </Button>
          )}
          <Button
            className="b-btn-close"
            onClick={handleClose}
            data-cy="Closeボタン"
          >
            Close
          </Button>
        </>
      );
    }

    return (
      <>
        {
          // 下書き or 新規作成（顧客）
          inpOrder.statusClassValue === OrderStatus.DRAFT &&
            !auth.user().bemacFlg && (
              <>
                {!readOnly && (
                  <>
                    {!inpOrder.isChangeOrder && (
                      <Button
                        className="b-btn-light"
                        onClick={(e) => handleClickActionButton("一時保存")}
                        data-cy="一時保存ボタン"
                      >
                        {tc("一時保存")}
                      </Button>
                    )}
                    <Button
                      className="b-btn-primary"
                      onClick={(e) => handleClickActionButton("発注")}
                      data-cy="発注ボタン"
                    >
                      {tc("発注")}
                    </Button>
                  </>
                )}
              </>
            )
        }
        {
          // 注文中（顧客）
          inpOrder.statusClassValue === OrderStatus.ORDERED &&
            !auth.user().bemacFlg && (
              <>
                {!readOnly && (
                  <Button
                    className="b-btn-primary"
                    onClick={(e) => handleClickActionButton("注文取消依頼")}
                    data-cy="注文取消依頼ボタン"
                  >
                    {tc("注文取消依頼")}
                  </Button>
                )}
              </>
            )
        }
        {
          // 注文中（BEMAC）
          inpOrder.statusClassValue === OrderStatus.ORDERED &&
            auth.user().bemacFlg && (
              <>
                {!readOnly && (
                  <>
                    <Button
                      className="b-btn-light"
                      onClick={(e) => handleClickActionButton("注文中一時保存")}
                      data-cy="一時保存ボタン"
                    >
                      {tc("一時保存")}
                    </Button>
                    <Button
                      className="b-btn-light"
                      onClick={(e) => handleClickActionButton("注文変更依頼")}
                      data-cy="注文変更依頼ボタン"
                    >
                      {tc("注文変更依頼")}
                    </Button>
                    <Button
                      className="b-btn-primary"
                      onClick={(e) => handleClickActionButton("注文承認")}
                      data-cy="注文承認ボタン"
                    >
                      {tc("注文承認")}
                    </Button>
                  </>
                )}
              </>
            )
        }
        {
          // 部品手配中(顧客)
          inpOrder.statusClassValue === OrderStatus.ARRANGING &&
            !auth.user().bemacFlg && (
              <>
                <Button
                  className="b-btn-light"
                  onClick={(e) => handleClickOutputPdf()}
                  data-cy="注文書PDFボタン"
                >
                  {tc("注文書PDF")}
                </Button>
                {!readOnly && (
                  <>
                    <Button
                      className="b-btn-primary"
                      onClick={(e) => handleClickActionButton("注文取消依頼")}
                      data-cy="注文取消依頼ボタン"
                    >
                      {tc("注文取消依頼")}
                    </Button>
                    <Button
                      className="b-btn-light"
                      onClick={(e) => handleClickActionButton("更新")}
                      data-cy="更新ボタン"
                    >
                      {tc("更新")}
                    </Button>
                  </>
                )}
              </>
            )
        }
        {
          // 部品手配中(BEMAC)
          inpOrder.statusClassValue === OrderStatus.ARRANGING &&
            auth.user().bemacFlg && (
              <>
                {!auth.isEngineer() && (
                  <Button
                    className="b-btn-light"
                    onClick={(e) => handleClickOutputPdf()}
                    data-cy="注文書PDFボタン"
                  >
                    {tc("注文書PDF")}
                  </Button>
                )}
                {!readOnly && (
                  <>
                    <Button
                      className="b-btn-light"
                      onClick={(e) => handleClickActionButton("注文変更依頼")}
                      data-cy="注文変更依頼ボタン"
                    >
                      {tc("注文変更依頼")}
                    </Button>
                    <Button
                      className="b-btn-primary"
                      onClick={(e) => handleClickActionButton("更新")}
                      data-cy="更新ボタン"
                    >
                      {tc("更新")}
                    </Button>
                  </>
                )}
              </>
            )
        }
        {
          // 部品取消依頼中(BEMAC)
          inpOrder.statusClassValue === OrderStatus.REQUESTED_CANCEL &&
            auth.user().bemacFlg && (
              <>
                {!readOnly && (
                  <Button
                    className="b-btn-primary"
                    onClick={(e) => handleClickActionButton("注文取消承認")}
                    data-cy="注文取消承認ボタン"
                  >
                    {tc("注文取消承認")}
                  </Button>
                )}
              </>
            )
        }
        {
          // 注文否認(BEMAC)
          inpOrder.statusClassValue === OrderStatus.REQUESTED_CHANGE &&
            auth.user().bemacFlg && (
              <>
                {!readOnly && (
                  <>
                    <Button
                      className="b-btn-light"
                      onClick={(e) => handleClickActionButton("差戻")}
                      data-cy="差戻ボタン"
                    >
                      {tc("差戻")}
                    </Button>
                  </>
                )}
              </>
            )
        }
        {
          // 注文否認(顧客)
          inpOrder.statusClassValue === OrderStatus.REQUESTED_CHANGE &&
            !auth.user().bemacFlg && (
              <>
                {!readOnly && (
                  <>
                    {!inpOrder.replyDateTime && (
                      <Button
                        className="b-btn-light"
                        onClick={(e) => handleClickActionButton("注文停止")}
                        data-cy="注文停止ボタン"
                      >
                        {tc("注文停止")}
                      </Button>
                    )}
                    <Button
                      className="b-btn-primary"
                      onClick={(e) => handleClickActionButton("注文変更")}
                      data-cy="注文変更ボタン"
                    >
                      {tc("注文変更")}
                    </Button>
                  </>
                )}
              </>
            )
        }
        {
          // 完了(BEMAC)
          inpOrder.statusClassValue === OrderStatus.COMPLETED &&
            auth.user().bemacFlg && (
              <>
                {!auth.isEngineer() && !inpOrder.dispatchFlg && (
                  <Button
                    className="b-btn-light"
                    onClick={(e) => handleClickOutputPdf()}
                    data-cy="注文書PDFボタン"
                  >
                    {tc("注文書PDF")}
                  </Button>
                )}
                {!readOnly && (
                  <>
                    {inpOrder.dispatchFlg && (
                      <>
                        <Button
                          className="b-btn-light"
                          onClick={(e) => handleClickOutputCompQuotationPdf()}
                          data-cy="工事明細書PDFボタン"
                        >
                          {tc("工事明細書PDF")}
                        </Button>
                        <Button
                          className="b-btn-light"
                          onClick={(e) =>
                            handleClickActionButton("完工見積一時保存")
                          }
                          data-cy="完工見積一時保存ボタン"
                        >
                          {tc("一時保存")}
                        </Button>
                        <Button
                          className="b-btn-light"
                          onClick={(e) => handleClickActionButton("完工見積")}
                          data-cy="完工見積ボタン"
                        >
                          {tc("完工見積")}
                        </Button>
                      </>
                    )}
                    {!inpOrder.dispatchFlg && (
                      <Button
                        className="b-btn-light"
                        onClick={(e) => handleClickActionButton("注文変更依頼")}
                        data-cy="注文変更依頼ボタン"
                      >
                        {tc("注文変更依頼")}
                      </Button>
                    )}
                    <Button
                      className="b-btn-primary"
                      onClick={(e) =>
                        handleClickActionButton("出荷後キャンセル")
                      }
                      data-cy="出荷後キャンセルボタン"
                    >
                      {tc("出荷後キャンセル")}
                    </Button>
                  </>
                )}
              </>
            )
        }
        {
          // 完了(顧客)
          inpOrder.statusClassValue === OrderStatus.COMPLETED &&
            !auth.user().bemacFlg && (
              <>
                <Button
                  className="b-btn-light"
                  onClick={(e) => handleClickOutputPdf()}
                  data-cy="注文書PDFボタン"
                >
                  {tc("注文書PDF")}
                </Button>
              </>
            )
        }
        <Button
          className="b-btn-close"
          onClick={handleClose}
          data-cy="Closeボタン"
        >
          Close
        </Button>
      </>
    );
  }

  // レンダリング
  return (
    <Modal
      fullscreen={true}
      show={show}
      onShow={handleShow}
      onHide={handleClose}
      scrollable
      data-cy="注文詳細モーダル"
    >
      <Modal.Header closeButton>
        <Modal.Title>{tc("注文詳細")}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="b-modal-detail"
        style={{ padding: "0px 12px 0px 12px" }}
      >
        {modalBody()}
      </Modal.Body>
      <Modal.Footer className="b-modal-detail">{modalFooter()}</Modal.Footer>
    </Modal>
  );
});

export default OrderDetail;
