import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import { useDialog } from "src/context/DialogProvider";
import useApi from "src/hook/useApi";
import { UsersGetResponse } from "src/model/api/response/UsersGetResponse";
import { UsersGetResponseSub } from "src/model/api/response/UsersGetResponseSub";
import { SelectOption, defaultSelectStyle } from "src/util/SelectUtil";

import { useAuthUserContext } from "../../context/AuthUser";
import { OrderStatus, QuotationStatus, TextConst } from "../../util/Constant";
import RequireMark from "../common/RequireMark";
import { OrderDetailChangeEvent, OrderDetailType } from "./OrderDetailHeader";

type Props = {
  readOnly: boolean;
  type: OrderDetailType;
  status: string;
  deliveryName: string;
  deliveryPostalCode: string;
  deliveryAddress: string;
  deliveryTel: string;
  bemacStartFlg?: boolean; // BEMAC起点フラグ
  reQuotationFlg: boolean; // 再見積フラグ
  /** 値変更イベント */
  onChange: (e: OrderDetailChangeEvent) => void;
};

function OrderDetailDelivery(props: Props) {
  const itemTitleStyle = { width: "11rem" };
  //未定フラグ
  const [deliveryUndecidedFlg, setDeliveryUndecidedFlg] = useState(false);

  // API使用宣言
  const api = useApi();
  //ダイアログ使用宣言
  const showDialog = useDialog();
  // ユーザ情報
  const auth = useAuthUserContext();
  // 翻訳
  const { t: tc } = useTranslation("OrderDetailDelivery");

  // 送付先コンボ
  const [deliverySuggest, setDeliverySuggest] = useState<
    SelectOption<string>[]
  >([]);

  // 初回レンダリングのみ実行
  useEffect(() => {
    if (editableItem()) {
      api
        .get<UsersGetResponse[]>(
          `/api/v1/users?userId=${auth.user().userId}&bemacFlg=${
            auth.user().bemacFlg
          }`
        )
        .then((response) => {
          if (
            response.data.length &&
            response.data[0].usersGetResponseSub?.length
          ) {
            const options: SelectOption<string>[] =
              response.data[0].usersGetResponseSub.map((row) => {
                return {
                  value: row.name,
                  label: row.name,
                  appendix: row,
                };
              });
            setDeliverySuggest(options);
          } else {
            setDeliverySuggest([]);
          }
        })
        .catch((error) => {
          // エラー時の処理
          showDialog({ error });
        });
    }
  }, []);

  // 注記表示判定
  function showsAddressAlert() {
    if (props.readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合
      if (auth.user().bemacFlg) {
        // BEMACユーザは非表示
        return false;
      }
      return editableItem();
    } else {
      // 注文の場合「作成中」は表示
      return props.status === OrderStatus.DRAFT;
    }
  }

  function handleChangeSelect(e: SingleValue<SelectOption<string | number>>) {
    if (!e?.appendix) {
      // 選択解除の場合は何もしない
      return;
    }

    // 元画面に連携
    props.onChange({
      name: "deliveryName",
      value: e?.appendix.name ?? "",
    });
    props.onChange({
      name: "deliveryPostalCode",
      value: e?.appendix.postalCode ?? "",
    });
    props.onChange({
      name: "deliveryAddress",
      value: e?.appendix.address ?? "",
    });
    props.onChange({
      name: "deliveryTel",
      value: e?.appendix.tel ?? "",
    });
  }

  // テキストボックス変更
  function handleChangeInput(
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const name = e.target.name;
    const value = e.target.value;
    // 元画面に連携
    props.onChange({ name, value });
  }

  // 項目編集可否判定
  function editableItem() {
    if (props.readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合
      // 再見積の場合はtrue(true：編集モード(入力可能))
      if (props.reQuotationFlg) {
        return true;
      }
      // 「作成中」の場合
      const statusList = [QuotationStatus.DRAFT];
      // 顧客起点で「依頼中」の場合、顧客のみ修正可能
      if (!props.bemacStartFlg && !auth.user().bemacFlg) {
        statusList.push(QuotationStatus.REQUESTED);
      }

      return statusList.includes(props.status);
    } else {
      // 注文の場合「作成中」「部品手配中」は修正可能
      return (
        props.status === OrderStatus.DRAFT ||
        props.status === OrderStatus.ARRANGING
      );
    }
  }

  // 注文の場合、否認時(=注文変更後)に送付先の編集可能
  function enableDelivery() {
    if (props.type === OrderDetailType.Order) {
      if (
        !auth.user().bemacFlg &&
        props.status === OrderStatus.REQUESTED_CHANGE
      ) {
        return true;
      }
      return false;
    }
  }

  //未定トグル押下イベント
  function deliveryUndecideFlgChange() {
    if (deliveryUndecidedFlg === false) {
      setDeliveryUndecidedFlg(true);
    } else {
      setDeliveryUndecidedFlg(false);
    }

    // 元画面に連携
    props.onChange({
      name: "deliveryName",
      value: !deliveryUndecidedFlg ? tc("未定") : "",
    });
    props.onChange({
      name: "deliveryPostalCode",
      value: !deliveryUndecidedFlg ? tc("未定") : "",
    });
    props.onChange({
      name: "deliveryAddress",
      value: !deliveryUndecidedFlg ? tc("未定") : "",
    });
    props.onChange({
      name: "deliveryTel",
      value: !deliveryUndecidedFlg ? tc("未定") : "",
    });
  }

  return (
    <>
      {editableItem() && (
        <div className="d-flex mb-1" style={{ alignItems: "center" }}>
          <div style={{ width: "8rem" }}>{tc("送付先住所参照")}</div>
          <Select
            styles={defaultSelectStyle}
            placeholder={TextConst.COMBO_PLACEHOLDER}
            isClearable
            options={deliverySuggest}
            onChange={handleChangeSelect}
          />
        </div>
      )}
      <div className="p-1 bg-white b-detail-items">
        {showsAddressAlert() && (
          <span style={{ color: "red" }}>
            {tc("※送付先未定の場合は「未定」ボタンを押してください。")}
          </span>
        )}
        <>
          {!props.readOnly && editableItem() && (
            <div className="mt3">
              <div className="input-group">
                <span className="" data-cy="未定">
                  {tc("未定")}　
                </span>
                <div
                  className="form-check form-switch"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "1.5",
                  }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={deliveryUndecidedFlg}
                    onChange={deliveryUndecideFlgChange}
                    data-cy="未定一括設定チェックボックス"
                  />
                </div>
              </div>
            </div>
          )}
        </>
        <div className="input-group">
          <span className="b-input-group-text" style={itemTitleStyle}>
            {tc("宛名")}
            {editableItem() && <RequireMark />}
          </span>
          <input
            type="text"
            name="deliveryName"
            className="form-control"
            style={{ width: "10%" }}
            disabled={
              (!editableItem() && !enableDelivery()) || deliveryUndecidedFlg
            }
            onChange={handleChangeInput}
            value={props.deliveryName}
            maxLength={64}
            data-cy="宛名テキスト"
          />
        </div>
        <div className="input-group">
          <span className="b-input-group-text" style={itemTitleStyle}>
            {tc("郵便番号")}
            {editableItem() && <RequireMark />}
          </span>
          <input
            type="text"
            name="deliveryPostalCode"
            className="form-control"
            autoComplete="postal-code"
            disabled={
              (!editableItem() && !enableDelivery()) || deliveryUndecidedFlg
            }
            onChange={handleChangeInput}
            value={props.deliveryPostalCode}
            maxLength={10}
            data-cy="郵便番号テキスト"
          />
        </div>
        <div className="input-group">
          <span className="b-input-group-text" style={itemTitleStyle}>
            {tc("住所")}
            {editableItem() && <RequireMark />}
          </span>
          <textarea
            name="deliveryAddress"
            className="form-control textarea"
            style={{ width: "50%", height: "115px" }}
            autoComplete="street-address"
            disabled={
              (!editableItem() && !enableDelivery()) || deliveryUndecidedFlg
            }
            onChange={handleChangeInput}
            value={props.deliveryAddress}
            maxLength={100}
            data-cy="住所テキスト"
          />
        </div>
        <div className="input-group">
          <span className="b-input-group-text" style={itemTitleStyle}>
            {tc("電話番号")}
            {editableItem() && <RequireMark />}
          </span>
          <input
            type="text"
            name="deliveryTel"
            className="form-control"
            autoComplete="postal-code"
            disabled={
              (!editableItem() && !enableDelivery()) || deliveryUndecidedFlg
            }
            onChange={handleChangeInput}
            value={props.deliveryTel}
            maxLength={13}
            data-cy="電話番号テキスト"
          />
        </div>
      </div>
    </>
  );
}

export default OrderDetailDelivery;
