/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatchReport } from "src/hook/useDispatchReport";
import { VesselSummaryGetResponse } from "src/model/api/response/VesselSummaryGetResponse";
import { VesselSummaryGetResponseSub3 } from "src/model/api/response/VesselSummaryGetResponseSub3";
import { VesselVisitsGetResponse } from "src/model/api/response/VesselVisitsGetResponse";
import { formatDate } from "src/util/ecUtil";

type Prop = {
  vesselSummary: VesselSummaryGetResponse | undefined;
};

//hover時のcss
const hoverCss = css`
  background-color: #f4f5f8;
  :hover {
    background-color: #e1e3ea;
    .viewmore {
      background-color: #5d5d5d;
      span {
        filter: invert(1);
      }
    }
  }
`;

// 対応内容、結果に表示する最大文字数
const TEXT_MAX_LEN = 200;

function MaintenanceHistoryArea(props: Prop) {
  //クエリパラメータの取り出し
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const queryImoNo = query.get("imono") ?? ""; // IMO番号

  // 翻訳
  const { t: tc } = useTranslation("MaintenanceHistoryArea");

  // 工事レポート
  const dispatchReport = useDispatchReport();

  // MAINTENANCE HISTORY情報
  // 訪船履歴
  const visit: VesselVisitsGetResponse | undefined =
    props.vesselSummary?.visitVesselHistory;
  // 不具合対応履歴
  const trouble: VesselSummaryGetResponseSub3 | undefined =
    props.vesselSummary?.troubleHistory;

  function cutText(text: string | null) {
    if (text && text.length > TEXT_MAX_LEN) {
      return text.substring(0, TEXT_MAX_LEN) + "...";
    }

    return text;
  }

  /** サービスレポートボタンクリック */
  function handleClickServiceReportButton(visit: VesselVisitsGetResponse) {
    const imono = visit.imoNo;
    const wbses = visit.wbses
      .flatMap((it) => it.wbs) // 配列の配列を1階層に変換
      .map((it) =>
        it && it.length >= 7
          ? it.substring(0, 3) + "-" + it.substring(3, 7)
          : ""
      ) // string|null を stringにするための変換
      .filter((it) => it); // 空文字を除去
    //wbesが空だったらシンガポール支社対応分か判定
    if (
      wbses.length == 0 &&
      visit.colorClass &&
      visit.estimatedTimeArrival &&
      (visit.colorClass === "海外出張" || visit.colorClass === "BESTA")
    ) {
      wbses.push(
        "SGP-" +
          formatDate(visit.estimatedTimeArrival, "YYYY") +
          "-" +
          formatDate(visit.estimatedTimeArrival, "MM")
      );
    }
    // ダウンロード
    dispatchReport.downloadServiceReports(imono, wbses);
  }

  /** その他資料ボタンクリック */
  function handleClickOtherFileButton(visit: VesselVisitsGetResponse) {
    const imono = visit.imoNo;
    const wbses = visit.wbses
      .flatMap((it) => it.wbs) // 配列の配列を1階層に変換
      .map((it) =>
        it && it.length >= 7
          ? it.substring(0, 3) + "-" + it.substring(3, 7)
          : ""
      ) // string|null を stringにするための変換
      .filter((it) => it); // 空文字を除去
    //wbesが空だったらシンガポール支社対応分か判定
    if (
      wbses.length == 0 &&
      visit.colorClass &&
      visit.estimatedTimeArrival &&
      (visit.colorClass === "海外出張" || visit.colorClass === "BESTA")
    ) {
      wbses.push(
        "SGP-" +
          formatDate(visit.estimatedTimeArrival, "YYYY") +
          "-" +
          formatDate(visit.estimatedTimeArrival, "MM")
      );
    }
    // ダウンロード
    dispatchReport.downloadOtherFiles(imono, wbses);
  }

  /** 不具合対応履歴枠のレンダー */
  function renderTroubleArea(trouble: VesselSummaryGetResponseSub3) {
    return (
      <div
        className="mb-3 p-2"
        style={{ border: "solid", borderWidth: "2px", borderColor: "silver" }}
      >
        <label style={{ fontWeight: "bold" }}>{tc("過去問い合わせ内容")}</label>
        <div>
          {tc("対応日")}：{formatDate(trouble.troubleNoticeDate)}
        </div>
        <div>
          {tc("製品情報")}：{trouble.productInfo}
        </div>
        <div>
          {tc("対応内容")}：
          <div className="ps-3" style={{ whiteSpace: "pre-wrap" }}>
            {cutText(trouble.troubleDetail)}
          </div>
        </div>
        <div>
          {tc("結果")}：
          <div className="ps-3" style={{ whiteSpace: "pre-wrap" }}>
            {cutText(trouble.result)}
          </div>
        </div>
      </div>
    );
  }

  /** 訪船履歴欄のレンダー */
  function renderVisitArea(visit: VesselVisitsGetResponse) {
    // 期間
    const period =
      formatDate(visit.estimatedTimeArrival) +
      "～" +
      formatDate(visit.estimatedTimeDeparture);
    // 人員
    const simplePersons: string[] = []; //  重複を除いた人員名の配列
    visit.operations
      .flatMap((it) => it.persons) // "配列の配列"を1階層に変換
      .forEach((it) => {
        if (it && simplePersons.indexOf(it) < 0) {
          // 値が設定されている、かつ初出の場合
          simplePersons.push(it);
        }
      });
    const persons = simplePersons.join(" ");

    // ボタン幅を合わせる
    const buttonWidth = "150px";

    return (
      <div
        className="mb-3 p-2"
        style={{ border: "solid", borderWidth: "2px", borderColor: "silver" }}
      >
        <label style={{ fontWeight: "bold" }}>{tc("訪船履歴")}</label>
        <div>
          {tc("期間")}：{period}
        </div>
        <div>
          {tc("場所")}：{visit.place}
        </div>
        <div>
          {tc("作業")}：{visit.operation}
        </div>
        <div>
          {tc("人員")}：{persons}
        </div>
        <div className="d-flex justify-content-end">
          <input
            type="button"
            className="btn b-btn-secondary btn-sm mt-2"
            style={
              visit.serviceReportFlg
                ? { width: buttonWidth }
                : { visibility: "hidden" }
            }
            value={tc("サービスレポート")}
            onClick={() => handleClickServiceReportButton(visit)}
          />
        </div>
        <div className="d-flex justify-content-end">
          <input
            type="button"
            className="btn b-btn-secondary btn-sm mt-2"
            value={tc("その他資料")}
            style={
              visit.checkListFlg
                ? { width: buttonWidth }
                : { visibility: "hidden" }
            }
            onClick={() => handleClickOtherFileButton(visit)}
          />
        </div>
      </div>
    );
  }

  // レンダリング
  return (
    <>
      <div className="m-0 h-100 d-flex flex-column">
        <span
          className="fs-6"
          style={{
            fontSize: "80%",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          MAINTENANCE HISTORY
        </span>
        <div className="d-flex justify-content-end">
          <Nav.Item css={hoverCss} style={{ borderRadius: "15px" }}>
            <Nav.Link
              className="viewmore"
              as={NavLink}
              to={`/maintenance-history-list?imono=${queryImoNo}`}
              style={{
                color: "black",
                borderRadius: "15px",
                fontSize: "13px",
              }}
              data-cy="MAINTENANCE HISTORY VIEW ALLリンク"
            >
              <span>VIEW ALL</span>
            </Nav.Link>
          </Nav.Item>
        </div>
        <div className="overflow-auto my-2" style={{ flexGrow: "1" }}>
          {trouble && renderTroubleArea(trouble)}
          {visit && renderVisitArea(visit)}
        </div>
      </div>
    </>
  );
}

export default MaintenanceHistoryArea;
