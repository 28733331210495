import { VesselProductsGetResponse } from "src/model/api/response/VesselProductsGetResponse";

import {
  GridRecommendProductType,
  MiddleType,
} from "../../component/lcm/RepairSchedule";
import { OrderStatus } from "../../util/Constant";

// 部品交換内容グリッドデータ取得
export function getGridRecommenProductList(
  vesselProductsGetResponse: VesselProductsGetResponse[],
  yearCheckValue: number
) {
  const vesselProductData: GridRecommendProductType[] =
    vesselProductsGetResponse.map((it: VesselProductsGetResponse) => {
      return {
        rowId: it.detailNo.toString(),
        display: undefined,
        largeClassName: it.largeClassName,
        middleClassName: it.middleClassName,
        discontinuedFlg: it.discontinuedFlg,
        replacementFlg: it.replacementFlg,
        productCode: it.productCode,
        productGroupName: it.productGroupName,
        spec: it.spec,
        recommendQuantity: it.recommendQuantity,
        recommend1: it.vesselProductsGetResponseSub[0].inspectionYearFlg
          ? "●"
          : "",
        done1:
          it.vesselProductsGetResponseSub[0].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[0].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation1: it.vesselProductsGetResponseSub[0]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice1:
          it.vesselProductsGetResponseSub[0].recommendDetailSubTotal,
        recommendSubtotal1: it.vesselProductsGetResponseSub[0].inspectionYearFlg
          ? it.vesselProductsGetResponseSub[0].recommendDetailSubTotal
          : undefined,
        doneSubtotal1:
          it.vesselProductsGetResponseSub[0].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[0].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal1:
          it.vesselProductsGetResponseSub[0].quotationReplyDetailSubTotal,
        quotationStatusClassValue1:
          it.vesselProductsGetResponseSub[0].quotationStatusClassValue,
        orderStatusClassValue1:
          it.vesselProductsGetResponseSub[0].orderStatusClassValue,
        recommend2:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[1].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[2].inspectionYearFlg
              ? "●"
              : ""
            : it.vesselProductsGetResponseSub[1].inspectionYearFlg
            ? "●"
            : "",
        done2:
          it.vesselProductsGetResponseSub[1].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[1].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation2: it.vesselProductsGetResponseSub[1]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice2:
          it.vesselProductsGetResponseSub[1].recommendDetailSubTotal,
        recommendSubtotal2:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[1].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[2].inspectionYearFlg
              ? it.vesselProductsGetResponseSub[1].recommendDetailSubTotal
              : undefined
            : it.vesselProductsGetResponseSub[1].inspectionYearFlg
            ? it.vesselProductsGetResponseSub[1].recommendDetailSubTotal
            : undefined,
        doneSubtotal2:
          it.vesselProductsGetResponseSub[1].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[1].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal2:
          it.vesselProductsGetResponseSub[1].quotationReplyDetailSubTotal,
        quotationStatusClassValue2:
          it.vesselProductsGetResponseSub[1].quotationStatusClassValue,
        orderStatusClassValue2:
          it.vesselProductsGetResponseSub[1].orderStatusClassValue,
        recommend3: it.vesselProductsGetResponseSub[2].inspectionYearFlg
          ? "●"
          : "",
        done3:
          it.vesselProductsGetResponseSub[2].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[2].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation3: it.vesselProductsGetResponseSub[2]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice3:
          it.vesselProductsGetResponseSub[2].recommendDetailSubTotal,
        recommendSubtotal3: it.vesselProductsGetResponseSub[2].inspectionYearFlg
          ? it.vesselProductsGetResponseSub[2].recommendDetailSubTotal
          : undefined,
        doneSubtotal3:
          it.vesselProductsGetResponseSub[2].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[2].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal3:
          it.vesselProductsGetResponseSub[2].quotationReplyDetailSubTotal,
        quotationStatusClassValue3:
          it.vesselProductsGetResponseSub[2].quotationStatusClassValue,
        orderStatusClassValue3:
          it.vesselProductsGetResponseSub[2].orderStatusClassValue,
        recommend4: it.vesselProductsGetResponseSub[3].inspectionYearFlg
          ? "●"
          : "",
        done4:
          it.vesselProductsGetResponseSub[3].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[3].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation4: it.vesselProductsGetResponseSub[3]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice4:
          it.vesselProductsGetResponseSub[3].recommendDetailSubTotal,
        recommendSubtotal4: it.vesselProductsGetResponseSub[3].inspectionYearFlg
          ? it.vesselProductsGetResponseSub[3].recommendDetailSubTotal
          : undefined,
        doneSubtotal4:
          it.vesselProductsGetResponseSub[3].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[3].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal4:
          it.vesselProductsGetResponseSub[3].quotationReplyDetailSubTotal,
        quotationStatusClassValue4:
          it.vesselProductsGetResponseSub[3].quotationStatusClassValue,
        orderStatusClassValue4:
          it.vesselProductsGetResponseSub[3].orderStatusClassValue,
        recommend5: it.vesselProductsGetResponseSub[4].inspectionYearFlg
          ? "●"
          : "",
        done5:
          it.vesselProductsGetResponseSub[4].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[4].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation5: it.vesselProductsGetResponseSub[4]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice5:
          it.vesselProductsGetResponseSub[4].recommendDetailSubTotal,
        recommendSubtotal5: it.vesselProductsGetResponseSub[4].inspectionYearFlg
          ? it.vesselProductsGetResponseSub[4].recommendDetailSubTotal
          : undefined,
        doneSubtotal5:
          it.vesselProductsGetResponseSub[4].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[4].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal5:
          it.vesselProductsGetResponseSub[4].quotationReplyDetailSubTotal,
        quotationStatusClassValue5:
          it.vesselProductsGetResponseSub[4].quotationStatusClassValue,
        orderStatusClassValue5:
          it.vesselProductsGetResponseSub[4].orderStatusClassValue,
        recommend6: it.vesselProductsGetResponseSub[5].inspectionYearFlg
          ? "●"
          : "",
        done6:
          it.vesselProductsGetResponseSub[5].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[5].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation6: it.vesselProductsGetResponseSub[5]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice6:
          it.vesselProductsGetResponseSub[5].recommendDetailSubTotal,
        recommendSubtotal6: it.vesselProductsGetResponseSub[5].inspectionYearFlg
          ? it.vesselProductsGetResponseSub[5].recommendDetailSubTotal
          : undefined,
        doneSubtotal6:
          it.vesselProductsGetResponseSub[5].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[5].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal6:
          it.vesselProductsGetResponseSub[5].quotationReplyDetailSubTotal,
        quotationStatusClassValue6:
          it.vesselProductsGetResponseSub[5].quotationStatusClassValue,
        orderStatusClassValue6:
          it.vesselProductsGetResponseSub[5].orderStatusClassValue,
        recommend7:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[6].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[7].inspectionYearFlg
              ? "●"
              : ""
            : it.vesselProductsGetResponseSub[6].inspectionYearFlg
            ? "●"
            : "",
        done7:
          it.vesselProductsGetResponseSub[6].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[6].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation7: it.vesselProductsGetResponseSub[6]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice7:
          it.vesselProductsGetResponseSub[6].recommendDetailSubTotal,
        recommendSubtotal7:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[6].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[7].inspectionYearFlg
              ? it.vesselProductsGetResponseSub[6].recommendDetailSubTotal
              : undefined
            : it.vesselProductsGetResponseSub[6].inspectionYearFlg
            ? it.vesselProductsGetResponseSub[6].recommendDetailSubTotal
            : undefined,
        doneSubtotal7:
          it.vesselProductsGetResponseSub[6].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[6].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal7:
          it.vesselProductsGetResponseSub[6].quotationReplyDetailSubTotal,
        quotationStatusClassValue7:
          it.vesselProductsGetResponseSub[6].quotationStatusClassValue,
        orderStatusClassValue7:
          it.vesselProductsGetResponseSub[6].orderStatusClassValue,
        recommend8: it.vesselProductsGetResponseSub[7].inspectionYearFlg
          ? "●"
          : "",
        done8:
          it.vesselProductsGetResponseSub[7].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[7].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation8: it.vesselProductsGetResponseSub[7]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice8:
          it.vesselProductsGetResponseSub[7].recommendDetailSubTotal,
        recommendSubtotal8: it.vesselProductsGetResponseSub[7].inspectionYearFlg
          ? it.vesselProductsGetResponseSub[7].recommendDetailSubTotal
          : undefined,
        doneSubtotal8:
          it.vesselProductsGetResponseSub[7].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[7].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal8:
          it.vesselProductsGetResponseSub[7].quotationReplyDetailSubTotal,
        quotationStatusClassValue8:
          it.vesselProductsGetResponseSub[7].quotationStatusClassValue,
        orderStatusClassValue8:
          it.vesselProductsGetResponseSub[7].orderStatusClassValue,
        recommend9: it.vesselProductsGetResponseSub[8].inspectionYearFlg
          ? "●"
          : "",
        done9:
          it.vesselProductsGetResponseSub[8].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[8].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation9: it.vesselProductsGetResponseSub[8]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice9:
          it.vesselProductsGetResponseSub[8].recommendDetailSubTotal,
        recommendSubtotal9: it.vesselProductsGetResponseSub[8].inspectionYearFlg
          ? it.vesselProductsGetResponseSub[8].recommendDetailSubTotal
          : undefined,
        doneSubtotal9:
          it.vesselProductsGetResponseSub[8].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[8].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal9:
          it.vesselProductsGetResponseSub[8].quotationReplyDetailSubTotal,
        quotationStatusClassValue9:
          it.vesselProductsGetResponseSub[8].quotationStatusClassValue,
        orderStatusClassValue9:
          it.vesselProductsGetResponseSub[8].orderStatusClassValue,
        recommend10: it.vesselProductsGetResponseSub[9].inspectionYearFlg
          ? "●"
          : "",
        done10:
          it.vesselProductsGetResponseSub[9].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[9].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation10: it.vesselProductsGetResponseSub[9]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice10:
          it.vesselProductsGetResponseSub[9].recommendDetailSubTotal,
        recommendSubtotal10: it.vesselProductsGetResponseSub[9]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[9].recommendDetailSubTotal
          : undefined,
        doneSubtotal10:
          it.vesselProductsGetResponseSub[9].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[9].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal10:
          it.vesselProductsGetResponseSub[9].quotationReplyDetailSubTotal,
        quotationStatusClassValue10:
          it.vesselProductsGetResponseSub[9].quotationStatusClassValue,
        orderStatusClassValue10:
          it.vesselProductsGetResponseSub[9].orderStatusClassValue,
        recommend11: it.vesselProductsGetResponseSub[10].inspectionYearFlg
          ? "●"
          : "",
        done11:
          it.vesselProductsGetResponseSub[10].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[10].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation11: it.vesselProductsGetResponseSub[10]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice11:
          it.vesselProductsGetResponseSub[10].recommendDetailSubTotal,
        recommendSubtotal11: it.vesselProductsGetResponseSub[10]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[10].recommendDetailSubTotal
          : undefined,
        doneSubtotal11:
          it.vesselProductsGetResponseSub[10].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[10].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal11:
          it.vesselProductsGetResponseSub[10].quotationReplyDetailSubTotal,
        quotationStatusClassValue11:
          it.vesselProductsGetResponseSub[10].quotationStatusClassValue,
        orderStatusClassValue11:
          it.vesselProductsGetResponseSub[10].orderStatusClassValue,
        recommend12:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[11].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[12].inspectionYearFlg
              ? "●"
              : ""
            : it.vesselProductsGetResponseSub[11].inspectionYearFlg
            ? "●"
            : "",
        done12:
          it.vesselProductsGetResponseSub[11].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[11].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation12: it.vesselProductsGetResponseSub[11]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice12:
          it.vesselProductsGetResponseSub[11].recommendDetailSubTotal,
        recommendSubtotal12:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[11].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[12].inspectionYearFlg
              ? it.vesselProductsGetResponseSub[11].recommendDetailSubTotal
              : undefined
            : it.vesselProductsGetResponseSub[11].inspectionYearFlg
            ? it.vesselProductsGetResponseSub[11].recommendDetailSubTotal
            : undefined,
        doneSubtotal12:
          it.vesselProductsGetResponseSub[11].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[11].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal12:
          it.vesselProductsGetResponseSub[11].quotationReplyDetailSubTotal,
        quotationStatusClassValue12:
          it.vesselProductsGetResponseSub[11].quotationStatusClassValue,
        orderStatusClassValue12:
          it.vesselProductsGetResponseSub[11].orderStatusClassValue,
        recommend13: it.vesselProductsGetResponseSub[12].inspectionYearFlg
          ? "●"
          : "",
        done13:
          it.vesselProductsGetResponseSub[12].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[12].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation13: it.vesselProductsGetResponseSub[12]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice13:
          it.vesselProductsGetResponseSub[12].recommendDetailSubTotal,
        recommendSubtotal13: it.vesselProductsGetResponseSub[12]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[12].recommendDetailSubTotal
          : undefined,
        doneSubtotal13:
          it.vesselProductsGetResponseSub[12].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[12].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal13:
          it.vesselProductsGetResponseSub[12].quotationReplyDetailSubTotal,
        quotationStatusClassValue13:
          it.vesselProductsGetResponseSub[12].quotationStatusClassValue,
        orderStatusClassValue13:
          it.vesselProductsGetResponseSub[12].orderStatusClassValue,
        recommend14: it.vesselProductsGetResponseSub[13].inspectionYearFlg
          ? "●"
          : "",
        done14:
          it.vesselProductsGetResponseSub[13].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[13].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation14: it.vesselProductsGetResponseSub[13]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice14:
          it.vesselProductsGetResponseSub[13].recommendDetailSubTotal,
        recommendSubtotal14: it.vesselProductsGetResponseSub[13]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[13].recommendDetailSubTotal
          : undefined,
        doneSubtotal14:
          it.vesselProductsGetResponseSub[13].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[13].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal14:
          it.vesselProductsGetResponseSub[13].quotationReplyDetailSubTotal,
        quotationStatusClassValue14:
          it.vesselProductsGetResponseSub[13].quotationStatusClassValue,
        orderStatusClassValue14:
          it.vesselProductsGetResponseSub[13].orderStatusClassValue,
        recommend15: it.vesselProductsGetResponseSub[14].inspectionYearFlg
          ? "●"
          : "",
        done15:
          it.vesselProductsGetResponseSub[14].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[14].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation15: it.vesselProductsGetResponseSub[14]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice15:
          it.vesselProductsGetResponseSub[14].recommendDetailSubTotal,
        recommendSubtotal15: it.vesselProductsGetResponseSub[14]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[14].recommendDetailSubTotal
          : undefined,
        doneSubtotal15:
          it.vesselProductsGetResponseSub[14].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[14].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal15:
          it.vesselProductsGetResponseSub[14].quotationReplyDetailSubTotal,
        quotationStatusClassValue15:
          it.vesselProductsGetResponseSub[14].quotationStatusClassValue,
        orderStatusClassValue15:
          it.vesselProductsGetResponseSub[14].orderStatusClassValue,
        recommend16: it.vesselProductsGetResponseSub[15].inspectionYearFlg
          ? "●"
          : "",
        done16:
          it.vesselProductsGetResponseSub[15].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[15].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation16: it.vesselProductsGetResponseSub[15]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice16:
          it.vesselProductsGetResponseSub[15].recommendDetailSubTotal,
        recommendSubtotal16: it.vesselProductsGetResponseSub[15]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[15].recommendDetailSubTotal
          : undefined,
        doneSubtotal16:
          it.vesselProductsGetResponseSub[15].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[15].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal16:
          it.vesselProductsGetResponseSub[15].quotationReplyDetailSubTotal,
        quotationStatusClassValue16:
          it.vesselProductsGetResponseSub[15].quotationStatusClassValue,
        orderStatusClassValue16:
          it.vesselProductsGetResponseSub[15].orderStatusClassValue,
        recommend17:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[16].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[17].inspectionYearFlg
              ? "●"
              : ""
            : it.vesselProductsGetResponseSub[16].inspectionYearFlg
            ? "●"
            : "",
        done17:
          it.vesselProductsGetResponseSub[16].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[16].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation17: it.vesselProductsGetResponseSub[16]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice17:
          it.vesselProductsGetResponseSub[16].recommendDetailSubTotal,
        recommendSubtotal17:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[16].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[17].inspectionYearFlg
              ? it.vesselProductsGetResponseSub[16].recommendDetailSubTotal
              : undefined
            : it.vesselProductsGetResponseSub[16].inspectionYearFlg
            ? it.vesselProductsGetResponseSub[16].recommendDetailSubTotal
            : undefined,
        doneSubtotal17:
          it.vesselProductsGetResponseSub[16].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[16].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal17:
          it.vesselProductsGetResponseSub[16].quotationReplyDetailSubTotal,
        quotationStatusClassValue17:
          it.vesselProductsGetResponseSub[16].quotationStatusClassValue,
        orderStatusClassValue17:
          it.vesselProductsGetResponseSub[16].orderStatusClassValue,
        recommend18: it.vesselProductsGetResponseSub[17].inspectionYearFlg
          ? "●"
          : "",
        done18:
          it.vesselProductsGetResponseSub[17].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[17].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation18: it.vesselProductsGetResponseSub[17]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice18:
          it.vesselProductsGetResponseSub[17].recommendDetailSubTotal,
        recommendSubtotal18: it.vesselProductsGetResponseSub[17]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[17].recommendDetailSubTotal
          : undefined,
        doneSubtotal18:
          it.vesselProductsGetResponseSub[17].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[17].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal18:
          it.vesselProductsGetResponseSub[17].quotationReplyDetailSubTotal,
        quotationStatusClassValue18:
          it.vesselProductsGetResponseSub[17].quotationStatusClassValue,
        orderStatusClassValue18:
          it.vesselProductsGetResponseSub[17].orderStatusClassValue,
        recommend19: it.vesselProductsGetResponseSub[18].inspectionYearFlg
          ? "●"
          : "",
        done19:
          it.vesselProductsGetResponseSub[18].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[18].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation19: it.vesselProductsGetResponseSub[18]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice19:
          it.vesselProductsGetResponseSub[18].recommendDetailSubTotal,
        recommendSubtotal19: it.vesselProductsGetResponseSub[18]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[18].recommendDetailSubTotal
          : undefined,
        doneSubtotal19:
          it.vesselProductsGetResponseSub[18].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[18].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal19:
          it.vesselProductsGetResponseSub[18].quotationReplyDetailSubTotal,
        quotationStatusClassValue19:
          it.vesselProductsGetResponseSub[18].quotationStatusClassValue,
        orderStatusClassValue19:
          it.vesselProductsGetResponseSub[18].orderStatusClassValue,
        recommend20: it.vesselProductsGetResponseSub[19].inspectionYearFlg
          ? "●"
          : "",
        done20:
          it.vesselProductsGetResponseSub[19].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[19].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation20: it.vesselProductsGetResponseSub[19]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice20:
          it.vesselProductsGetResponseSub[19].recommendDetailSubTotal,
        recommendSubtotal20: it.vesselProductsGetResponseSub[19]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[19].recommendDetailSubTotal
          : undefined,
        doneSubtotal20:
          it.vesselProductsGetResponseSub[19].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[19].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal20:
          it.vesselProductsGetResponseSub[19].quotationReplyDetailSubTotal,
        quotationStatusClassValue20:
          it.vesselProductsGetResponseSub[19].quotationStatusClassValue,
        orderStatusClassValue20:
          it.vesselProductsGetResponseSub[19].orderStatusClassValue,
        recommend21: it.vesselProductsGetResponseSub[20].inspectionYearFlg
          ? "●"
          : "",
        done21:
          it.vesselProductsGetResponseSub[20].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[20].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation21: it.vesselProductsGetResponseSub[20]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice21:
          it.vesselProductsGetResponseSub[20].recommendDetailSubTotal,
        recommendSubtotal21: it.vesselProductsGetResponseSub[20]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[20].recommendDetailSubTotal
          : undefined,
        doneSubtotal21:
          it.vesselProductsGetResponseSub[20].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[20].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal21:
          it.vesselProductsGetResponseSub[20].quotationReplyDetailSubTotal,
        quotationStatusClassValue21:
          it.vesselProductsGetResponseSub[20].quotationStatusClassValue,
        orderStatusClassValue21:
          it.vesselProductsGetResponseSub[20].orderStatusClassValue,
        recommend22:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[21].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[22].inspectionYearFlg
              ? "●"
              : ""
            : it.vesselProductsGetResponseSub[21].inspectionYearFlg
            ? "●"
            : "",
        done22:
          it.vesselProductsGetResponseSub[21].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[21].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation22: it.vesselProductsGetResponseSub[21]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice22:
          it.vesselProductsGetResponseSub[21].recommendDetailSubTotal,
        recommendSubtotal22:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[21].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[22].inspectionYearFlg
              ? it.vesselProductsGetResponseSub[21].recommendDetailSubTotal
              : undefined
            : it.vesselProductsGetResponseSub[21].inspectionYearFlg
            ? it.vesselProductsGetResponseSub[21].recommendDetailSubTotal
            : undefined,
        doneSubtotal22:
          it.vesselProductsGetResponseSub[21].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[21].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal22:
          it.vesselProductsGetResponseSub[21].quotationReplyDetailSubTotal,
        quotationStatusClassValue22:
          it.vesselProductsGetResponseSub[21].quotationStatusClassValue,
        orderStatusClassValue22:
          it.vesselProductsGetResponseSub[21].orderStatusClassValue,
        recommend23: it.vesselProductsGetResponseSub[22].inspectionYearFlg
          ? "●"
          : "",
        done23:
          it.vesselProductsGetResponseSub[22].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[22].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation23: it.vesselProductsGetResponseSub[22]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice23:
          it.vesselProductsGetResponseSub[22].recommendDetailSubTotal,
        recommendSubtotal23: it.vesselProductsGetResponseSub[22]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[22].recommendDetailSubTotal
          : undefined,
        doneSubtotal23:
          it.vesselProductsGetResponseSub[22].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[22].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal23:
          it.vesselProductsGetResponseSub[22].quotationReplyDetailSubTotal,
        quotationStatusClassValue23:
          it.vesselProductsGetResponseSub[22].quotationStatusClassValue,
        orderStatusClassValue23:
          it.vesselProductsGetResponseSub[22].orderStatusClassValue,
        recommend24: it.vesselProductsGetResponseSub[23].inspectionYearFlg
          ? "●"
          : "",
        done24:
          it.vesselProductsGetResponseSub[23].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[23].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation24: it.vesselProductsGetResponseSub[23]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice24:
          it.vesselProductsGetResponseSub[23].recommendDetailSubTotal,
        recommendSubtotal24: it.vesselProductsGetResponseSub[23]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[23].recommendDetailSubTotal
          : undefined,
        doneSubtotal24:
          it.vesselProductsGetResponseSub[23].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[23].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal24:
          it.vesselProductsGetResponseSub[23].quotationReplyDetailSubTotal,
        quotationStatusClassValue24:
          it.vesselProductsGetResponseSub[23].quotationStatusClassValue,
        orderStatusClassValue24:
          it.vesselProductsGetResponseSub[23].orderStatusClassValue,
        recommend25: it.vesselProductsGetResponseSub[24].inspectionYearFlg
          ? "●"
          : "",
        done25:
          it.vesselProductsGetResponseSub[24].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[24].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation25: it.vesselProductsGetResponseSub[24]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice25:
          it.vesselProductsGetResponseSub[24].recommendDetailSubTotal,
        recommendSubtotal25: it.vesselProductsGetResponseSub[24]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[24].recommendDetailSubTotal
          : undefined,
        doneSubtotal25:
          it.vesselProductsGetResponseSub[24].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[24].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal25:
          it.vesselProductsGetResponseSub[24].quotationReplyDetailSubTotal,
        quotationStatusClassValue25:
          it.vesselProductsGetResponseSub[24].quotationStatusClassValue,
        orderStatusClassValue25:
          it.vesselProductsGetResponseSub[24].orderStatusClassValue,
        recommend26: it.vesselProductsGetResponseSub[25].inspectionYearFlg
          ? "●"
          : "",
        done26:
          it.vesselProductsGetResponseSub[25].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[25].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation26: it.vesselProductsGetResponseSub[25]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice26:
          it.vesselProductsGetResponseSub[25].recommendDetailSubTotal,
        recommendSubtotal26: it.vesselProductsGetResponseSub[25]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[25].recommendDetailSubTotal
          : undefined,
        doneSubtotal26:
          it.vesselProductsGetResponseSub[25].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[25].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal26:
          it.vesselProductsGetResponseSub[25].quotationReplyDetailSubTotal,
        quotationStatusClassValue26:
          it.vesselProductsGetResponseSub[25].quotationStatusClassValue,
        orderStatusClassValue26:
          it.vesselProductsGetResponseSub[25].orderStatusClassValue,
        recommend27:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[26].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[27].inspectionYearFlg
              ? "●"
              : ""
            : it.vesselProductsGetResponseSub[26].inspectionYearFlg
            ? "●"
            : "",
        done27:
          it.vesselProductsGetResponseSub[26].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[26].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation27: it.vesselProductsGetResponseSub[26]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice27:
          it.vesselProductsGetResponseSub[26].recommendDetailSubTotal,
        recommendSubtotal27:
          yearCheckValue === 0
            ? it.vesselProductsGetResponseSub[26].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[27].inspectionYearFlg
              ? it.vesselProductsGetResponseSub[26].recommendDetailSubTotal
              : undefined
            : it.vesselProductsGetResponseSub[26].inspectionYearFlg
            ? it.vesselProductsGetResponseSub[26].recommendDetailSubTotal
            : undefined,
        doneSubtotal27:
          it.vesselProductsGetResponseSub[26].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[26].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal27:
          it.vesselProductsGetResponseSub[26].quotationReplyDetailSubTotal,
        quotationStatusClassValue27:
          it.vesselProductsGetResponseSub[26].quotationStatusClassValue,
        orderStatusClassValue27:
          it.vesselProductsGetResponseSub[26].orderStatusClassValue,
        recommend28: it.vesselProductsGetResponseSub[27].inspectionYearFlg
          ? "●"
          : "",
        done28:
          it.vesselProductsGetResponseSub[27].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[27].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation28: it.vesselProductsGetResponseSub[27]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice28:
          it.vesselProductsGetResponseSub[27].recommendDetailSubTotal,
        recommendSubtotal28: it.vesselProductsGetResponseSub[27]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[27].recommendDetailSubTotal
          : undefined,
        doneSubtotal28:
          it.vesselProductsGetResponseSub[27].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[27].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal28:
          it.vesselProductsGetResponseSub[27].quotationReplyDetailSubTotal,
        quotationStatusClassValue28:
          it.vesselProductsGetResponseSub[27].quotationStatusClassValue,
        orderStatusClassValue28:
          it.vesselProductsGetResponseSub[27].orderStatusClassValue,
        recommend29: it.vesselProductsGetResponseSub[28].inspectionYearFlg
          ? "●"
          : "",
        done29:
          it.vesselProductsGetResponseSub[28].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[28].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation29: it.vesselProductsGetResponseSub[28]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice29:
          it.vesselProductsGetResponseSub[28].recommendDetailSubTotal,
        recommendSubtotal29: it.vesselProductsGetResponseSub[28]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[28].recommendDetailSubTotal
          : undefined,
        doneSubtotal29:
          it.vesselProductsGetResponseSub[28].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[28].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal29:
          it.vesselProductsGetResponseSub[28].quotationReplyDetailSubTotal,
        quotationStatusClassValue29:
          it.vesselProductsGetResponseSub[28].quotationStatusClassValue,
        orderStatusClassValue29:
          it.vesselProductsGetResponseSub[28].orderStatusClassValue,
        recommend30: it.vesselProductsGetResponseSub[29].inspectionYearFlg
          ? "●"
          : "",
        done30:
          it.vesselProductsGetResponseSub[29].orderReplyDetailSubTotal &&
          it.vesselProductsGetResponseSub[29].orderStatusClassValue ===
            OrderStatus.COMPLETED
            ? "●"
            : "",
        quotation30: it.vesselProductsGetResponseSub[29]
          .quotationReplyDetailSubTotal
          ? true
          : false,
        recommendPrice30:
          it.vesselProductsGetResponseSub[29].recommendDetailSubTotal,
        recommendSubtotal30: it.vesselProductsGetResponseSub[29]
          .inspectionYearFlg
          ? it.vesselProductsGetResponseSub[29].recommendDetailSubTotal
          : undefined,
        doneSubtotal30:
          it.vesselProductsGetResponseSub[29].orderStatusClassValue ===
          OrderStatus.COMPLETED
            ? it.vesselProductsGetResponseSub[29].orderReplyDetailSubTotal
            : undefined,
        quotationSubtotal30:
          it.vesselProductsGetResponseSub[29].quotationReplyDetailSubTotal,
        quotationStatusClassValue30:
          it.vesselProductsGetResponseSub[29].quotationStatusClassValue,
        orderStatusClassValue30:
          it.vesselProductsGetResponseSub[29].orderStatusClassValue,
      };
    });
  return vesselProductData;
}

// 大分類行データ取得
export function getGridRecommenProductLargeClassList(
  gridRecommendProductList: GridRecommendProductType[]
) {
  const largeClassGroup: string[] = [];
  gridRecommendProductList.forEach((element) => {
    if (
      element.largeClassName &&
      !largeClassGroup.includes(element.largeClassName)
    ) {
      largeClassGroup.push(element.largeClassName);
    }
  });

  const result: GridRecommendProductType[] = [];
  largeClassGroup.forEach((largName) => {
    const filterData: GridRecommendProductType[] =
      gridRecommendProductList.filter((it) => it.largeClassName === largName);

    result.push({
      rowId: largName,
      display: "LARGE",
      largeClassName: largName,
      middleClassName: undefined,
      discontinuedFlg: undefined,
      replacementFlg: undefined,
      productCode: undefined,
      productGroupName: undefined,
      spec: undefined,
      recommendQuantity: undefined,
      recommend1: "",
      done1: "",
      quotation1: undefined,
      recommendPrice1: undefined,
      recommendSubtotal1: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal1 ?? 0);
      }, 0),
      doneSubtotal1: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal1 ?? 0);
      }, 0),
      quotationSubtotal1: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal1 ?? 0);
      }, 0),
      quotationStatusClassValue1: undefined,
      orderStatusClassValue1: undefined,
      recommend2: "",
      done2: "",
      quotation2: undefined,
      recommendPrice2: undefined,
      recommendSubtotal2: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal2 ?? 0);
      }, 0),
      doneSubtotal2: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal2 ?? 0);
      }, 0),
      quotationSubtotal2: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal2 ?? 0);
      }, 0),
      quotationStatusClassValue2: undefined,
      orderStatusClassValue2: undefined,
      recommend3: "",
      done3: "",
      quotation3: undefined,
      recommendPrice3: undefined,
      recommendSubtotal3: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal3 ?? 0);
      }, 0),
      doneSubtotal3: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal3 ?? 0);
      }, 0),
      quotationSubtotal3: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal3 ?? 0);
      }, 0),
      quotationStatusClassValue3: undefined,
      orderStatusClassValue3: undefined,
      recommend4: "",
      done4: "",
      quotation4: undefined,
      recommendPrice4: undefined,
      recommendSubtotal4: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal4 ?? 0);
      }, 0),
      doneSubtotal4: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal4 ?? 0);
      }, 0),
      quotationSubtotal4: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal4 ?? 0);
      }, 0),
      quotationStatusClassValue4: undefined,
      orderStatusClassValue4: undefined,
      recommend5: "",
      done5: "",
      quotation5: undefined,
      recommendPrice5: undefined,
      recommendSubtotal5: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal5 ?? 0);
      }, 0),
      doneSubtotal5: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal5 ?? 0);
      }, 0),
      quotationSubtotal5: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal5 ?? 0);
      }, 0),
      quotationStatusClassValue5: undefined,
      orderStatusClassValue5: undefined,
      recommend6: "",
      done6: "",
      quotation6: undefined,
      recommendPrice6: undefined,
      recommendSubtotal6: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal6 ?? 0);
      }, 0),
      doneSubtotal6: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal6 ?? 0);
      }, 0),
      quotationSubtotal6: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal6 ?? 0);
      }, 0),
      quotationStatusClassValue6: undefined,
      orderStatusClassValue6: undefined,
      recommend7: "",
      done7: "",
      quotation7: undefined,
      recommendPrice7: undefined,
      recommendSubtotal7: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal7 ?? 0);
      }, 0),
      doneSubtotal7: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal7 ?? 0);
      }, 0),
      quotationSubtotal7: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal7 ?? 0);
      }, 0),
      quotationStatusClassValue7: undefined,
      orderStatusClassValue7: undefined,
      recommend8: "",
      done8: "",
      quotation8: undefined,
      recommendPrice8: undefined,
      recommendSubtotal8: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal8 ?? 0);
      }, 0),
      doneSubtotal8: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal8 ?? 0);
      }, 0),
      quotationSubtotal8: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal8 ?? 0);
      }, 0),
      quotationStatusClassValue8: undefined,
      orderStatusClassValue8: undefined,
      recommend9: "",
      done9: "",
      quotation9: undefined,
      recommendPrice9: undefined,
      recommendSubtotal9: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal9 ?? 0);
      }, 0),
      doneSubtotal9: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal9 ?? 0);
      }, 0),
      quotationSubtotal9: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal9 ?? 0);
      }, 0),
      quotationStatusClassValue9: undefined,
      orderStatusClassValue9: undefined,
      recommend10: "",
      done10: "",
      quotation10: undefined,
      recommendPrice10: undefined,
      recommendSubtotal10: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal10 ?? 0);
      }, 0),
      doneSubtotal10: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal10 ?? 0);
      }, 0),
      quotationSubtotal10: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal10 ?? 0);
      }, 0),
      quotationStatusClassValue10: undefined,
      orderStatusClassValue10: undefined,
      recommend11: "",
      done11: "",
      quotation11: undefined,
      recommendPrice11: undefined,
      recommendSubtotal11: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal11 ?? 0);
      }, 0),
      doneSubtotal11: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal11 ?? 0);
      }, 0),
      quotationSubtotal11: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal11 ?? 0);
      }, 0),
      quotationStatusClassValue11: undefined,
      orderStatusClassValue11: undefined,
      recommend12: "",
      done12: "",
      quotation12: undefined,
      recommendPrice12: undefined,
      recommendSubtotal12: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal12 ?? 0);
      }, 0),
      doneSubtotal12: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal12 ?? 0);
      }, 0),
      quotationSubtotal12: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal12 ?? 0);
      }, 0),
      quotationStatusClassValue12: undefined,
      orderStatusClassValue12: undefined,
      recommend13: "",
      done13: "",
      quotation13: undefined,
      recommendPrice13: undefined,
      recommendSubtotal13: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal13 ?? 0);
      }, 0),
      doneSubtotal13: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal13 ?? 0);
      }, 0),
      quotationSubtotal13: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal13 ?? 0);
      }, 0),
      quotationStatusClassValue13: undefined,
      orderStatusClassValue13: undefined,
      recommend14: "",
      done14: "",
      quotation14: undefined,
      recommendPrice14: undefined,
      recommendSubtotal14: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal14 ?? 0);
      }, 0),
      doneSubtotal14: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal14 ?? 0);
      }, 0),
      quotationSubtotal14: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal14 ?? 0);
      }, 0),
      quotationStatusClassValue14: undefined,
      orderStatusClassValue14: undefined,
      recommend15: "",
      done15: "",
      quotation15: undefined,
      recommendPrice15: undefined,
      recommendSubtotal15: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal15 ?? 0);
      }, 0),
      doneSubtotal15: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal15 ?? 0);
      }, 0),
      quotationSubtotal15: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal15 ?? 0);
      }, 0),
      quotationStatusClassValue15: undefined,
      orderStatusClassValue15: undefined,
      recommend16: "",
      done16: "",
      quotation16: undefined,
      recommendPrice16: undefined,
      recommendSubtotal16: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal16 ?? 0);
      }, 0),
      doneSubtotal16: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal16 ?? 0);
      }, 0),
      quotationSubtotal16: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal16 ?? 0);
      }, 0),
      quotationStatusClassValue16: undefined,
      orderStatusClassValue16: undefined,
      recommend17: "",
      done17: "",
      quotation17: undefined,
      recommendPrice17: undefined,
      recommendSubtotal17: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal17 ?? 0);
      }, 0),
      doneSubtotal17: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal17 ?? 0);
      }, 0),
      quotationSubtotal17: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal17 ?? 0);
      }, 0),
      quotationStatusClassValue17: undefined,
      orderStatusClassValue17: undefined,
      recommend18: "",
      done18: "",
      quotation18: undefined,
      recommendPrice18: undefined,
      recommendSubtotal18: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal18 ?? 0);
      }, 0),
      doneSubtotal18: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal18 ?? 0);
      }, 0),
      quotationSubtotal18: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal18 ?? 0);
      }, 0),
      quotationStatusClassValue18: undefined,
      orderStatusClassValue18: undefined,
      recommend19: "",
      done19: "",
      quotation19: undefined,
      recommendPrice19: undefined,
      recommendSubtotal19: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal19 ?? 0);
      }, 0),
      doneSubtotal19: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal19 ?? 0);
      }, 0),
      quotationSubtotal19: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal19 ?? 0);
      }, 0),
      quotationStatusClassValue19: undefined,
      orderStatusClassValue19: undefined,
      recommend20: "",
      done20: "",
      quotation20: undefined,
      recommendPrice20: undefined,
      recommendSubtotal20: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal20 ?? 0);
      }, 0),
      doneSubtotal20: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal20 ?? 0);
      }, 0),
      quotationSubtotal20: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal20 ?? 0);
      }, 0),
      quotationStatusClassValue20: undefined,
      orderStatusClassValue20: undefined,
      recommend21: "",
      done21: "",
      quotation21: undefined,
      recommendPrice21: undefined,
      recommendSubtotal21: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal21 ?? 0);
      }, 0),
      doneSubtotal21: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal21 ?? 0);
      }, 0),
      quotationSubtotal21: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal21 ?? 0);
      }, 0),
      quotationStatusClassValue21: undefined,
      orderStatusClassValue21: undefined,
      recommend22: "",
      done22: "",
      quotation22: undefined,
      recommendPrice22: undefined,
      recommendSubtotal22: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal22 ?? 0);
      }, 0),
      doneSubtotal22: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal22 ?? 0);
      }, 0),
      quotationSubtotal22: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal22 ?? 0);
      }, 0),
      quotationStatusClassValue22: undefined,
      orderStatusClassValue22: undefined,
      recommend23: "",
      done23: "",
      quotation23: undefined,
      recommendPrice23: undefined,
      recommendSubtotal23: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal23 ?? 0);
      }, 0),
      doneSubtotal23: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal23 ?? 0);
      }, 0),
      quotationSubtotal23: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal23 ?? 0);
      }, 0),
      quotationStatusClassValue23: undefined,
      orderStatusClassValue23: undefined,
      recommend24: "",
      done24: "",
      quotation24: undefined,
      recommendPrice24: undefined,
      recommendSubtotal24: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal24 ?? 0);
      }, 0),
      doneSubtotal24: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal24 ?? 0);
      }, 0),
      quotationSubtotal24: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal24 ?? 0);
      }, 0),
      quotationStatusClassValue24: undefined,
      orderStatusClassValue24: undefined,
      recommend25: "",
      done25: "",
      quotation25: undefined,
      recommendPrice25: undefined,
      recommendSubtotal25: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal25 ?? 0);
      }, 0),
      doneSubtotal25: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal25 ?? 0);
      }, 0),
      quotationSubtotal25: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal25 ?? 0);
      }, 0),
      quotationStatusClassValue25: undefined,
      orderStatusClassValue25: undefined,
      recommend26: "",
      done26: "",
      quotation26: undefined,
      recommendPrice26: undefined,
      recommendSubtotal26: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal26 ?? 0);
      }, 0),
      doneSubtotal26: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal26 ?? 0);
      }, 0),
      quotationSubtotal26: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal26 ?? 0);
      }, 0),
      quotationStatusClassValue26: undefined,
      orderStatusClassValue26: undefined,
      recommend27: "",
      done27: "",
      quotation27: undefined,
      recommendPrice27: undefined,
      recommendSubtotal27: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal27 ?? 0);
      }, 0),
      doneSubtotal27: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal27 ?? 0);
      }, 0),
      quotationSubtotal27: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal27 ?? 0);
      }, 0),
      quotationStatusClassValue27: undefined,
      orderStatusClassValue27: undefined,
      recommend28: "",
      done28: "",
      quotation28: undefined,
      recommendPrice28: undefined,
      recommendSubtotal28: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal28 ?? 0);
      }, 0),
      doneSubtotal28: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal28 ?? 0);
      }, 0),
      quotationSubtotal28: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal28 ?? 0);
      }, 0),
      quotationStatusClassValue28: undefined,
      orderStatusClassValue28: undefined,
      recommend29: "",
      done29: "",
      quotation29: undefined,
      recommendPrice29: undefined,
      recommendSubtotal29: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal29 ?? 0);
      }, 0),
      doneSubtotal29: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal29 ?? 0);
      }, 0),
      quotationSubtotal29: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal29 ?? 0);
      }, 0),
      quotationStatusClassValue29: undefined,
      orderStatusClassValue29: undefined,
      recommend30: "",
      done30: "",
      quotation30: undefined,
      recommendPrice30: undefined,
      recommendSubtotal30: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal30 ?? 0);
      }, 0),
      doneSubtotal30: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal30 ?? 0);
      }, 0),
      quotationSubtotal30: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal30 ?? 0);
      }, 0),
      quotationStatusClassValue30: undefined,
      orderStatusClassValue30: undefined,
    });
  });
  return result;
}

// 中分類行データ取得
export function getGridRecommenProductMiddleClassList(
  gridRecommendProductList: GridRecommendProductType[]
) {
  const middleClassGroup: MiddleType[] = [];
  gridRecommendProductList.forEach((element) => {
    const checkData: MiddleType = {
      largeName: element.largeClassName,
      middleName: element.middleClassName,
    };
    if (
      !middleClassGroup.find((middle) => {
        return (
          middle.largeName === checkData.largeName &&
          middle.middleName === checkData.middleName
        );
      })
    ) {
      middleClassGroup.push(checkData);
    }
  });

  const result: GridRecommendProductType[] = [];
  middleClassGroup.forEach((middle) => {
    const filterData: GridRecommendProductType[] =
      gridRecommendProductList.filter(
        (it) =>
          it.middleClassName === middle.middleName &&
          it.largeClassName === middle.largeName
      );

    result.push({
      rowId: middle.middleName,
      display: "MIDDLE",
      largeClassName: middle.largeName,
      middleClassName: middle.middleName,
      discontinuedFlg: undefined,
      replacementFlg: undefined,
      productCode: undefined,
      productGroupName: undefined,
      spec: undefined,
      recommendQuantity: undefined,
      recommend1: "",
      done1: "",
      quotation1: undefined,
      recommendPrice1: undefined,
      recommendSubtotal1: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal1 ?? 0);
      }, 0),
      doneSubtotal1: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal1 ?? 0);
      }, 0),
      quotationSubtotal1: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal1 ?? 0);
      }, 0),
      quotationStatusClassValue1: undefined,
      orderStatusClassValue1: undefined,
      recommend2: "",
      done2: "",
      quotation2: undefined,
      recommendPrice2: undefined,
      recommendSubtotal2: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal2 ?? 0);
      }, 0),
      doneSubtotal2: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal2 ?? 0);
      }, 0),
      quotationSubtotal2: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal2 ?? 0);
      }, 0),
      quotationStatusClassValue2: undefined,
      orderStatusClassValue2: undefined,
      recommend3: "",
      done3: "",
      quotation3: undefined,
      recommendPrice3: undefined,
      recommendSubtotal3: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal3 ?? 0);
      }, 0),
      doneSubtotal3: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal3 ?? 0);
      }, 0),
      quotationSubtotal3: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal3 ?? 0);
      }, 0),
      quotationStatusClassValue3: undefined,
      orderStatusClassValue3: undefined,
      recommend4: "",
      done4: "",
      quotation4: undefined,
      recommendPrice4: undefined,
      recommendSubtotal4: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal4 ?? 0);
      }, 0),
      doneSubtotal4: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal4 ?? 0);
      }, 0),
      quotationSubtotal4: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal4 ?? 0);
      }, 0),
      quotationStatusClassValue4: undefined,
      orderStatusClassValue4: undefined,
      recommend5: "",
      done5: "",
      quotation5: undefined,
      recommendPrice5: undefined,
      recommendSubtotal5: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal5 ?? 0);
      }, 0),
      doneSubtotal5: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal5 ?? 0);
      }, 0),
      quotationSubtotal5: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal5 ?? 0);
      }, 0),
      quotationStatusClassValue5: undefined,
      orderStatusClassValue5: undefined,
      recommend6: "",
      done6: "",
      quotation6: undefined,
      recommendPrice6: undefined,
      recommendSubtotal6: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal6 ?? 0);
      }, 0),
      doneSubtotal6: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal6 ?? 0);
      }, 0),
      quotationSubtotal6: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal6 ?? 0);
      }, 0),
      quotationStatusClassValue6: undefined,
      orderStatusClassValue6: undefined,
      recommend7: "",
      done7: "",
      quotation7: undefined,
      recommendPrice7: undefined,
      recommendSubtotal7: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal7 ?? 0);
      }, 0),
      doneSubtotal7: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal7 ?? 0);
      }, 0),
      quotationSubtotal7: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal7 ?? 0);
      }, 0),
      quotationStatusClassValue7: undefined,
      orderStatusClassValue7: undefined,
      recommend8: "",
      done8: "",
      quotation8: undefined,
      recommendPrice8: undefined,
      recommendSubtotal8: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal8 ?? 0);
      }, 0),
      doneSubtotal8: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal8 ?? 0);
      }, 0),
      quotationSubtotal8: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal8 ?? 0);
      }, 0),
      quotationStatusClassValue8: undefined,
      orderStatusClassValue8: undefined,
      recommend9: "",
      done9: "",
      quotation9: undefined,
      recommendPrice9: undefined,
      recommendSubtotal9: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal9 ?? 0);
      }, 0),
      doneSubtotal9: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal9 ?? 0);
      }, 0),
      quotationSubtotal9: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal9 ?? 0);
      }, 0),
      quotationStatusClassValue9: undefined,
      orderStatusClassValue9: undefined,
      recommend10: "",
      done10: "",
      quotation10: undefined,
      recommendPrice10: undefined,
      recommendSubtotal10: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal10 ?? 0);
      }, 0),
      doneSubtotal10: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal10 ?? 0);
      }, 0),
      quotationSubtotal10: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal10 ?? 0);
      }, 0),
      quotationStatusClassValue10: undefined,
      orderStatusClassValue10: undefined,
      recommend11: "",
      done11: "",
      quotation11: undefined,
      recommendPrice11: undefined,
      recommendSubtotal11: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal11 ?? 0);
      }, 0),
      doneSubtotal11: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal11 ?? 0);
      }, 0),
      quotationSubtotal11: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal11 ?? 0);
      }, 0),
      quotationStatusClassValue11: undefined,
      orderStatusClassValue11: undefined,
      recommend12: "",
      done12: "",
      quotation12: undefined,
      recommendPrice12: undefined,
      recommendSubtotal12: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal12 ?? 0);
      }, 0),
      doneSubtotal12: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal12 ?? 0);
      }, 0),
      quotationSubtotal12: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal12 ?? 0);
      }, 0),
      quotationStatusClassValue12: undefined,
      orderStatusClassValue12: undefined,
      recommend13: "",
      done13: "",
      quotation13: undefined,
      recommendPrice13: undefined,
      recommendSubtotal13: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal13 ?? 0);
      }, 0),
      doneSubtotal13: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal13 ?? 0);
      }, 0),
      quotationSubtotal13: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal13 ?? 0);
      }, 0),
      quotationStatusClassValue13: undefined,
      orderStatusClassValue13: undefined,
      recommend14: "",
      done14: "",
      quotation14: undefined,
      recommendPrice14: undefined,
      recommendSubtotal14: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal14 ?? 0);
      }, 0),
      doneSubtotal14: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal14 ?? 0);
      }, 0),
      quotationSubtotal14: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal14 ?? 0);
      }, 0),
      quotationStatusClassValue14: undefined,
      orderStatusClassValue14: undefined,
      recommend15: "",
      done15: "",
      quotation15: undefined,
      recommendPrice15: undefined,
      recommendSubtotal15: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal15 ?? 0);
      }, 0),
      doneSubtotal15: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal15 ?? 0);
      }, 0),
      quotationSubtotal15: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal15 ?? 0);
      }, 0),
      quotationStatusClassValue15: undefined,
      orderStatusClassValue15: undefined,
      recommend16: "",
      done16: "",
      quotation16: undefined,
      recommendPrice16: undefined,
      recommendSubtotal16: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal16 ?? 0);
      }, 0),
      doneSubtotal16: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal16 ?? 0);
      }, 0),
      quotationSubtotal16: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal16 ?? 0);
      }, 0),
      quotationStatusClassValue16: undefined,
      orderStatusClassValue16: undefined,
      recommend17: "",
      done17: "",
      quotation17: undefined,
      recommendPrice17: undefined,
      recommendSubtotal17: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal17 ?? 0);
      }, 0),
      doneSubtotal17: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal17 ?? 0);
      }, 0),
      quotationSubtotal17: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal17 ?? 0);
      }, 0),
      quotationStatusClassValue17: undefined,
      orderStatusClassValue17: undefined,
      recommend18: "",
      done18: "",
      quotation18: undefined,
      recommendPrice18: undefined,
      recommendSubtotal18: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal18 ?? 0);
      }, 0),
      doneSubtotal18: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal18 ?? 0);
      }, 0),
      quotationSubtotal18: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal18 ?? 0);
      }, 0),
      quotationStatusClassValue18: undefined,
      orderStatusClassValue18: undefined,
      recommend19: "",
      done19: "",
      quotation19: undefined,
      recommendPrice19: undefined,
      recommendSubtotal19: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal19 ?? 0);
      }, 0),
      doneSubtotal19: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal19 ?? 0);
      }, 0),
      quotationSubtotal19: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal19 ?? 0);
      }, 0),
      quotationStatusClassValue19: undefined,
      orderStatusClassValue19: undefined,
      recommend20: "",
      done20: "",
      quotation20: undefined,
      recommendPrice20: undefined,
      recommendSubtotal20: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal20 ?? 0);
      }, 0),
      doneSubtotal20: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal20 ?? 0);
      }, 0),
      quotationSubtotal20: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal20 ?? 0);
      }, 0),
      quotationStatusClassValue20: undefined,
      orderStatusClassValue20: undefined,
      recommend21: "",
      done21: "",
      quotation21: undefined,
      recommendPrice21: undefined,
      recommendSubtotal21: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal21 ?? 0);
      }, 0),
      doneSubtotal21: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal21 ?? 0);
      }, 0),
      quotationSubtotal21: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal21 ?? 0);
      }, 0),
      quotationStatusClassValue21: undefined,
      orderStatusClassValue21: undefined,
      recommend22: "",
      done22: "",
      quotation22: undefined,
      recommendPrice22: undefined,
      recommendSubtotal22: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal22 ?? 0);
      }, 0),
      doneSubtotal22: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal22 ?? 0);
      }, 0),
      quotationSubtotal22: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal22 ?? 0);
      }, 0),
      quotationStatusClassValue22: undefined,
      orderStatusClassValue22: undefined,
      recommend23: "",
      done23: "",
      quotation23: undefined,
      recommendPrice23: undefined,
      recommendSubtotal23: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal23 ?? 0);
      }, 0),
      doneSubtotal23: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal23 ?? 0);
      }, 0),
      quotationSubtotal23: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal23 ?? 0);
      }, 0),
      quotationStatusClassValue23: undefined,
      orderStatusClassValue23: undefined,
      recommend24: "",
      done24: "",
      quotation24: undefined,
      recommendPrice24: undefined,
      recommendSubtotal24: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal24 ?? 0);
      }, 0),
      doneSubtotal24: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal24 ?? 0);
      }, 0),
      quotationSubtotal24: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal24 ?? 0);
      }, 0),
      quotationStatusClassValue24: undefined,
      orderStatusClassValue24: undefined,
      recommend25: "",
      done25: "",
      quotation25: undefined,
      recommendPrice25: undefined,
      recommendSubtotal25: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal25 ?? 0);
      }, 0),
      doneSubtotal25: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal25 ?? 0);
      }, 0),
      quotationSubtotal25: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal25 ?? 0);
      }, 0),
      quotationStatusClassValue25: undefined,
      orderStatusClassValue25: undefined,
      recommend26: "",
      done26: "",
      quotation26: undefined,
      recommendPrice26: undefined,
      recommendSubtotal26: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal26 ?? 0);
      }, 0),
      doneSubtotal26: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal26 ?? 0);
      }, 0),
      quotationSubtotal26: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal26 ?? 0);
      }, 0),
      quotationStatusClassValue26: undefined,
      orderStatusClassValue26: undefined,
      recommend27: "",
      done27: "",
      quotation27: undefined,
      recommendPrice27: undefined,
      recommendSubtotal27: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal27 ?? 0);
      }, 0),
      doneSubtotal27: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal27 ?? 0);
      }, 0),
      quotationSubtotal27: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal27 ?? 0);
      }, 0),
      quotationStatusClassValue27: undefined,
      orderStatusClassValue27: undefined,
      recommend28: "",
      done28: "",
      quotation28: undefined,
      recommendPrice28: undefined,
      recommendSubtotal28: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal28 ?? 0);
      }, 0),
      doneSubtotal28: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal28 ?? 0);
      }, 0),
      quotationSubtotal28: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal28 ?? 0);
      }, 0),
      quotationStatusClassValue28: undefined,
      orderStatusClassValue28: undefined,
      recommend29: "",
      done29: "",
      quotation29: undefined,
      recommendPrice29: undefined,
      recommendSubtotal29: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal29 ?? 0);
      }, 0),
      doneSubtotal29: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal29 ?? 0);
      }, 0),
      quotationSubtotal29: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal29 ?? 0);
      }, 0),
      quotationStatusClassValue29: undefined,
      orderStatusClassValue29: undefined,
      recommend30: "",
      done30: "",
      quotation30: undefined,
      recommendPrice30: undefined,
      recommendSubtotal30: filterData.reduce((a, x) => {
        return a + (x.recommendSubtotal30 ?? 0);
      }, 0),
      doneSubtotal30: filterData.reduce((a, x) => {
        return a + (x.doneSubtotal30 ?? 0);
      }, 0),
      quotationSubtotal30: filterData.reduce((a, x) => {
        return a + (x.quotationSubtotal30 ?? 0);
      }, 0),
      quotationStatusClassValue30: undefined,
      orderStatusClassValue30: undefined,
    });
  });
  return result;
}

// 合計行データ取得
export function getGridRecommenProductSumList(
  gridRecommendProductList: GridRecommendProductType[],
  summaryTitle: string
) {
  return [
    {
      rowId: "SUM",
      display: "SUM",
      largeClassName: summaryTitle,
      middleClassName: undefined,
      discontinuedFlg: undefined,
      replacementFlg: undefined,
      productCode: undefined,
      productGroupName: undefined,
      spec: undefined,
      recommendQuantity: undefined,
      recommend1: "",
      done1: "",
      quotation1: undefined,
      recommendPrice1: undefined,
      recommendSubtotal1: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal1 ?? 0);
      }, 0),
      doneSubtotal1: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal1 ?? 0);
      }, 0),
      quotationSubtotal1: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal1 ?? 0);
      }, 0),
      quotationStatusClassValue1: undefined,
      orderStatusClassValue1: undefined,
      recommend2: "",
      done2: "",
      quotation2: undefined,
      recommendPrice2: undefined,
      recommendSubtotal2: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal2 ?? 0);
      }, 0),
      doneSubtotal2: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal2 ?? 0);
      }, 0),
      quotationSubtotal2: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal2 ?? 0);
      }, 0),
      quotationStatusClassValue2: undefined,
      orderStatusClassValue2: undefined,
      recommend3: "",
      done3: "",
      quotation3: undefined,
      recommendPrice3: undefined,
      recommendSubtotal3: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal3 ?? 0);
      }, 0),
      doneSubtotal3: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal3 ?? 0);
      }, 0),
      quotationSubtotal3: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal3 ?? 0);
      }, 0),
      quotationStatusClassValue3: undefined,
      orderStatusClassValue3: undefined,
      recommend4: "",
      done4: "",
      quotation4: undefined,
      recommendPrice4: undefined,
      recommendSubtotal4: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal4 ?? 0);
      }, 0),
      doneSubtotal4: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal4 ?? 0);
      }, 0),
      quotationSubtotal4: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal4 ?? 0);
      }, 0),
      quotationStatusClassValue4: undefined,
      orderStatusClassValue4: undefined,
      recommend5: "",
      done5: "",
      quotation5: undefined,
      recommendPrice5: undefined,
      recommendSubtotal5: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal5 ?? 0);
      }, 0),
      doneSubtotal5: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal5 ?? 0);
      }, 0),
      quotationSubtotal5: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal5 ?? 0);
      }, 0),
      quotationStatusClassValue5: undefined,
      orderStatusClassValue5: undefined,
      recommend6: "",
      done6: "",
      quotation6: undefined,
      recommendPrice6: undefined,
      recommendSubtotal6: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal6 ?? 0);
      }, 0),
      doneSubtotal6: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal6 ?? 0);
      }, 0),
      quotationSubtotal6: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal6 ?? 0);
      }, 0),
      quotationStatusClassValue6: undefined,
      orderStatusClassValue6: undefined,
      recommend7: "",
      done7: "",
      quotation7: undefined,
      recommendPrice7: undefined,
      recommendSubtotal7: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal7 ?? 0);
      }, 0),
      doneSubtotal7: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal7 ?? 0);
      }, 0),
      quotationSubtotal7: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal7 ?? 0);
      }, 0),
      quotationStatusClassValue7: undefined,
      orderStatusClassValue7: undefined,
      recommend8: "",
      done8: "",
      quotation8: undefined,
      recommendPrice8: undefined,
      recommendSubtotal8: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal8 ?? 0);
      }, 0),
      doneSubtotal8: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal8 ?? 0);
      }, 0),
      quotationSubtotal8: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal8 ?? 0);
      }, 0),
      quotationStatusClassValue8: undefined,
      orderStatusClassValue8: undefined,
      recommend9: "",
      done9: "",
      quotation9: undefined,
      recommendPrice9: undefined,
      recommendSubtotal9: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal9 ?? 0);
      }, 0),
      doneSubtotal9: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal9 ?? 0);
      }, 0),
      quotationSubtotal9: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal9 ?? 0);
      }, 0),
      quotationStatusClassValue9: undefined,
      orderStatusClassValue9: undefined,
      recommend10: "",
      done10: "",
      quotation10: undefined,
      recommendPrice10: undefined,
      recommendSubtotal10: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal10 ?? 0);
      }, 0),
      doneSubtotal10: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal10 ?? 0);
      }, 0),
      quotationSubtotal10: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal10 ?? 0);
      }, 0),
      quotationStatusClassValue10: undefined,
      orderStatusClassValue10: undefined,
      recommend11: "",
      done11: "",
      quotation11: undefined,
      recommendPrice11: undefined,
      recommendSubtotal11: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal11 ?? 0);
      }, 0),
      doneSubtotal11: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal11 ?? 0);
      }, 0),
      quotationSubtotal11: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal11 ?? 0);
      }, 0),
      quotationStatusClassValue11: undefined,
      orderStatusClassValue11: undefined,
      recommend12: "",
      done12: "",
      quotation12: undefined,
      recommendPrice12: undefined,
      recommendSubtotal12: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal12 ?? 0);
      }, 0),
      doneSubtotal12: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal12 ?? 0);
      }, 0),
      quotationSubtotal12: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal12 ?? 0);
      }, 0),
      quotationStatusClassValue12: undefined,
      orderStatusClassValue12: undefined,
      recommend13: "",
      done13: "",
      quotation13: undefined,
      recommendPrice13: undefined,
      recommendSubtotal13: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal13 ?? 0);
      }, 0),
      doneSubtotal13: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal13 ?? 0);
      }, 0),
      quotationSubtotal13: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal13 ?? 0);
      }, 0),
      quotationStatusClassValue13: undefined,
      orderStatusClassValue13: undefined,
      recommend14: "",
      done14: "",
      quotation14: undefined,
      recommendPrice14: undefined,
      recommendSubtotal14: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal14 ?? 0);
      }, 0),
      doneSubtotal14: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal14 ?? 0);
      }, 0),
      quotationSubtotal14: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal14 ?? 0);
      }, 0),
      quotationStatusClassValue14: undefined,
      orderStatusClassValue14: undefined,
      recommend15: "",
      done15: "",
      quotation15: undefined,
      recommendPrice15: undefined,
      recommendSubtotal15: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal15 ?? 0);
      }, 0),
      doneSubtotal15: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal15 ?? 0);
      }, 0),
      quotationSubtotal15: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal15 ?? 0);
      }, 0),
      quotationStatusClassValue15: undefined,
      orderStatusClassValue15: undefined,
      recommend16: "",
      done16: "",
      quotation16: undefined,
      recommendPrice16: undefined,
      recommendSubtotal16: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal16 ?? 0);
      }, 0),
      doneSubtotal16: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal16 ?? 0);
      }, 0),
      quotationSubtotal16: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal16 ?? 0);
      }, 0),
      quotationStatusClassValue16: undefined,
      orderStatusClassValue16: undefined,
      recommend17: "",
      done17: "",
      quotation17: undefined,
      recommendPrice17: undefined,
      recommendSubtotal17: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal17 ?? 0);
      }, 0),
      doneSubtotal17: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal17 ?? 0);
      }, 0),
      quotationSubtotal17: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal17 ?? 0);
      }, 0),
      quotationStatusClassValue17: undefined,
      orderStatusClassValue17: undefined,
      recommend18: "",
      done18: "",
      quotation18: undefined,
      recommendPrice18: undefined,
      recommendSubtotal18: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal18 ?? 0);
      }, 0),
      doneSubtotal18: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal18 ?? 0);
      }, 0),
      quotationSubtotal18: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal18 ?? 0);
      }, 0),
      quotationStatusClassValue18: undefined,
      orderStatusClassValue18: undefined,
      recommend19: "",
      done19: "",
      quotation19: undefined,
      recommendPrice19: undefined,
      recommendSubtotal19: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal19 ?? 0);
      }, 0),
      doneSubtotal19: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal19 ?? 0);
      }, 0),
      quotationSubtotal19: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal19 ?? 0);
      }, 0),
      quotationStatusClassValue19: undefined,
      orderStatusClassValue19: undefined,
      recommend20: "",
      done20: "",
      quotation20: undefined,
      recommendPrice20: undefined,
      recommendSubtotal20: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal20 ?? 0);
      }, 0),
      doneSubtotal20: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal20 ?? 0);
      }, 0),
      quotationSubtotal20: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal20 ?? 0);
      }, 0),
      quotationStatusClassValue20: undefined,
      orderStatusClassValue20: undefined,
      recommend21: "",
      done21: "",
      quotation21: undefined,
      recommendPrice21: undefined,
      recommendSubtotal21: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal21 ?? 0);
      }, 0),
      doneSubtotal21: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal21 ?? 0);
      }, 0),
      quotationSubtotal21: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal21 ?? 0);
      }, 0),
      quotationStatusClassValue21: undefined,
      orderStatusClassValue21: undefined,
      recommend22: "",
      done22: "",
      quotation22: undefined,
      recommendPrice22: undefined,
      recommendSubtotal22: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal22 ?? 0);
      }, 0),
      doneSubtotal22: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal22 ?? 0);
      }, 0),
      quotationSubtotal22: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal22 ?? 0);
      }, 0),
      quotationStatusClassValue22: undefined,
      orderStatusClassValue22: undefined,
      recommend23: "",
      done23: "",
      quotation23: undefined,
      recommendPrice23: undefined,
      recommendSubtotal23: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal23 ?? 0);
      }, 0),
      doneSubtotal23: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal23 ?? 0);
      }, 0),
      quotationSubtotal23: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal23 ?? 0);
      }, 0),
      quotationStatusClassValue23: undefined,
      orderStatusClassValue23: undefined,
      recommend24: "",
      done24: "",
      quotation24: undefined,
      recommendPrice24: undefined,
      recommendSubtotal24: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal24 ?? 0);
      }, 0),
      doneSubtotal24: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal24 ?? 0);
      }, 0),
      quotationSubtotal24: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal24 ?? 0);
      }, 0),
      quotationStatusClassValue24: undefined,
      orderStatusClassValue24: undefined,
      recommend25: "",
      done25: "",
      quotation25: undefined,
      recommendPrice25: undefined,
      recommendSubtotal25: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal25 ?? 0);
      }, 0),
      doneSubtotal25: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal25 ?? 0);
      }, 0),
      quotationSubtotal25: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal25 ?? 0);
      }, 0),
      quotationStatusClassValue25: undefined,
      orderStatusClassValue25: undefined,
      recommend26: "",
      done26: "",
      quotation26: undefined,
      recommendPrice26: undefined,
      recommendSubtotal26: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal26 ?? 0);
      }, 0),
      doneSubtotal26: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal26 ?? 0);
      }, 0),
      quotationSubtotal26: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal26 ?? 0);
      }, 0),
      quotationStatusClassValue26: undefined,
      orderStatusClassValue26: undefined,
      recommend27: "",
      done27: "",
      quotation27: undefined,
      recommendPrice27: undefined,
      recommendSubtotal27: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal27 ?? 0);
      }, 0),
      doneSubtotal27: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal27 ?? 0);
      }, 0),
      quotationSubtotal27: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal27 ?? 0);
      }, 0),
      quotationStatusClassValue27: undefined,
      orderStatusClassValue27: undefined,
      recommend28: "",
      done28: "",
      quotation28: undefined,
      recommendPrice28: undefined,
      recommendSubtotal28: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal28 ?? 0);
      }, 0),
      doneSubtotal28: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal28 ?? 0);
      }, 0),
      quotationSubtotal28: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal28 ?? 0);
      }, 0),
      quotationStatusClassValue28: undefined,
      orderStatusClassValue28: undefined,
      recommend29: "",
      done29: "",
      quotation29: undefined,
      recommendPrice29: undefined,
      recommendSubtotal29: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal29 ?? 0);
      }, 0),
      doneSubtotal29: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal29 ?? 0);
      }, 0),
      quotationSubtotal29: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal29 ?? 0);
      }, 0),
      quotationStatusClassValue29: undefined,
      orderStatusClassValue29: undefined,
      recommend30: "",
      done30: "",
      quotation30: undefined,
      recommendPrice30: undefined,
      recommendSubtotal30: gridRecommendProductList.reduce((a, x) => {
        return a + (x.recommendSubtotal30 ?? 0);
      }, 0),
      doneSubtotal30: gridRecommendProductList.reduce((a, x) => {
        return a + (x.doneSubtotal30 ?? 0);
      }, 0),
      quotationSubtotal30: gridRecommendProductList.reduce((a, x) => {
        return a + (x.quotationSubtotal30 ?? 0);
      }, 0),
      quotationStatusClassValue30: undefined,
      orderStatusClassValue30: undefined,
    },
  ];
}
