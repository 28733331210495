import {
  CellValueChangedEvent,
  ICellRendererParams,
  RowDragEndEvent,
  RowDragEnterEvent,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiOutlineTrash } from "react-icons/hi";
import { QuotationDetailInitialValueParam } from "src/model/param/QuotationDetailInitialValueParam";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { RecommendsPostRequest } from "../../model/api/request/RecommendsPostRequest";
import { ProductGetResponse } from "../../model/api/response/ProductGetResponse";
import { ProductsGetResponse } from "../../model/api/response/ProductsGetResponse";
import { RecommendGetResponse } from "../../model/api/response/RecommendGetResponse";
import { RecommendGetResponseSub } from "../../model/api/response/RecommendGetResponseSub";
import { agNumberFormatter, defaultColDef } from "../../util/AgGridUtil";
import { RecommendStatusClassValue } from "../../util/Constant";
import { calculateTax } from "../../util/TaxUtil";
import {
  convertRecomendStatusName,
  formatDate,
  getBizError,
} from "../../util/ecUtil";
import RequireMark from "../common/RequireMark";
import S3Uploader, { S3UploaderHandles } from "../common/S3Uploader";
import OrderDetail, { OrderDetailHandles } from "../order/OrderDetail";
import QuotationDetail, {
  QuotationDetailHandles,
} from "../order/QuotationDetail";
import CompanyDetail, { CompanyDetailHandles } from "../setting/CompanyDetail";
import ProductListModal, {
  ProductListModalHandles,
} from "../setting/ProductListModal";
import UserDetail, {
  UserDetailHandles,
  statusBrowse,
} from "../setting/UserDetail";

// 明細データの型
type DetailRowType = {
  recommendId?: number;
  recommendDetailNo: number;
  productCode?: string;
  productGroupName?: string;
  spec?: string;
  recommendQuantity?: number;
  unit?: string;
  orderQuantity?: number;
};

//提案営業の型
type recommendSalesType = {
  userId: number | undefined;
  userName: string | undefined;
};

// 公開する関数の定義
export interface RecommendDetailHandles {
  show(
    recommendId: number | undefined,
    searchValues?: any,
    searchValueLabels?: any
  ): void;
}

type Props = {
  search: () => void;
  searchValues?: any;
  searchValueLabels?: any;
};

//レコメンド詳細コンポーネント
const RecommendDetail = forwardRef<RecommendDetailHandles, Props>(
  (props: Props, ref) => {
    //API使用宣言
    const api = useApi();
    //スプラッシュメッセージ使用宣言
    const splashMessage = useSplashMessage();
    //ダイアログ使用宣言
    const showDialog = useDialog();
    // 翻訳
    const { t: tc } = useTranslation("RecommendDetail");
    // ファイル添付
    const uploaderRef = useRef<S3UploaderHandles>(null);
    //ユーザー情報
    const user = useAuthUserContext().user();
    //エンジニアか否か
    const isEngineer = useAuthUserContext().isEngineer();

    // state
    const [show, setShow] = useState(false);
    const [inputDatas, setInputDatas] = useState<DetailRowType[]>([]); // 明細
    //新規作成・編集時の初期レコメンドデータ
    const [recommend, setRecommend] = useState<
      RecommendsPostRequest | undefined
    >();
    //登録用レコメンドデータ
    const [postRequest, setPostRequest] = useState<RecommendsPostRequest>(
      generatePostRequest()
    );
    //提案金額合計
    const [quotationTotalPrice, setQuotationTotalPrice] = useState<
      number | undefined
    >();
    //実施金額
    const [orderTotalPrice, setOrderTotalPrice] = useState<
      number | undefined
    >();
    //提案営業
    const [recommendSales, setRecommendSales] = useState<recommendSalesType>(); // 提案営業

    //参照
    const gridRef = useRef<AgGridReact>(null);
    const productListModalRef = useRef<ProductListModalHandles>(null);
    const quotationDetailRef = useRef<QuotationDetailHandles>(null);
    const orderDetailRef = useRef<OrderDetailHandles>(null);
    const companyDetailRef = useRef<CompanyDetailHandles>(null);
    const userDetailRef = useRef<UserDetailHandles>(null);

    //公開する関数の実装
    useImperativeHandle(ref, () => ({
      show(
        recommendId: number | undefined,
        searchValues: any,
        searchValueLabels: any
      ) {
        recommendSearch(recommendId, searchValues, searchValueLabels);
        setShow(true);
      },
    }));

    //recommend.s3Prefix更新時(=レコメンド詳細画面開閉時)のみ実行
    useEffect(() => {
      uploaderRef.current?.init(postRequest.s3Prefix!, false);
    }, [recommend?.s3Prefix]);

    //見出しに割り当てるstyle
    const headlineStyle = {
      width: "170px",
    };

    // グリッドのセル値変更イベント
    const onCellValueChanged = (event: CellValueChangedEvent) => {
      // 自身が最終行なら一行追加する
      if (event.rowIndex === inputDatas.length - 1) {
        setInputDatas((prev) => {
          const newRows = prev.concat();
          addNewRow(newRows);
          return newRows;
        });
      }

      // 品目コード変更時は商品単一取得API実行
      if (event.column.getColId() === "productCode") {
        const productCode = encodeURIComponent(event.value + "");
        if (productCode) {
          api
            .get(`/api/v1/products/${productCode}`)
            .then((response) => {
              setInputDatas((prev) => {
                const newRows = prev.map((it, index) => {
                  if (index === event.rowIndex) {
                    const data = response.data as ProductGetResponse;
                    const newRow = prev[event.rowIndex ?? 0];
                    newRow.productGroupName = data.productGroupName;
                    newRow.spec = data.spec;
                    newRow.unit = data.unit;
                    return newRow;
                  } else {
                    return it;
                  }
                });
                return newRows;
              });
            })
            .catch((error) => {
              if (error.response.status === 404) {
                showDialog({ id: "E029" });
              } else {
                showDialog({ error });
              }
            });
        } else {
          //品目コードクリア時はレコードの値をクリアする
          setInputDatas((prev) => {
            const newRows = prev.map((it, index) => {
              if (index === event.rowIndex) {
                const newRow = {
                  recommendDetailNo: it.recommendDetailNo,
                };
                return newRow;
              } else {
                return it;
              }
            });
            return newRows;
          });
        }
      }
    };

    // 行ドラッグ
    let dragRowIndex = -1;
    const onRowDragEnter = useCallback((e: RowDragEnterEvent) => {
      dragRowIndex = e.overIndex;
    }, []);
    const onRowDragEnd = useCallback((e: RowDragEndEvent) => {
      // 並び替え後の順番でNoをつけなおす
      const newDatas: DetailRowType[] = [];
      gridRef.current?.api.forEachNode((node: any, index: any) => {
        const row = node.data;
        row.recommendDetailNo = index + 1;
        newDatas.push(row);
      });
      // 最下行を移動させた場合は新規行を追加
      if (
        dragRowIndex !== e.overIndex &&
        dragRowIndex === newDatas.length - 1
      ) {
        addNewRow(newDatas);
      }
      setInputDatas(newDatas);
    }, []);

    // 行削除
    function onClickDeleteButton(recommendDetailNo: number) {
      // 自身の行を削除し、その他の行のrecommendDetailNoを採番しなおす
      const newRows = inputDatas
        .filter((it) => it.recommendDetailNo !== recommendDetailNo)
        .map((it, itIndex) => {
          it.recommendDetailNo = itIndex + 1;
          return it;
        });
      setInputDatas(newRows);
    }

    //編集可否判定
    function canEdit() {
      return postRequest.statusClassValue === RecommendStatusClassValue.DRAFT;
    }

    function isEmptyRow(row: any) {
      // 入力項目がすべて空の場合は空行とみなす
      if (row.productCode) {
        return false;
      }
      if (row.recommendNum) {
        return false;
      }

      return true;
    }

    // 新規行追加
    function addNewRow(rows: DetailRowType[]) {
      const newRow = {
        recommendDetailNo: rows.length + 1,
      };
      rows.push(newRow);
    }

    // 商品一覧で部品を選択
    function handleSelectProducts(products: ProductsGetResponse[]) {
      // 最終行の新規行を除いてリストを再生成
      const newInputDatas = [...inputDatas].filter(
        (it) => it.recommendDetailNo !== inputDatas.length || !isEmptyRow(it)
      );
      let nextNo = newInputDatas.length + 1;
      const addDatas = products.map((it) => {
        return {
          recommendDetailNo: nextNo++,
          productCode: it.productCode,
          productGroupName: it.productGroupName,
          spec: it.spec,
          unit: it.unit,
        };
      });
      // 新規行を追加
      const emptyRow = {
        recommendDetailNo: nextNo,
      };
      // stateに保存
      const newRows = [...newInputDatas, ...addDatas, emptyRow];
      setInputDatas(newRows);
    }

    // モーダルクローズハンドラ
    const handleClose = () => {
      //レコメンド・明細・添付ファイルに変更があれば確認ダイアログを出す。
      if (
        // レコメンドに変更がある
        JSON.stringify(recommend) !== JSON.stringify(postRequest) ||
        // 更新モードで明細に変更がある
        (recommend?.recommendId &&
          JSON.stringify(
            recommend?.recommendsPostRequestSub.map((it) => {
              // 見積作成時や注文作成時の再取得で更新日付でひっかかるため除外
              return {
                // 見積注文後も再読み込みで注文情報で差異がでるため、注文情報の項目を除外
                recommendId: it.recommendId,
                recommendDetailNo: it.recommendDetailNo,
                productCode: it.productCode,
                productGroupName: it.productGroupName,
                spec: it.spec,
                recommendQuantity: it.recommendQuantity,
                unit: it.unit,
              };
            })
          ) !==
            JSON.stringify(
              inputDatas
                .filter(
                  (fil) =>
                    fil.recommendId ||
                    fil.productCode ||
                    fil.productGroupName ||
                    fil.spec ||
                    fil.recommendQuantity ||
                    fil.unit
                )
                .map((it) => {
                  return {
                    // 見積注文後も再読み込みで注文情報で差異がでるため、注文情報の項目を除外
                    recommendId: it.recommendId,
                    recommendDetailNo: it.recommendDetailNo,
                    productCode: it.productCode,
                    productGroupName: it.productGroupName,
                    spec: it.spec,
                    recommendQuantity: it.recommendQuantity,
                    unit: it.unit,
                  };
                })
            )) ||
        // 新規モードで明細が空行以外にもある
        (!recommend?.recommendId && inputDatas.length > 1) ||
        // 添付ファイルに変更がある
        uploaderRef.current?.hasChanged()
      ) {
        showDialog({
          id: "I001",
          confirm: true,
          callback(isOk) {
            if (isOk) {
              setRecommend(undefined);
              setShow(false);
            }
          },
        });
      } else {
        setInputDatas([]);
        setRecommend(undefined);
        setShow(false);
      }
    };

    //テキストボックス/エリア入力値変更時の処理
    function handleChangeInput(
      e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) {
      const name = e.target.name;
      const value = e.target.value;
      setPostRequest({ ...postRequest, [name]: value });
    }

    //トグル切り替え時の処理
    function handleChangeToggle(e: ChangeEvent<HTMLInputElement>) {
      const name = e.target.name;
      const value = e.target.checked;
      setPostRequest({ ...postRequest, [name]: value });
    }

    //部品検索ボタン押下時の処理
    function onClickSearchButton() {
      productListModalRef.current?.show();
    }

    //見積ボタン押下時の処理
    function onClickQuotationButton() {
      if (postRequest.quotationNo === undefined) {
        // 見積画面に渡すパラメータを組み立てる
        const param: QuotationDetailInitialValueParam = {
          companyCode: recommend?.companyCode ?? "",
          companyName: recommend?.companyName ?? "",
          imoNo: recommend?.imoNo ?? "",
          vesselName: recommend?.vesselName ?? "",
          vesselBuilder: recommend?.vesselBuilder ?? "",
          yardNo: recommend?.yardNo ?? "",
          dispatchFlg: recommend?.dispatchFlg ?? false,
          details: inputDatas.map((it) => {
            return {
              referenceDetailNo: it.recommendDetailNo,
              productCode: it.productCode ?? "",
              quantity: it.recommendQuantity ?? 0,
            };
          }),
        };
        quotationDetailRef.current?.show({
          initialData: param,
          recommendKey: { recommendId: recommend?.recommendId ?? 0 },
        });
      } else {
        // 該当の見積が存在するかをチェック
        api
          .get(`/api/v1/quotations/${postRequest.quotationNo}`)
          .then((response) => {
            quotationDetailRef.current?.show({
              quotationNo: recommend?.quotationNo,
            });
          })
          .catch((error) => {
            // エラー時の処理
            if (error.response.status === 404) {
              //エラーメッセージの表示
              showDialog({ id: "E077" });
            } else {
              showDialog({ error });
            }
          });
      }
    }

    //注文内容ボタン押下時の処理
    function onClickOrderContentButton() {
      if (recommend?.orderNo) {
        api
          .get(`/api/v1/orders/${recommend?.orderNo}`)
          .then((response) => {
            orderDetailRef.current?.show({ orderNo: recommend?.orderNo });
          })
          .catch((error) => {
            // エラー時の処理
            if (error.response.status === 404) {
              //エラーメッセージの表示
              showDialog({ id: "E078" });
            } else {
              showDialog({ error });
            }
          });
      } else {
        orderDetailRef.current?.show({ orderNo: recommend?.orderNo });
      }
    }

    //一時保存押下時の処理
    function onClickTmpSaveButton() {
      // 明細の編集を停止
      gridRef.current?.api.stopEditing();

      save(false);
    }

    //登録ボタン押下時の処理
    function onClickRegistButton() {
      // 明細の編集を停止
      gridRef.current?.api.stopEditing();

      showDialog({
        id: "I006",
        confirm: true,
        callback(isOk) {
          if (isOk) {
            save(true);
          }
        },
      });
    }

    //ファイル登録→DB登録→再検索を行う処理
    function save(isRegist: boolean) {
      uploaderRef.current?.save().then(() => {
        const requestSub: RecommendGetResponseSub[] = inputDatas.map((it) => {
          return {
            recommendId: it.recommendId,
            recommendDetailNo: it.recommendDetailNo,
            productCode: it.productCode,
            productGroupName: it.productGroupName,
            spec: it.spec,
            unit: it.unit,
            recommendQuantity: it.recommendQuantity,
            updateDateTime: postRequest.updateDateTime,
          };
        });
        //新規行を削除
        requestSub.pop();

        let request: RecommendsPostRequest = {
          ...postRequest,
          recommendsPostRequestSub: requestSub,
        };
        if (isRegist) {
          request.statusClassValue = RecommendStatusClassValue.UNRECOMMENDED;
        }
        //レコメンド登録API実行
        api
          .post(`/api/v1/recommends`, request)
          .then((response) => {
            props.search();
            splashMessage.show("I035");
            setRecommend(undefined);
            setShow(false);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              showDialog({ id: "E027" });
            } else {
              if (getBizError(error)) {
                //対応するエラーメッセージを表示
                showDialog({ error });
              } else {
                showDialog({ id: "E026" });
              }
            }
          });
      });
    }

    //リクエストを作成する
    function generatePostRequest(
      searchValues?: any,
      recommend?: RecommendGetResponse,
      searchValueLabels?: any
    ): RecommendsPostRequest {
      return {
        recommendId: recommend?.recommendId ?? undefined,
        statusClassValue:
          recommend?.statusClassValue ?? RecommendStatusClassValue.DRAFT,
        companyCode: searchValues?.companyCode ?? "",
        companyName: searchValueLabels?.companyName ?? "",
        imoNo: searchValues?.imoNo ?? "",
        vesselName: searchValueLabels?.vesselName ?? "",
        vesselBuilder: recommend?.vesselBuilder ?? "",
        yardNo: recommend?.yardNo ?? "",
        title: recommend?.title ?? "",
        recommendLimitDate: recommend?.recommendLimitDate ?? "",
        dispatchFlg: recommend?.dispatchFlg ?? true,
        recommendUserId: recommend?.recommendUserId ?? user.userId,
        recommendUserName: recommend?.recommendUserName ?? user.userName,
        recommendComment: recommend?.recommendComment ?? "",
        recommendCommentDetail: recommend?.recommendCommentDetail ?? "",
        recommendDateTime: recommend?.recommendDateTime ?? "",
        quotationNo: recommend?.quotationNo ?? undefined,
        orderNo: recommend?.orderNo ?? undefined,
        s3Prefix: recommend?.s3Prefix ?? getDefaultS3Prefix(),
        updateDateTime: recommend?.updateDateTime ?? "",
        recommendsPostRequestSub: recommend?.recommendGetResponseSub ?? [],
      };
    }

    //デフォルトのs3プリフィクスを取得する
    function getDefaultS3Prefix() {
      const datetime = formatDate(new Date(), "YYYYMMDDHHmmss");
      return `recommend/${datetime}-${user.userId}/`;
    }

    // グリッド内の削除ボタン使用可否
    function canDeleteRow(rowData: ICellRendererParams<DetailRowType>) {
      // 最終行の場合は削除不可
      if (rowData.data?.recommendDetailNo === inputDatas.length) {
        return false;
      }
      return true;
    }

    // 削除ボタンレンダリング
    function rendererDeleteButton(rowData: ICellRendererParams<DetailRowType>) {
      if (canDeleteRow(rowData)) {
        return (
          <span>
            <a
              style={{ color: "black" }}
              href="#"
              onClick={(e) =>
                onClickDeleteButton(rowData.data?.recommendDetailNo ?? -1)
              }
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <HiOutlineTrash size={20} style={{ margin: "10 10px" }} />
              </div>
            </a>
          </span>
        );
      } else {
        return <></>;
      }
    }

    // グリッドの列定義
    let columnDefs = [
      {
        headerName: "No",
        field: "recommendDetailNo",
        width: 100,
        rowDrag: canEdit(),
      },
      {
        headerName: tc("品目コード"),
        field: "productCode",
        width: 120,
        editable: canEdit(),
        cellClass: canEdit() ? "b-editable-cell" : "",
      },
      {
        headerName: tc("品名"),
        field: "productGroupName",
        width: 160,
      },
      {
        headerName: tc("型式"),
        field: "spec",
        width: 250,
      },
      {
        headerName: tc("レコメンド数量"),
        field: "recommendQuantity",
        width: 140,
        cellStyle: { textAlign: "right" },
        editable: canEdit(),
        cellClass: canEdit() ? "b-editable-cell" : "",
        valueFormatter: (params: ValueFormatterParams) =>
          agNumberFormatter(params, ""),
      },
      {
        headerName: tc("実施数量"),
        field: "orderQuantity",
        width: 100,
        cellStyle: { textAlign: "right" },
        valueFormatter: (params: ValueFormatterParams) =>
          agNumberFormatter(params, ""),
        hide:
          postRequest.statusClassValue !== RecommendStatusClassValue.COMPLETED,
      },
      {
        headerName: "削除",
        field: "delete",
        width: 80,
        cellRenderer: rendererDeleteButton,
        hide: !canEdit(),
      },
    ];

    //モーダルbody部レンダリング
    function modalBody() {
      return (
        <>
          <div>
            <div className="row">
              <div className="col-4" />
              <div className="col-4">
                {recommend?.recommendId &&
                  postRequest.statusClassValue !==
                    RecommendStatusClassValue.DRAFT &&
                  postRequest.statusClassValue !==
                    RecommendStatusClassValue.UNRECOMMENDED && (
                    <div className="input-group">
                      <span
                        className="b-input-group-text-light_blue py-1"
                        style={headlineStyle}
                      >
                        {tc("提案営業")}
                      </span>
                      <span className="py-1 form-control">
                        <a
                          href="#"
                          className="text-link"
                          onClick={(e) =>
                            userDetailRef.current?.show(
                              recommendSales?.userId,
                              statusBrowse,
                              true
                            )
                          }
                          data-cy="提案営業リンク"
                        >
                          {recommendSales?.userName}
                        </a>
                      </span>
                    </div>
                  )}
              </div>
              <div className="col-4">
                <div className="input-group">
                  {postRequest.statusClassValue !==
                    RecommendStatusClassValue.DRAFT &&
                    postRequest.statusClassValue !==
                      RecommendStatusClassValue.UNRECOMMENDED && (
                      <>
                        <span
                          className="b-input-group-text-light_blue py-0"
                          style={headlineStyle}
                        >
                          {tc("提案日")}:
                        </span>
                        <div className="form-control py-0">
                          <span data-cy="提案日">
                            {formatDate(
                              postRequest.recommendDateTime,
                              "YYYY/MM/DD"
                            )}
                          </span>
                        </div>
                      </>
                    )}
                </div>
                {recommend?.recommendId && (
                  <div className="input-group">
                    <>
                      <span
                        className="b-input-group-text-light_blue py-0"
                        style={headlineStyle}
                      >
                        {tc("実施状況")}:
                      </span>
                      <div className="form-control py-0">
                        <span
                          className="ms-2"
                          style={{ color: "red" }}
                          data-cy="実施状況"
                        >
                          {convertRecomendStatusName(
                            postRequest.statusClassValue
                          )}
                        </span>
                      </div>
                    </>
                  </div>
                )}
              </div>
            </div>
            <div className="row  mt-2">
              <div className="col-4">
                <div className="input-group">
                  <span className="b-input-group-text" style={headlineStyle}>
                    {tc("会社名")}
                  </span>
                  <span className="form-control">
                    <a
                      href="#"
                      className="text-link"
                      onClick={(e) =>
                        companyDetailRef.current?.show(
                          postRequest.companyCode,
                          true
                        )
                      }
                      data-cy="会社名リンク"
                    >
                      {postRequest.companyName}
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div className="input-group">
                  <span className="b-input-group-text" style={headlineStyle}>
                    {tc("船名")}
                  </span>
                  <span className="form-control" data-cy="船名">
                    {postRequest.vesselName}
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div className="input-group">
                  <span className="b-input-group-text" style={headlineStyle}>
                    {tc("提案技師")}
                  </span>
                  <span className="form-control">
                    <a
                      href="#"
                      className="text-link"
                      onClick={(e) =>
                        userDetailRef.current?.show(
                          postRequest.recommendUserId,
                          statusBrowse,
                          true
                        )
                      }
                      data-cy="提案技師リンク"
                    >
                      {postRequest.recommendUserName}
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <div className="input-group">
                  <span className="b-input-group-text" style={headlineStyle}>
                    {tc("件名")}
                    {canEdit() && <RequireMark />}
                  </span>
                  {canEdit() ? (
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      size={50}
                      value={postRequest.title}
                      onChange={handleChangeInput}
                      data-cy="件名テキスト"
                    />
                  ) : (
                    <span className="form-control" data-cy="件名">
                      {postRequest.title}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <div className="input-group">
                  <span className="b-input-group-text" style={headlineStyle}>
                    {tc("提案理由")}
                    {canEdit() && <RequireMark />}
                  </span>
                  {canEdit() ? (
                    <input
                      type="text"
                      name="recommendComment"
                      className="form-control"
                      size={200}
                      onChange={handleChangeInput}
                      value={postRequest.recommendComment}
                      data-cy="提案理由テキスト"
                    />
                  ) : (
                    <span className="form-control" data-cy="提案理由">
                      {postRequest.recommendComment}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <div className="input-group">
                  <span className="b-input-group-text" style={headlineStyle}>
                    {tc("詳細内容")}
                  </span>
                  {canEdit() ? (
                    <textarea
                      name="recommendCommentDetail"
                      className="form-control textarea"
                      cols={100}
                      maxLength={2000}
                      value={postRequest.recommendCommentDetail}
                      onChange={handleChangeInput}
                      data-cy="詳細内容テキスト"
                    />
                  ) : (
                    <span
                      className="form-control"
                      style={{ whiteSpace: "pre-wrap" }}
                      data-cy="詳細内容"
                    >
                      {postRequest.recommendCommentDetail}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="input-group">
                  <span className="b-input-group-text" style={headlineStyle}>
                    {tc("レコメンド推奨期限")}
                  </span>
                  {canEdit() ? (
                    <input
                      type="date"
                      name="recommendLimitDate"
                      className="form-control"
                      value={formatDate(
                        postRequest.recommendLimitDate,
                        "YYYY-MM-DD"
                      )}
                      onChange={handleChangeInput}
                      data-cy="レコメンド推奨期限テキスト"
                    />
                  ) : (
                    <span className="form-control" data-cy="レコメンド推奨期限">
                      {formatDate(postRequest.recommendLimitDate, "YYYY/MM/DD")}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-4">
                <div className="input-group">
                  <span className="b-input-group-text" style={headlineStyle}>
                    {tc("技師派遣")}
                  </span>
                  <div
                    className="form-check form-switch form-control mb-0"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: canEdit() ? "#f5f5f5" : "white",
                    }}
                  >
                    <input
                      className="form-check-input mt-0"
                      type="checkbox"
                      name="dispatchFlg"
                      checked={postRequest.dispatchFlg}
                      disabled={!canEdit()}
                      onChange={handleChangeToggle}
                      style={{
                        marginLeft: "-2rem",
                        fontSize: 20,
                      }}
                      data-cy="技師派遣チェックボックス"
                    />
                    <span className="ms-2" data-cy="技師派遣">
                      {postRequest.dispatchFlg ? (
                        <span style={{ color: "red" }}>{tc("有")}</span>
                      ) : (
                        tc("無")
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={"ag-theme-alpine mx-auto w-100 mt-3"}
              data-cy="レコメンド明細グリッド"
            >
              <AgGridReact
                domLayout="autoHeight"
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowData={inputDatas}
                onCellValueChanged={onCellValueChanged}
                singleClickEdit
                ref={gridRef}
                rowDragManaged={true}
                onRowDragEnter={onRowDragEnter}
                onRowDragEnd={onRowDragEnd}
              />
            </div>
            {canEdit() && (
              <>
                <Button
                  type="button"
                  className="btn b-btn-primary m-2"
                  onClick={onClickSearchButton}
                  data-cy="部品検索ボタン"
                >
                  {tc("部品検索")}
                </Button>
              </>
            )}
            <div className="row py-3">
              {postRequest.statusClassValue !==
                RecommendStatusClassValue.DRAFT &&
                postRequest.statusClassValue !==
                  RecommendStatusClassValue.UNRECOMMENDED && (
                  <div className="col-4">
                    <div className="input-group">
                      <span
                        className="b-input-group-text"
                        style={headlineStyle}
                      >
                        {tc("提案金額合計(税込)")}
                      </span>
                      <span
                        className="form-control"
                        data-cy="提案金額合計(税込)"
                      >
                        {quotationTotalPrice?.toLocaleString()}
                      </span>
                    </div>
                  </div>
                )}
              {postRequest.statusClassValue ===
                RecommendStatusClassValue.COMPLETED && (
                <div className="col-4">
                  <div className="input-group">
                    <span className="b-input-group-text" style={headlineStyle}>
                      {tc("実施金額(税込)")}
                    </span>
                    <span className="form-control" data-cy="実施金額(税込)">
                      {orderTotalPrice?.toLocaleString()}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {
              <div>
                {canEdit() && (
                  <span
                    className="ms-2"
                    style={{ color: "red" }}
                    data-cy="ファイル添付注記"
                  >
                    {tc("※ファイル添付は、最大10件までです。")}
                  </span>
                )}

                <S3Uploader
                  editable={canEdit()}
                  maxCount={10}
                  ref={uploaderRef}
                  imgWidth={120}
                />
              </div>
            }

            <ProductListModal
              onDecide={handleSelectProducts}
              ref={productListModalRef}
            />
            <QuotationDetail
              ref={quotationDetailRef}
              onClickSaveButton={handleClickSaveButton}
            />
            <OrderDetail
              ref={orderDetailRef}
              onClickSaveButton={handleClickSaveButton}
            />
            <CompanyDetail ref={companyDetailRef} />
            <UserDetail ref={userDetailRef} />
          </div>
        </>
      );
    }

    //モーダルfooter部レンダリング
    function modalFooter() {
      return (
        <>
          {canEdit() && (
            <>
              <Button
                className="b-btn-light"
                onClick={onClickTmpSaveButton}
                data-cy="一時保存ボタン"
              >
                一時保存
              </Button>
              <Button
                className="b-btn-primary"
                onClick={onClickRegistButton}
                data-cy="登録ボタン"
              >
                登録
              </Button>
            </>
          )}
          {!(
            // 作成中は表示しない、未提案で技師、もしくは顧客で見積番号が未設定の場合は表示しない(見積番号有は再見積)
            (
              postRequest.statusClassValue ===
                RecommendStatusClassValue.DRAFT ||
              (postRequest.statusClassValue ===
                RecommendStatusClassValue.UNRECOMMENDED &&
                (isEngineer || (!user.bemacFlg && !postRequest.quotationNo)))
            )
          ) && (
            <>
              <Button
                className="b-btn-primary"
                onClick={onClickQuotationButton}
                data-cy="見積ボタン"
              >
                {tc("見積")}
              </Button>
            </>
          )}
          {(postRequest.statusClassValue ===
            RecommendStatusClassValue.SCHEDULED ||
            postRequest.statusClassValue ===
              RecommendStatusClassValue.COMPLETED) && (
            <>
              <Button
                className="b-btn-primary"
                onClick={onClickOrderContentButton}
                data-cy="注文内容ボタン"
              >
                {tc("注文内容")}
              </Button>
            </>
          )}
          <Button
            className="b-btn-close"
            onClick={handleClose}
            data-cy="Closeボタン"
          >
            Close
          </Button>
        </>
      );
    }

    function recommendSearch(
      recommendId: number | undefined,
      searchValues?: any,
      searchValueLabels?: any
    ) {
      if (recommendId !== undefined) {
        //レコメンド単一取得API実行
        api
          .get(`/api/v1/recommends/${recommendId}`)
          .then((response) => {
            const postRequest = generatePostRequest(
              searchValues,
              response.data,
              searchValueLabels
            );
            setRecommend(postRequest);
            setPostRequest(postRequest);

            //明細データを取得。編集可能時は行を追加
            const detailRows: DetailRowType[] =
              response.data.recommendGetResponseSub.map((it: DetailRowType) => {
                return { ...it };
              });

            if (
              response.data.statusClassValue === RecommendStatusClassValue.DRAFT
            ) {
              addNewRow(detailRows);
            }

            //見積番号があれば提案営業、提案金額合計を取得
            const quotationNo = response.data.quotationNo;
            if (quotationNo) {
              api
                .get(`/api/v1/quotations/${quotationNo}`)
                .then((response) => {
                  setQuotationTotalPrice(response.data.replyTotalPrice);
                  setRecommendSales({
                    userId: response.data.replyUserId,
                    userName: response.data.replyUserName,
                  });
                })
                .catch((error) => {
                  showDialog({ error });
                });
            }

            //注文番号があれば実施金額、実施数量を取得
            const orderNo = response.data.orderNo;
            if (orderNo) {
              api
                .get(`/api/v1/orders/${orderNo}`)
                .then((response) => {
                  const orderDetail = response.data.orderDetail;
                  for (let i = 0; i < detailRows.length; i++) {
                    for (let j = 0; j < orderDetail.length; j++) {
                      if (
                        detailRows[i].recommendDetailNo ===
                        orderDetail[j].referenceDetailNo
                      ) {
                        const cancelOrderQuantity =
                          orderDetail[j].cancelOrderQuantity ?? 0;
                        detailRows[i].orderQuantity =
                          orderDetail[j].replyQuantity! - cancelOrderQuantity;
                        break;
                      }
                    }
                  }

                  // キャンセル分の税抜き合計金額算出
                  let cancelSubTotal = 0;
                  for (let i = 0; i < orderDetail.length; i++) {
                    cancelSubTotal +=
                      (orderDetail[i].replyUnitCost ?? 0) *
                      (orderDetail[i].cancelOrderQuantity ?? 0);
                  }
                  // キャンセル分の税込合計金額算出
                  const taxPrice = calculateTax(
                    cancelSubTotal,
                    response.data.replyTax
                  );
                  const cancelTotal = cancelSubTotal + taxPrice;

                  //　キャンセル金額分を差し引いた金額を実施金額にセット
                  setOrderTotalPrice(
                    response.data.replyTotalPrice - cancelTotal
                  );
                })
                .catch((error) => {
                  if (error.response.status === 404) {
                    // 注文番号が存在するがレコードが存在しない場合、削除された注文とみなし対応しない
                  } else {
                    //対応するエラーメッセージを表示
                    showDialog({ error });
                  }
                });
            }
            setInputDatas(detailRows);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              showDialog({ id: "E073" });
            } else {
              if (getBizError(error)) {
                //対応するエラーメッセージを表示
                showDialog({ error });
              } else {
                showDialog({ id: "E026" });
              }
            }
            setRecommend(undefined);
            setShow(false);
          });
      } else {
        //新規作成時

        const postRequest = generatePostRequest(
          searchValues,
          undefined,
          searchValueLabels
        );
        setRecommend(postRequest);
        setPostRequest(postRequest);
        // 新規行を追加
        const detailRows: DetailRowType[] = [];
        addNewRow(detailRows);
        setInputDatas(detailRows);
      }
    }

    // 見積・注文詳細画面保存ボタンクリック
    function handleClickSaveButton() {
      // 再検索
      recommendSearch(
        recommend?.recommendId,
        props.searchValues,
        props.searchValueLabels
      );
      props.search();
    }

    //レンダリング
    return (
      <Modal
        fullscreen={true}
        show={show}
        onHide={handleClose}
        scrollable
        data-cy="レコメンド詳細モーダル"
      >
        <Modal.Header closeButton>
          <Modal.Title>{tc("レコメンド詳細")}</Modal.Title>
        </Modal.Header>
        <ModalBody>{modalBody()}</ModalBody>
        <Modal.Footer>{modalFooter()}</Modal.Footer>
      </Modal>
    );
  }
);

export default RecommendDetail;
