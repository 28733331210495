/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FiMail } from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";
import { useDialog } from "src/context/DialogProvider";
import { containsStoredUrls, storeUrl } from "src/util/UrlUtil";

import { useAuthUserContext } from "../../context/AuthUser";
import useApi from "../../hook/useApi";
import { TopSummaryGetResponse } from "../../model/api/response/TopSummaryGetResponse";
import { TopSummaryGetResponseSub1 } from "../../model/api/response/TopSummaryGetResponseSub1";
import { TopSummaryGetResponseSub2 } from "../../model/api/response/TopSummaryGetResponseSub2";
import { OrderStatus, QuotationStatus } from "../../util/Constant";
import { formatDate, getBizError } from "../../util/ecUtil";
import OrderDetail, { OrderDetailHandles } from "../order/OrderDetail";
import QuotationDetail, {
  QuotationDetailHandles,
} from "../order/QuotationDetail";
import ServiceNewsDetail, {
  MODE_BROWSE,
  ServiceNewsDetailHandles,
} from "../setting/ServiceNewsDetail";
import ServiceNewsListModal, {
  ServiceNewsListModalHandles,
} from "../setting/ServiceNewsListModal";
import UserDetail, {
  UserDetailHandles,
  statusPasswordChange,
} from "../setting/UserDetail";
import OwnShipList from "./OwnShipList";
import TaskList from "./TaskList";
import TopLogo from "./TopLogo";

function Home() {
  // クエリパラメータ取り出し
  const location = useLocation();
  const locationSearch = location.search;
  const query = new URLSearchParams(locationSearch);
  const queryShowServiceNewsId = query.get("show-service-news-id"); // 初期表示するサービスニュースID

  //ユーザー情報
  const authUser = useAuthUserContext().user();

  // 権限情報
  const auth = useAuthUserContext();

  // API使用宣言
  const api = useApi();

  //ダイアログ使用宣言
  const showDialog = useDialog();

  // 翻訳
  const { t: tc } = useTranslation("Home");

  const serviceNewsListModalRef = useRef<ServiceNewsListModalHandles>(null); // サービスニュース一覧
  const serviceNewsDetailRef = useRef<ServiceNewsDetailHandles>(null); // サービスニュース詳細
  const quotationDetailRef = useRef<QuotationDetailHandles>(null); //見積詳細
  const orderDetailRef = useRef<OrderDetailHandles>(null); //注文詳細
  const userDetailRef = useRef<UserDetailHandles>(null); //ユーザー詳細
  const imgRef = useRef<HTMLImageElement>(null); //ヘッダー画像

  //Top情報
  const [topSummary, setTopSummary] = useState<TopSummaryGetResponse>();

  //個船リストの表示・非表示制御
  const ownShipListView: boolean =
    authUser.tradingFlg || authUser.bemacFlg ? false : true;

  //LCMカレンダーの表示・非表示制御
  const lcmCalenderView: boolean = authUser.tradingFlg
    ? false
    : authUser.bemacFlg
    ? auth.isEngineer()
      ? false
      : true
    : true;

  //画面サイズ
  const [_, setWindowWidth] = useState<number>(0);

  //Top画面全体のstyle
  const homeStyle: React.CSSProperties = {
    backgroundColor: "#F5F5F5",
    height: "100%",
    width: `calc(100% - 268px)`,
    position: "absolute",
    top: "0px",
    left: "280px",
  };

  //contents全体のstyle
  const contentsStyle: React.CSSProperties = {
    marginLeft: "-15px",
    width: "100%",
    height: `calc(100% - ${imgRef.current?.height}px - 35px)`,
    display: "flex",
    padding: "0px",
  };

  //serviceNewsのstyle
  const serviceNewsStyle: React.CSSProperties = {
    backgroundColor: "white",
    borderRadius: "15px",
    width: "25%",
    height: "100%",
    boxShadow: "6px 10px 20px #0000000D",
  };

  //lcmのstyle
  const lcmStyle: React.CSSProperties = {
    backgroundColor: "white",
    borderRadius: "15px",
    width: "50%",
    height: "100%",
    boxShadow: "6px 10px 20px #0000000D",
  };

  //hover時のcss
  const hoverCss = css`
    background-color: #f4f5f8;
    :hover {
      background-color: #5d5d5d;
      img,
      span {
        filter: invert(1);
      }
    }
  `;

  // 初回レンダリングのみ実行
  useEffect(() => {
    // クエリパラメータでサービスニュースIDが指定されている場合の処理
    if (queryShowServiceNewsId) {
      // ブラウザバックで詳細画面を開かないために検査
      const url = location.pathname + location.search;
      if (!containsStoredUrls(url)) {
        // 検索後処理を定義
        const afterProc = (data: TopSummaryGetResponse) => {
          // 詳細モーダルを開く
          const serviceNewsId = parseInt(queryShowServiceNewsId);
          serviceNewsDetailRef.current?.show(serviceNewsId, MODE_BROWSE);
        };
        // 検索を実行
        search(afterProc);
        // ブラウザバックで詳細画面を開かないように記憶させる
        storeUrl(url);
        return;
      }
    }
    setWindowWidth(window.innerWidth);
    search();
  }, []);

  //Top情報取得API実行
  function search(afterProc?: (data: TopSummaryGetResponse) => void) {
    api
      .get<TopSummaryGetResponse>(`/api/v1/top-summary`)
      .then((response) => {
        const result: TopSummaryGetResponse = response.data;
        setTopSummary(result);
        if (!response.data.hasChangedPassword) {
          userDetailRef.current?.show(
            authUser.userId,
            statusPasswordChange,
            authUser.bemacFlg
          );
        } else if (afterProc) {
          // 後処理が指定されていたら実行
          afterProc(response.data);
        }
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E062" });
        }
      });
  }

  //serviceNewsが閉じられた際に実行
  function handleCloseServiceNews() {
    search();
  }
  //リサイズ時に実行(再レンダリングさせるため)
  window.addEventListener("resize", function () {
    setWindowWidth(window.innerWidth);
  });

  //TOPロゴやサイドメニューからユーザ詳細画面の更新を通知されるuseEffect
  useEffect(() => {
    function handleUserDetailSave() {
      //画面描画のための再検索処理実行
      search();
    }
    document.addEventListener("UserDetailSave", handleUserDetailSave);
    return () => {
      document.removeEventListener("UserDetailSave", handleUserDetailSave);
    };
  }, []);

  // MAINTENANCE LISTのcss
  const maintenanceListCss = css`
    :hover {
      .label {
        background-color: #e1e3ea;
      }
    }
  `;
  const maintenanceListStyle: React.CSSProperties = {
    backgroundColor: "#F4F5F8",
    width: "100%",
    height: "100%",
    position: "relative",
    left: "15px",
    borderRadius: "0 5px 5px 0px",
    boxSizing: "border-box",
  };

  // MAINTENANCE LISTを表示する
  const maintenanceList = (
    vesselName: string,
    dockScheduleStart: Date,
    dockScheduleEnd: Date,
    lcmStatusName: string,
    quotationNo: number,
    orderNo: number
  ) => {
    return (
      <div
        className="row mt-2"
        style={maintenanceListStyle}
        css={maintenanceListCss}
        data-cy="メンテナンスリスト情報"
      >
        <div className="col-8 align-items-center label">
          <span
            style={{
              fontWeight: "bold",
              fontSize: "80%",
            }}
          >
            {vesselName}
          </span>
          <br />
          <span
            style={{
              fontSize: "80%",
            }}
          >
            {dockSchedule(dockScheduleStart, dockScheduleEnd)}
          </span>
        </div>
        {lcmStatus(lcmStatusName, quotationNo, orderNo)}
      </div>
    );
  };

  // ドックスケジュールの表示
  function dockSchedule(dockScheduleStart: Date, dockScheduleEnd: Date) {
    if (dockScheduleStart !== null && dockScheduleEnd !== null) {
      return formatDate(dockScheduleStart) + "～" + formatDate(dockScheduleEnd);
    } else if (dockScheduleStart !== null && dockScheduleEnd === null) {
      return formatDate(dockScheduleStart) + "～";
    } else if (dockScheduleStart === null && dockScheduleEnd !== null) {
      return "～" + formatDate(dockScheduleEnd);
    } else {
      return tc("未定");
    }
  }

  //LCMステータスを表示する
  function lcmStatus(
    lcmStatusName: string,
    quotationNo: number,
    orderNo: number
  ) {
    switch (lcmStatusName) {
      case "引合中":
        return (
          <>
            <div className="col-4 m-0 p-0">
              <a
                href="#"
                className="text-link w-100 h-100 p-0 d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "#498EEA",
                  width: "70px",
                  borderRadius: "0 5px 5px 0",
                  color: "white",
                  textDecoration: "none",
                }}
                onClick={() => onClickQuotationLink(quotationNo)}
                data-cy="メンテナスリストリンク"
              >
                {tc(lcmStatusName)}
              </a>
            </div>
          </>
        );
      case "受注済":
      case "発注済手配中":
      case "発送済":
      case "工事済":
        return (
          <>
            <div className="col-4 m-0 p-0">
              <a
                href="#"
                className="text-link w-100 h-100 p-0 d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "#498EEA",
                  width: "70px",
                  borderRadius: "0 5px 5px 0",
                  color: "white",
                  textDecoration: "none",
                }}
                onClick={() => onClickOrderLink(orderNo)}
                data-cy="メンテナスリストリンク"
              >
                {tc(lcmStatusName)}
              </a>
            </div>
          </>
        );
      default:
        return (
          <>
            <Nav.Item className="col-4 m-0 p-0">
              <Nav.Link
                as={NavLink}
                to={`/lcm-calendar-list`}
                className="w-100 h-100 p-0 d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "#498EEA",
                  width: "70px",
                  borderRadius: "0 5px 5px 0",
                }}
                data-cy="メンテナスリストリンク"
              >
                {tc(lcmStatusName)}
              </Nav.Link>
            </Nav.Item>
          </>
        );
    }
  }

  //見積リンク押下時の処理
  function onClickQuotationLink(quotationNo: number) {
    // 該当の見積が存在するか確認
    // 見積番号が設定されていたらAPIから初期化する
    api
      .get(`/api/v1/quotations/${quotationNo}`)
      .then((response) => {
        quotationDetailRef.current?.show({
          quotationNo: quotationNo,
        });
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status === 404) {
          //エラーメッセージの表示
          showDialog({ id: "E077" });
        } else {
          showDialog({ error });
        }
      });
  }

  //注文番号リンク押下時の処理
  function onClickOrderLink(orderNo: number) {
    // 該当の注文が存在するか確認
    // 見積番号が設定されていたらAPIから初期化する
    api
      .get(`/api/v1/orders/${orderNo}`)
      .then((response) => {
        orderDetailRef.current?.show({ orderNo: orderNo });
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status === 404) {
          //エラーメッセージの表示
          showDialog({ id: "E078" });
        } else {
          showDialog({ error });
        }
      });
  }

  // 見積・注文詳細画面保存ボタンクリック
  function handleClickSaveButton() {
    // 再検索
    search();
  }

  // MAINTENANCE LISTのViewALLボタン
  function maintenanceListViewAll() {
    return (
      <Nav.Item css={hoverCss} style={{ borderRadius: "15px" }}>
        <Nav.Link
          as={NavLink}
          to={"/lcm-calendar-list"}
          style={{
            color: "black",
            borderRadius: "15px",
            fontSize: "13px",
          }}
          data-cy="定期点検カレンダーView Allボタン"
        >
          <span>VIEW ALL</span>
        </Nav.Link>
      </Nav.Item>
    );
  }

  return (
    <>
      {/* ロゴ・ユーザアイコン部分 */}
      <TopLogo />

      <div className="row" style={homeStyle}>
        {/* Topページヘッダーアイコン*/}
        <div
          style={{
            padding: "15px 12px 0px 8px",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/toppage-header.png`}
            alt="topPageHeader"
            style={{ width: "100%", height: "auto" }}
            ref={imgRef}
          />
        </div>
        {/* 個船リスト */}
        {ownShipListView && (
          <OwnShipList userVesselList={topSummary?.userVesselList!} />
        )}

        <div style={contentsStyle}>
          {/* Service Newsカード */}
          <div className="ms-4 me-1 my-1" style={serviceNewsStyle}>
            <div className="m-0 p-3 h-100">
              <span
                className="fs-6"
                style={{
                  fontSize: "80%",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                SERVICE NEWS
              </span>
              <div className="overflow-auto" style={{ height: "80%" }}>
                {topSummary?.serviceNewsList?.map(
                  (it: TopSummaryGetResponseSub1) => {
                    return (
                      <div
                        key={it.serviceNewsId}
                        className="my-2 pb-4"
                        style={{
                          color: "#333333",
                        }}
                      >
                        <div data-cy="サービスニュース掲載開始日">
                          {formatDate(it.startDate)}
                        </div>
                        <NavLink
                          to="#"
                          className="text-link"
                          onClick={() =>
                            serviceNewsDetailRef.current?.show(
                              it.serviceNewsId,
                              MODE_BROWSE
                            )
                          }
                          style={{
                            color: "#333333",
                            overflowWrap: "break-word",
                          }}
                          data-cy="サービスニュース件名リンク"
                        >
                          {it.title}
                          {/* 未読アイコン */}
                          {!it.readFlg && <FiMail className="ms-2" />}
                        </NavLink>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  className="btn-light"
                  style={{
                    position: "absolute",
                    bottom: "3vh",
                    borderRadius: "15px",
                  }}
                  css={hoverCss}
                  onClick={(e) => serviceNewsListModalRef.current?.show()}
                  data-cy="サービスニュースView Allボタン"
                >
                  <span>VIEW ALL</span>
                </Button>
              </div>
            </div>
          </div>

          <div
            className="mb-1"
            style={{
              width: "72%",
            }}
          >
            <TaskList summary={topSummary} height={"45%"} imoNo="" />
            <div
              className="d-flex mt-1 mb-2"
              style={{
                position: "relative",
                height: "55%",
              }}
            >
              {/*見積・注文新規作成（技師は非表示） */}
              {!useAuthUserContext().isEngineer() && (
                <div
                  className="p-2 py-2 ms-1"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "15px",
                    boxShadow: "6px 10px 20px #0000000D",
                    height: "100%",
                    width: "50%",
                    margin: "0 1% 0 -1%",
                  }}
                >
                  <span
                    className="fs-6"
                    style={{
                      fontSize: "80%",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                    }}
                  >
                    NEW REQUEST
                  </span>
                  <div className="row p-2">
                    <div className="col-6 px-2 mt-2">
                      <Nav.Item>
                        <Nav.Link
                          className="border-0 "
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "5px",
                            textAlign: "center",
                          }}
                          css={hoverCss}
                          as={NavLink}
                          to="/quotation-list?create=true"
                          data-cy="見積新規作成リンク"
                        >
                          <img
                            style={{ margin: "25%" }}
                            src={`${process.env.PUBLIC_URL}/top-quotation.png`}
                            width={"50%"}
                            height={"50%"}
                            alt="topQuotation"
                          />
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                    {/* BEMACユーザは非表示 */}
                    {!authUser.bemacFlg && (
                      <div className="col-6 px-2 mt-2">
                        <Nav.Item>
                          <Nav.Link
                            className="border-0 "
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                            css={hoverCss}
                            as={NavLink}
                            to="/order-list?create=true"
                            data-cy="注文新規作成リンク"
                          >
                            <img
                              style={{ margin: "25%" }}
                              src={`${process.env.PUBLIC_URL}/top-order.png`}
                              width={"30%"}
                              height={"30%"}
                              alt="topOrder"
                            />
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* LCMカード（非商社の顧客ユーザとBEMAC営業ユーザのみ表示） */}
              {lcmCalenderView && (
                <div className="py-2 p-2" style={lcmStyle}>
                  <div className="d-flex justify-content-between">
                    <span
                      className="fs-6"
                      style={{
                        fontSize: "80%",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                      data-cy="定期点検カレンダータイトル"
                    >
                      MAINTENANCE LIST
                    </span>
                    <div className="px-4 d-flex justify-content-between">
                      {maintenanceListViewAll()}
                    </div>
                  </div>
                  <div className="overflow-auto" style={{ height: "85%" }}>
                    {topSummary?.maintenanceScheduleList?.map(
                      (it: TopSummaryGetResponseSub2, index) => {
                        if (index < 3) {
                          return (
                            <div
                              style={{
                                borderRadius: "15px",
                                padding: "0% 5% 0% 0%",
                              }}
                            >
                              <div>
                                {maintenanceList(
                                  it.vesselName,
                                  it.dockScheduleStart,
                                  it.dockScheduleEnd,
                                  it.lcmStatus!,
                                  it.quotationNo,
                                  it.orderNo
                                )}
                              </div>
                            </div>
                          );
                        }
                      }
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ServiceNewsListModal
        onClosed={handleCloseServiceNews}
        ref={serviceNewsListModalRef}
      />
      <ServiceNewsDetail
        onClosed={handleCloseServiceNews}
        ref={serviceNewsDetailRef}
      />
      <QuotationDetail
        ref={quotationDetailRef}
        onClickSaveButton={handleClickSaveButton}
      />
      <OrderDetail
        ref={orderDetailRef}
        onClickSaveButton={handleClickSaveButton}
      />
      <UserDetail ref={userDetailRef} />
    </>
  );
}
export default Home;
