import { RowNode } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { ClassValuesGetResponse } from "../../model/api/response/ClassValuesGetResponse";
import { MaintenanceSchedulesGetResponse } from "../../model/api/response/MaintenanceSchedulesGetResponse";
import { VesselProductsGetResponse } from "../../model/api/response/VesselProductsGetResponse";
import { OrderStatus, QuotationStatus, TextConst } from "../../util/Constant";
import { SelectOption } from "../../util/SelectUtil";
import { formatDate, getBizError, setProperty } from "../../util/ecUtil";
import { getGridMaintenanceCostList } from "../../util/lcm/GridMaintenanceCostConverter";
import { getGridMaintenanceScheduleList } from "../../util/lcm/GridMaintenanceScheduleConverter";
import {
  getGridRecommenProductLargeClassList,
  getGridRecommenProductList,
  getGridRecommenProductMiddleClassList,
  getGridRecommenProductSumList,
} from "../../util/lcm/GridRecommendProductConverter";
import {
  ACB_MITUBISHI_COST,
  ACB_TERASAKI_COST,
  AUTO_MACHINE_COST,
  DATA_LOGGER_COST,
  DISCONTINUED_FLG,
  DISPLAY_CODE,
  DISPLAY_LARGE,
  DISPLAY_MIDDLE,
  DONE,
  DONE_SUBTOTAL,
  INSPECTION_DETAIL_BOTH,
  INSPECTION_DETAIL_INSPECTION,
  INSPECTION_DETAIL_PARTS,
  MIDDLE_CLASS_VALUE,
  PRODUCT_GOODS_CODE,
  PRODUCT_GROUP_NAME,
  QUANTITY,
  QUOTATION,
  QUOTATION_SUBTOTAL,
  RECOMMEND,
  RECOMMEND_SUBTOTAL,
  REPLACEMENT_FLG,
  SPEC,
  TIMINGS,
} from "../../util/lcm/LcmConst";
import RepairScheduleMaintenanceCost from "./RepairScheduleMaintenanceCost";
import RepairScheduleMaintenanceSchedule from "./RepairScheduleMaintenanceSchedule";
import RepairScheduleRecommendProduct from "./RepairScheduleRecommendProduct";

type Props = {
  isDefaultTab: boolean;
  companyCode: string | undefined;
  companyName: string | undefined;
  imoNo: string | undefined;
  vesselName: string | undefined;
  vesselBuilder: string | undefined;
  yardNo: string | undefined;
  maintenaceScheduleDataList: MaintenanceSchedulesGetResponse[];
  vesselProductsGetResponse: VesselProductsGetResponse[];
  search: () => void;
  searchStartFlg: boolean;
  directFlg: boolean;
  setFormChanged: (state: boolean) => void;
  yearCheckValue: number;
};
export enum RowTypes {
  InspectionCost,
  TotalWorkCost,
  PartsCost,
  InspectionDetail,
  Summary,
  Expenses,
  TotalSummary,
  QuotationOrder,
}
export type GridMaintenanceCostType = {
  rowType: RowTypes;
  rowTitle: string;
  recommend1: string | number | undefined;
  recommend2: string | number | undefined;
  recommend3: string | number | undefined;
  recommend4: string | number | undefined;
  recommend5: string | number | undefined;
  recommend6: string | number | undefined;
  recommend7: string | number | undefined;
  recommend8: string | number | undefined;
  recommend9: string | number | undefined;
  recommend10: string | number | undefined;
  recommend11: string | number | undefined;
  recommend12: string | number | undefined;
  recommend13: string | number | undefined;
  recommend14: string | number | undefined;
  recommend15: string | number | undefined;
  recommend16: string | number | undefined;
  recommend17: string | number | undefined;
  recommend18: string | number | undefined;
  recommend19: string | number | undefined;
  recommend20: string | number | undefined;
  recommend21: string | number | undefined;
  recommend22: string | number | undefined;
  recommend23: string | number | undefined;
  recommend24: string | number | undefined;
  recommend25: string | number | undefined;
  recommend26: string | number | undefined;
  recommend27: string | number | undefined;
  recommend28: string | number | undefined;
  recommend29: string | number | undefined;
  recommend30: string | number | undefined;
  done1: string | number | undefined;
  done2: string | number | undefined;
  done3: string | number | undefined;
  done4: string | number | undefined;
  done5: string | number | undefined;
  done6: string | number | undefined;
  done7: string | number | undefined;
  done8: string | number | undefined;
  done9: string | number | undefined;
  done10: string | number | undefined;
  done11: string | number | undefined;
  done12: string | number | undefined;
  done13: string | number | undefined;
  done14: string | number | undefined;
  done15: string | number | undefined;
  done16: string | number | undefined;
  done17: string | number | undefined;
  done18: string | number | undefined;
  done19: string | number | undefined;
  done20: string | number | undefined;
  done21: string | number | undefined;
  done22: string | number | undefined;
  done23: string | number | undefined;
  done24: string | number | undefined;
  done25: string | number | undefined;
  done26: string | number | undefined;
  done27: string | number | undefined;
  done28: string | number | undefined;
  done29: string | number | undefined;
  done30: string | number | undefined;
  quotation1: string | number | undefined;
  quotation2: string | number | undefined;
  quotation3: string | number | undefined;
  quotation4: string | number | undefined;
  quotation5: string | number | undefined;
  quotation6: string | number | undefined;
  quotation7: string | number | undefined;
  quotation8: string | number | undefined;
  quotation9: string | number | undefined;
  quotation10: string | number | undefined;
  quotation11: string | number | undefined;
  quotation12: string | number | undefined;
  quotation13: string | number | undefined;
  quotation14: string | number | undefined;
  quotation15: string | number | undefined;
  quotation16: string | number | undefined;
  quotation17: string | number | undefined;
  quotation18: string | number | undefined;
  quotation19: string | number | undefined;
  quotation20: string | number | undefined;
  quotation21: string | number | undefined;
  quotation22: string | number | undefined;
  quotation23: string | number | undefined;
  quotation24: string | number | undefined;
  quotation25: string | number | undefined;
  quotation26: string | number | undefined;
  quotation27: string | number | undefined;
  quotation28: string | number | undefined;
  quotation29: string | number | undefined;
  quotation30: string | number | undefined;
};

export type GridMaintenanceScheduleType = {
  timing: number;
  inspectionYear: string;
  companyCode: string;
  imoNo: string;
  inspectionYearClassValue: string;
  dockCountryClassValue: string | undefined;
  dockName: string | undefined;
  dockSchedule: string | undefined;
  dockScheduleStart: string | undefined;
  dockScheduleEnd: string | undefined;
  autoMachineFlg: boolean;
  dataLoggerFlg: boolean;
  acbMitsubishiQuantity: string | undefined;
  acbTerasakiQuantity: string | undefined;
  inspectionCost: string | undefined;
  inspectionCostSub: string | undefined;
  doneInspectionCost: string | undefined;
  quotationInspectionCost: string | undefined;
  inspectionUserId: string | undefined;
  planTariffPerHour: string | undefined;
  planTariffPerHourWeekOut: string | undefined;
  planTariffPerHourHol: string | undefined;
  planTariffPerHourHolOut: string | undefined;
  planTariffPerHourForeign: string | undefined;
  planInspectionTime: string | undefined;
  planInspectionTimeWeekOut: string | undefined;
  planInspectionTimeHol: string | undefined;
  planInspectionTimeHolOut: string | undefined;
  planInspectionTimeForeign: string | undefined;
  planTotalWorkCost: string | undefined;
  planTotalWorkCostWeekOut: string | undefined;
  planTotalWorkCostHol: string | undefined;
  planTotalWorkCostHolOut: string | undefined;
  planTotalWorkCostForeign: string | undefined;
  doneTariffPerHour: string | undefined;
  doneTariffPerHourWeekOut: string | undefined;
  doneTariffPerHourHol: string | undefined;
  doneTariffPerHourHolOut: string | undefined;
  doneTariffPerHourForeign: string | undefined;
  doneInspectionTime: string | undefined;
  doneInspectionTimeWeekOut: string | undefined;
  doneInspectionTimeHol: string | undefined;
  doneInspectionTimeHolOut: string | undefined;
  doneInspectionTimeForeign: string | undefined;
  doneTotalWorkCost: string | undefined;
  doneTotalWorkCostWeekOut: string | undefined;
  doneTotalWorkCostHol: string | undefined;
  doneTotalWorkCostHolOut: string | undefined;
  doneTotalWorkCostForeign: string | undefined;
  quotationTariffPerHour: string | undefined;
  quotationTariffPerHourWeekOut: string | undefined;
  quotationTariffPerHourHol: string | undefined;
  quotationTariffPerHourHolOut: string | undefined;
  quotationTariffPerHourForeign: string | undefined;
  quotationInspectionTime: string | undefined;
  quotationInspectionTimeWeekOut: string | undefined;
  quotationInspectionTimeHol: string | undefined;
  quotationInspectionTimeHolOut: string | undefined;
  quotationInspectionTimeForeign: string | undefined;
  quotationTotalWorkCost: string | undefined;
  quotationTotalWorkCostWeekOut: string | undefined;
  quotationTotalWorkCostHol: string | undefined;
  quotationTotalWorkCostHolOut: string | undefined;
  quotationTotalWorkCostForeign: string | undefined;
  planPartsCost: string | undefined;
  donePartsCost: string | undefined;
  quotationPartsCost: string | undefined;
  expenses: string | undefined;
  doneExpenses: string | undefined;
  quotationExpenses: string | undefined;
  inspectionDetailClassValue: string | undefined;
  quotationNo: string | undefined;
  quotationStatusClassValue: string | undefined;
  orderNo: string | undefined;
  orderStatusClassValue: string | undefined;
  quotationUserId: string | undefined;
  updateDateTime: string | undefined;
};
export type MiddleType = {
  largeName: string | undefined;
  middleName: string | undefined;
};
export type GridRecommendProductType = {
  rowId: string | undefined;
  display: string | undefined;
  largeClassName: string | undefined;
  middleClassName: string | undefined;
  discontinuedFlg: boolean | undefined;
  replacementFlg: boolean | undefined;
  productCode: string | undefined;
  productGroupName: string | undefined;
  spec: string | undefined;
  recommendQuantity: number | undefined;
  recommend1: string;
  done1: string | undefined;
  quotation1: boolean | undefined;
  recommendPrice1: number | undefined;
  recommendSubtotal1: number | undefined;
  doneSubtotal1: number | undefined;
  quotationSubtotal1: number | undefined;
  quotationStatusClassValue1: string | undefined;
  orderStatusClassValue1: string | undefined;
  recommend2: string;
  done2: string | undefined;
  quotation2: boolean | undefined;
  recommendPrice2: number | undefined;
  recommendSubtotal2: number | undefined;
  doneSubtotal2: number | undefined;
  quotationSubtotal2: number | undefined;
  quotationStatusClassValue2: string | undefined;
  orderStatusClassValue2: string | undefined;
  recommend3: string;
  done3: string | undefined;
  quotation3: boolean | undefined;
  recommendPrice3: number | undefined;
  recommendSubtotal3: number | undefined;
  doneSubtotal3: number | undefined;
  quotationSubtotal3: number | undefined;
  quotationStatusClassValue3: string | undefined;
  orderStatusClassValue3: string | undefined;
  recommend4: string;
  done4: string | undefined;
  quotation4: boolean | undefined;
  recommendPrice4: number | undefined;
  recommendSubtotal4: number | undefined;
  doneSubtotal4: number | undefined;
  quotationSubtotal4: number | undefined;
  quotationStatusClassValue4: string | undefined;
  orderStatusClassValue4: string | undefined;
  recommend5: string;
  done5: string | undefined;
  quotation5: boolean | undefined;
  recommendPrice5: number | undefined;
  recommendSubtotal5: number | undefined;
  doneSubtotal5: number | undefined;
  quotationSubtotal5: number | undefined;
  quotationStatusClassValue5: string | undefined;
  orderStatusClassValue5: string | undefined;
  recommend6: string;
  done6: string | undefined;
  quotation6: boolean | undefined;
  recommendPrice6: number | undefined;
  recommendSubtotal6: number | undefined;
  doneSubtotal6: number | undefined;
  quotationSubtotal6: number | undefined;
  quotationStatusClassValue6: string | undefined;
  orderStatusClassValue6: string | undefined;
  recommend7: string;
  done7: string | undefined;
  quotation7: boolean | undefined;
  recommendPrice7: number | undefined;
  recommendSubtotal7: number | undefined;
  doneSubtotal7: number | undefined;
  quotationSubtotal7: number | undefined;
  quotationStatusClassValue7: string | undefined;
  orderStatusClassValue7: string | undefined;
  recommend8: string;
  done8: string | undefined;
  quotation8: boolean | undefined;
  recommendPrice8: number | undefined;
  recommendSubtotal8: number | undefined;
  doneSubtotal8: number | undefined;
  quotationSubtotal8: number | undefined;
  quotationStatusClassValue8: string | undefined;
  orderStatusClassValue8: string | undefined;
  recommend9: string;
  done9: string | undefined;
  quotation9: boolean | undefined;
  recommendPrice9: number | undefined;
  recommendSubtotal9: number | undefined;
  doneSubtotal9: number | undefined;
  quotationSubtotal9: number | undefined;
  quotationStatusClassValue9: string | undefined;
  orderStatusClassValue9: string | undefined;
  recommend10: string;
  done10: string | undefined;
  quotation10: boolean | undefined;
  recommendPrice10: number | undefined;
  recommendSubtotal10: number | undefined;
  doneSubtotal10: number | undefined;
  quotationSubtotal10: number | undefined;
  quotationStatusClassValue10: string | undefined;
  orderStatusClassValue10: string | undefined;
  recommend11: string;
  done11: string | undefined;
  quotation11: boolean | undefined;
  recommendPrice11: number | undefined;
  recommendSubtotal11: number | undefined;
  doneSubtotal11: number | undefined;
  quotationSubtotal11: number | undefined;
  quotationStatusClassValue11: string | undefined;
  orderStatusClassValue11: string | undefined;
  recommend12: string;
  done12: string | undefined;
  quotation12: boolean | undefined;
  recommendPrice12: number | undefined;
  recommendSubtotal12: number | undefined;
  doneSubtotal12: number | undefined;
  quotationSubtotal12: number | undefined;
  quotationStatusClassValue12: string | undefined;
  orderStatusClassValue12: string | undefined;
  recommend13: string;
  done13: string | undefined;
  quotation13: boolean | undefined;
  recommendPrice13: number | undefined;
  recommendSubtotal13: number | undefined;
  doneSubtotal13: number | undefined;
  quotationSubtotal13: number | undefined;
  quotationStatusClassValue13: string | undefined;
  orderStatusClassValue13: string | undefined;
  recommend14: string;
  done14: string | undefined;
  quotation14: boolean | undefined;
  recommendPrice14: number | undefined;
  recommendSubtotal14: number | undefined;
  doneSubtotal14: number | undefined;
  quotationSubtotal14: number | undefined;
  quotationStatusClassValue14: string | undefined;
  orderStatusClassValue14: string | undefined;
  recommend15: string;
  done15: string | undefined;
  quotation15: boolean | undefined;
  recommendPrice15: number | undefined;
  recommendSubtotal15: number | undefined;
  doneSubtotal15: number | undefined;
  quotationSubtotal15: number | undefined;
  quotationStatusClassValue15: string | undefined;
  orderStatusClassValue15: string | undefined;
  recommend16: string;
  done16: string | undefined;
  quotation16: boolean | undefined;
  recommendPrice16: number | undefined;
  recommendSubtotal16: number | undefined;
  doneSubtotal16: number | undefined;
  quotationSubtotal16: number | undefined;
  quotationStatusClassValue16: string | undefined;
  orderStatusClassValue16: string | undefined;
  recommend17: string;
  done17: string | undefined;
  quotation17: boolean | undefined;
  recommendPrice17: number | undefined;
  recommendSubtotal17: number | undefined;
  doneSubtotal17: number | undefined;
  quotationSubtotal17: number | undefined;
  quotationStatusClassValue17: string | undefined;
  orderStatusClassValue17: string | undefined;
  recommend18: string;
  done18: string | undefined;
  quotation18: boolean | undefined;
  recommendPrice18: number | undefined;
  recommendSubtotal18: number | undefined;
  doneSubtotal18: number | undefined;
  quotationSubtotal18: number | undefined;
  quotationStatusClassValue18: string | undefined;
  orderStatusClassValue18: string | undefined;
  recommend19: string;
  done19: string | undefined;
  quotation19: boolean | undefined;
  recommendPrice19: number | undefined;
  recommendSubtotal19: number | undefined;
  doneSubtotal19: number | undefined;
  quotationSubtotal19: number | undefined;
  quotationStatusClassValue19: string | undefined;
  orderStatusClassValue19: string | undefined;
  recommend20: string;
  done20: string | undefined;
  quotation20: boolean | undefined;
  recommendPrice20: number | undefined;
  recommendSubtotal20: number | undefined;
  doneSubtotal20: number | undefined;
  quotationSubtotal20: number | undefined;
  quotationStatusClassValue20: string | undefined;
  orderStatusClassValue20: string | undefined;
  recommend21: string;
  done21: string | undefined;
  quotation21: boolean | undefined;
  recommendPrice21: number | undefined;
  recommendSubtotal21: number | undefined;
  doneSubtotal21: number | undefined;
  quotationSubtotal21: number | undefined;
  quotationStatusClassValue21: string | undefined;
  orderStatusClassValue21: string | undefined;
  recommend22: string;
  done22: string | undefined;
  quotation22: boolean | undefined;
  recommendPrice22: number | undefined;
  recommendSubtotal22: number | undefined;
  doneSubtotal22: number | undefined;
  quotationSubtotal22: number | undefined;
  quotationStatusClassValue22: string | undefined;
  orderStatusClassValue22: string | undefined;
  recommend23: string;
  done23: string | undefined;
  quotation23: boolean | undefined;
  recommendPrice23: number | undefined;
  recommendSubtotal23: number | undefined;
  doneSubtotal23: number | undefined;
  quotationSubtotal23: number | undefined;
  quotationStatusClassValue23: string | undefined;
  orderStatusClassValue23: string | undefined;
  recommend24: string;
  done24: string | undefined;
  quotation24: boolean | undefined;
  recommendPrice24: number | undefined;
  recommendSubtotal24: number | undefined;
  doneSubtotal24: number | undefined;
  quotationSubtotal24: number | undefined;
  quotationStatusClassValue24: string | undefined;
  orderStatusClassValue24: string | undefined;
  recommend25: string;
  done25: string | undefined;
  quotation25: boolean | undefined;
  recommendPrice25: number | undefined;
  recommendSubtotal25: number | undefined;
  doneSubtotal25: number | undefined;
  quotationSubtotal25: number | undefined;
  quotationStatusClassValue25: string | undefined;
  orderStatusClassValue25: string | undefined;
  recommend26: string;
  done26: string | undefined;
  quotation26: boolean | undefined;
  recommendPrice26: number | undefined;
  recommendSubtotal26: number | undefined;
  doneSubtotal26: number | undefined;
  quotationSubtotal26: number | undefined;
  quotationStatusClassValue26: string | undefined;
  orderStatusClassValue26: string | undefined;
  recommend27: string;
  done27: string | undefined;
  quotation27: boolean | undefined;
  recommendPrice27: number | undefined;
  recommendSubtotal27: number | undefined;
  doneSubtotal27: number | undefined;
  quotationSubtotal27: number | undefined;
  quotationStatusClassValue27: string | undefined;
  orderStatusClassValue27: string | undefined;
  recommend28: string;
  done28: string | undefined;
  quotation28: boolean | undefined;
  recommendPrice28: number | undefined;
  recommendSubtotal28: number | undefined;
  doneSubtotal28: number | undefined;
  quotationSubtotal28: number | undefined;
  quotationStatusClassValue28: string | undefined;
  orderStatusClassValue28: string | undefined;
  recommend29: string;
  done29: string | undefined;
  quotation29: boolean | undefined;
  recommendPrice29: number | undefined;
  recommendSubtotal29: number | undefined;
  doneSubtotal29: number | undefined;
  quotationSubtotal29: number | undefined;
  quotationStatusClassValue29: string | undefined;
  orderStatusClassValue29: string | undefined;
  recommend30: string;
  done30: string | undefined;
  quotation30: boolean | undefined;
  recommendPrice30: number | undefined;
  recommendSubtotal30: number | undefined;
  doneSubtotal30: number | undefined;
  quotationSubtotal30: number | undefined;
  quotationStatusClassValue30: string | undefined;
  orderStatusClassValue30: string | undefined;
};

/** 変更グリッド */
const CHANGE_TYPE_SCHEDULE = "schedule";
const CHANGE_TYPE_GOODS = "goods";

/** ドック国(その他) */
const DOCK_COUNTRY_OTHER = "1";
/** ドック国(日本(その他)) */
const DOCK_COUNTRY_JAPAN = "2";
/** ドック国(中国) */
const DOCK_COUNTRY_CHINA = "3";
/** ドック国(シンガポール) */
const DOCK_COUNTRY_SINGAPORE = "4";
/** ドック国(日本(瀬戸内)) */
const DOCK_COUNTRY_JAPAN_SETOUCHI = "5";
/** ドック国(日本(広島、岡山)) */
const DOCK_COUNTRY_JAPAN_HIROOKA = "6";
/** ドック国(日本(山口)) */
const DOCK_COUNTRY_JAPAN_YAMAGUCHI = "7";
/** ドック国(日本(九州・関西)) */
const DOCK_COUNTRY_JAPAN_KYUKAN = "8";

/** 日本(その他)、日本(九州・関西)、その他の一般点検基本料金 */
const DOCK_COUNTRY_COST_JAPAN = 600000;
/** 中国の一般点検基本料金 */
const DOCK_COUNTRY_COST_CHINA = 700000;
/** シンガポールの一般点検基本料金 */
const DOCK_COUNTRY_COST_SINGAPORE = 900000;
/** 日本(瀬戸内)の一般点検基本料金 */
const DOCK_COUNTRY_COST_SETOUCHI = 350000;
/** 日本(広島、岡山)の一般点検基本料金 */
const DOCK_COUNTRY_COST_HIROOKA = 400000;
/** 日本(山口)の一般点検基本料金 */
const DOCK_COUNTRY_COST_YAMAGUCHI = 500000;

function RepairSchedule(props: Props) {
  // スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();
  // ダイアログ使用宣言
  const showDialog = useDialog();
  // API使用宣言
  const api = useApi();
  // 権限情報
  const auth = useAuthUserContext();
  // メッセージ
  const { t } = useTranslation();
  // 翻訳
  const { t: tc } = useTranslation("RepairSchedule");

  // 船舶未選択メッセージ
  const SELECT_VESSEL = t("message.E067");

  // 定期点検・部品交換情報グリッド
  const gridRefMaitenanceSchedule = useRef<AgGridReact>(null);
  // 部品交換内容グリッド
  const gridRefRecommendProduct = useRef<AgGridReact>(null);
  // 点検金額グリッド
  const gridRefMaintenanceCost = useRef<AgGridReact>(null);

  // 集約表示
  const [selectedSummaryDisplay, setSelectedSummaryDisplay] = useState<string>(
    auth.user().bemacFlg ? DISPLAY_CODE : DISPLAY_LARGE
  );

  // 定期点検基本料金を打ち換えた時のの金額
  const [changeInspectionCost, setChangeInspectionCost] = useState<number>(0);

  // 最終取込日時
  const [lastImportCsvDateTIme, setLastImportCsvDateTIme] = useState<string>();

  // 竣工年
  const [completedYear, setCompletedYear] = useState<number>(0);

  // 表示年度切替
  const [yearCheckValue, setYearCheckValue] = useState<number>();

  // 定期点検・部品交換交換情報一括設定
  const [
    allSettingMaintenanceScheduleFlg,
    setAllSettingMaintenanceScheduleFlg,
  ] = useState(false);

  // ドック国コンボボックス
  const [dockCountryOptions, setDockCountryOptions] = useState<
    SelectOption<string>[]
  >([]);

  // 点検内訳コンボボックス
  const [inspectionDetailOptions, setInspectionDetailOptions] = useState<
    SelectOption<string>[]
  >([]);

  // 部品交換内容の金額表示の有無フラグ
  const [costVisible, setCostVisible] = useState<boolean>(
    auth.user().bemacFlg ? false : true
  );

  // グリッド0件時のテキスト
  const [noRowsText, setNoRowsText] = useState(SELECT_VESSEL);

  // 定期点検・部品交換情報グリッドレコード
  const [gridMaintenaceScheduleList, setGridMaintenaceScheduleList] = useState<
    GridMaintenanceScheduleType[] | null
  >([]);

  // 定期点検・部品交換情報グリッドレコード(変更チェック用)
  const [
    gridMaintenaceScheduleListInitial,
    setGridMaintenaceScheduleListInitial,
  ] = useState<GridMaintenanceScheduleType[]>([]);

  // 部品交換内容グリッドレコード
  const [gridVesselProductList, setGridVesselProductList] = useState<
    GridRecommendProductType[] | null
  >([]);

  // 大分類ごとの行レコード
  const [largeClassData, setLargClassData] = useState<
    GridRecommendProductType[]
  >([]);

  // 中分類毎の行レコード
  const [middleClassData, setMiddleClassData] = useState<
    GridRecommendProductType[]
  >([]);

  // 合計行レコード
  const [vesselProductSum, setRecommendProductSum] = useState<
    GridRecommendProductType[]
  >([]);

  // 定期点検一覧のグリッドデータ(別コンポーネントから変更の必要があるためデータをグリッドにバインドさせる )
  const [gridMaintenanceCostList, setGridMaintenanceCostList] = useState<
    GridMaintenanceCostType[] | null
  >([]);

  const [searchStartFlg, setSearchStartFlg] = useState(
    props.isDefaultTab || false
  );

  //部品交換内容エリアのスクロール復元用行番号
  const [scrollIndex, setScrollindex] = useState<number | undefined>(0);

  // 詳細画面保存ボタンクリック
  function handleClickSaveButton(buttonName: string) {
    // 再検索
    props.search();
  }

  const quotationNameMap = TIMINGS.map((timing) => {
    return {
      colId: QUOTATION + timing.toString(),
      timing: timing.toString(),
    };
  });

  /** 見積列名からtimingを取り出す */
  function getTimingFromQuotationCol(name: string | undefined) {
    return quotationNameMap.find((it) => it.colId === name)?.timing;
  }

  // 定期点検基本料金、作業費用計再計算(かなり冗長...)
  function calcCost(
    lockType: "unChange" | "addChange" | undefined = undefined,
    rowIndex: number,
    changeType: string,
    name: string | undefined = undefined,
    largeClassName: string | undefined = undefined,
    middleClassName: string | undefined = undefined
  ) {
    if (changeType === CHANGE_TYPE_SCHEDULE) {
      gridRefMaitenanceSchedule.current?.api.forEachNode((node, index) => {
        let dockCounryCost = 0;
        let autoMachineCost = 0;
        let dataLoggerCost = 0;
        let acbMitsubishiCost = 0;
        let acbTerasakiCost = 0;
        let workCost = 0;
        let workCostWeekOut = 0;
        let workCostHol = 0;
        let workCostHolOut = 0;
        let workCostForeign = 0;
        let workCostTotal = 0;
        if (allSettingMaintenanceScheduleFlg || index === rowIndex) {
          // 1.ドック国に基づく基本料金の計算
          if (!lockType) {
            if (node.data.dockCountryClassValue) {
              switch (node.data.dockCountryClassValue) {
                case DOCK_COUNTRY_OTHER:
                case DOCK_COUNTRY_JAPAN:
                case DOCK_COUNTRY_JAPAN_KYUKAN:
                  // その他、日本(その他)、日本(九州・関西)の場合
                  dockCounryCost = DOCK_COUNTRY_COST_JAPAN;
                  break;
                case DOCK_COUNTRY_CHINA:
                  // 中国の場合
                  dockCounryCost = DOCK_COUNTRY_COST_CHINA;
                  break;
                case DOCK_COUNTRY_SINGAPORE:
                  // シンガポールの場合
                  dockCounryCost = DOCK_COUNTRY_COST_SINGAPORE;
                  break;
                case DOCK_COUNTRY_JAPAN_SETOUCHI:
                  // ドック国(日本(瀬戸内))の場合
                  dockCounryCost = DOCK_COUNTRY_COST_SETOUCHI;
                  break;
                case DOCK_COUNTRY_JAPAN_HIROOKA:
                  // ドック国(日本(広島、岡山))の場合
                  dockCounryCost = DOCK_COUNTRY_COST_HIROOKA;
                  break;
                case DOCK_COUNTRY_JAPAN_YAMAGUCHI:
                  // ドック国(日本(山口))の場合
                  dockCounryCost = DOCK_COUNTRY_COST_YAMAGUCHI;
                  break;
                default:
                  break;
              }
            }
            node.setDataValue("inspectionCostSub", dockCounryCost);
          } else if (lockType === "addChange") {
            dockCounryCost = node.data.inspectionCostSub;
          } else {
            // 定期点検基本料金を設定
            dockCounryCost = Number(node.data.inspectionCost);
            node.setDataValue(
              "inspectionCostSub",
              Number(node.data.inspectionCost)
            );
          }

          // 2.自動化装置に関する料金の計算
          if (node.data.autoMachineFlg) {
            autoMachineCost = AUTO_MACHINE_COST;
          }

          // 3.ロガーに関する料金の計算
          if (node.data.dataLoggerFlg) {
            dataLoggerCost = DATA_LOGGER_COST;
          }

          // 4.ACB(三菱)に関する料金の計算
          if (node.data.acbMitsubishiQuantity) {
            acbMitsubishiCost =
              ACB_MITUBISHI_COST * Number(node.data.acbMitsubishiQuantity);
          }

          // 5.ACB(寺崎)に関する料金の計算
          if (node.data.acbTerasakiQuantity) {
            acbTerasakiCost =
              ACB_TERASAKI_COST * Number(node.data.acbTerasakiQuantity);
          }

          // 6.作業費用計に関する料金の計算
          if (node.data.planTariffPerHour && node.data.planInspectionTime) {
            workCost =
              Number(node.data.planTariffPerHour) *
              Number(node.data.planInspectionTime);
          }

          if (
            node.data.planTariffPerHourWeekOut &&
            node.data.planInspectionTimeWeekOut
          ) {
            workCostWeekOut =
              Number(node.data.planTariffPerHourWeekOut) *
              Number(node.data.planInspectionTimeWeekOut);
          }

          if (
            node.data.planTariffPerHourHol &&
            node.data.planInspectionTimeHol
          ) {
            workCostHol =
              Number(node.data.planTariffPerHourHol) *
              Number(node.data.planInspectionTimeHol);
          }

          if (
            node.data.planTariffPerHourHolOut &&
            node.data.planInspectionTimeHolOut
          ) {
            workCostHolOut =
              Number(node.data.planTariffPerHourHolOut) *
              Number(node.data.planInspectionTimeHolOut);
          }

          if (
            node.data.planTariffPerHourForeign &&
            node.data.planInspectionTimeForeign
          ) {
            workCostForeign =
              Number(node.data.planTariffPerHourForeign) *
              Number(node.data.planInspectionTimeForeign);
          }

          workCostTotal =
            workCost +
            workCostWeekOut +
            workCostHol +
            workCostHolOut +
            workCostForeign;

          // 定期点検基本料金を設定
          node.setDataValue(
            "inspectionCost",
            lockType === "unChange"
              ? dockCounryCost
              : dockCounryCost +
                  autoMachineCost +
                  dataLoggerCost +
                  acbMitsubishiCost +
                  acbTerasakiCost
          );

          // 作業費用計を設定
          node.setDataValue("planTotalWorkCost", workCost);
          node.setDataValue("planTotalWorkCostWeekOut", workCostWeekOut);
          node.setDataValue("planTotalWorkCostHol", workCostHol);
          node.setDataValue("planTotalWorkCostHolOut", workCostHolOut);
          node.setDataValue("planTotalWorkCostForeign", workCostForeign);

          let recommendPartsCost: number = 0;
          let inspectionDetailClassValue: string;

          // 点検金額側の基本料金を設定
          gridRefMaintenanceCost.current?.api.forEachNode((nodeCost, index) => {
            if (index === RowTypes.InspectionCost) {
              const cost =
                lockType === "unChange"
                  ? dockCounryCost
                  : dockCounryCost +
                    autoMachineCost +
                    dataLoggerCost +
                    acbMitsubishiCost +
                    acbTerasakiCost;
              nodeCost.setDataValue(
                RECOMMEND + node.data.timing,
                cost !== 0 ? cost : undefined
              );
            }

            if (index === RowTypes.TotalWorkCost) {
              nodeCost.setDataValue(
                RECOMMEND + node.data.timing,
                workCostTotal !== 0 ? workCostTotal : undefined
              );
            }

            if (index === RowTypes.PartsCost) {
              if (RECOMMEND + node.data.timing === "recommend1") {
                recommendPartsCost = nodeCost.data.recommend1;
              } else if (RECOMMEND + node.data.timing === "recommend2") {
                recommendPartsCost = nodeCost.data.recommend2;
              } else if (RECOMMEND + node.data.timing === "recommend3") {
                recommendPartsCost = nodeCost.data.recommend3;
              } else if (RECOMMEND + node.data.timing === "recommend4") {
                recommendPartsCost = nodeCost.data.recommend4;
              } else if (RECOMMEND + node.data.timing === "recommend5") {
                recommendPartsCost = nodeCost.data.recommend5;
              } else if (RECOMMEND + node.data.timing === "recommend6") {
                recommendPartsCost = nodeCost.data.recommend6;
              } else if (RECOMMEND + node.data.timing === "recommend7") {
                recommendPartsCost = nodeCost.data.recommend7;
              } else if (RECOMMEND + node.data.timing === "recommend8") {
                recommendPartsCost = nodeCost.data.recommend8;
              } else if (RECOMMEND + node.data.timing === "recommend9") {
                recommendPartsCost = nodeCost.data.recommend9;
              } else if (RECOMMEND + node.data.timing === "recommend10") {
                recommendPartsCost = nodeCost.data.recommend10;
              } else if (RECOMMEND + node.data.timing === "recommend11") {
                recommendPartsCost = nodeCost.data.recommend11;
              } else if (RECOMMEND + node.data.timing === "recommend12") {
                recommendPartsCost = nodeCost.data.recommend12;
              } else if (RECOMMEND + node.data.timing === "recommend13") {
                recommendPartsCost = nodeCost.data.recommend13;
              } else if (RECOMMEND + node.data.timing === "recommend14") {
                recommendPartsCost = nodeCost.data.recommend14;
              } else if (RECOMMEND + node.data.timing === "recommend15") {
                recommendPartsCost = nodeCost.data.recommend15;
              } else if (RECOMMEND + node.data.timing === "recommend16") {
                recommendPartsCost = nodeCost.data.recommend16;
              } else if (RECOMMEND + node.data.timing === "recommend17") {
                recommendPartsCost = nodeCost.data.recommend17;
              } else if (RECOMMEND + node.data.timing === "recommend18") {
                recommendPartsCost = nodeCost.data.recommend18;
              } else if (RECOMMEND + node.data.timing === "recommend19") {
                recommendPartsCost = nodeCost.data.recommend19;
              } else if (RECOMMEND + node.data.timing === "recommend20") {
                recommendPartsCost = nodeCost.data.recommend20;
              } else if (RECOMMEND + node.data.timing === "recommend21") {
                recommendPartsCost = nodeCost.data.recommend21;
              } else if (RECOMMEND + node.data.timing === "recommend22") {
                recommendPartsCost = nodeCost.data.recommend22;
              } else if (RECOMMEND + node.data.timing === "recommend23") {
                recommendPartsCost = nodeCost.data.recommend23;
              } else if (RECOMMEND + node.data.timing === "recommend24") {
                recommendPartsCost = nodeCost.data.recommend24;
              } else if (RECOMMEND + node.data.timing === "recommend25") {
                recommendPartsCost = nodeCost.data.recommend25;
              } else if (RECOMMEND + node.data.timing === "recommend26") {
                recommendPartsCost = nodeCost.data.recommend26;
              } else if (RECOMMEND + node.data.timing === "recommend27") {
                recommendPartsCost = nodeCost.data.recommend27;
              } else if (RECOMMEND + node.data.timing === "recommend28") {
                recommendPartsCost = nodeCost.data.recommend28;
              } else if (RECOMMEND + node.data.timing === "recommend29") {
                recommendPartsCost = nodeCost.data.recommend29;
              } else if (RECOMMEND + node.data.timing === "recommend30") {
                recommendPartsCost = nodeCost.data.recommend30;
              }
            }

            if (index === RowTypes.InspectionDetail) {
              if (RECOMMEND + node.data.timing === "recommend1") {
                inspectionDetailClassValue = nodeCost.data.recommend1;
              } else if (RECOMMEND + node.data.timing === "recommend2") {
                inspectionDetailClassValue = nodeCost.data.recommend2;
              } else if (RECOMMEND + node.data.timing === "recommend3") {
                inspectionDetailClassValue = nodeCost.data.recommend3;
              } else if (RECOMMEND + node.data.timing === "recommend4") {
                inspectionDetailClassValue = nodeCost.data.recommend4;
              } else if (RECOMMEND + node.data.timing === "recommend5") {
                inspectionDetailClassValue = nodeCost.data.recommend5;
              } else if (RECOMMEND + node.data.timing === "recommend6") {
                inspectionDetailClassValue = nodeCost.data.recommend6;
              } else if (RECOMMEND + node.data.timing === "recommend7") {
                inspectionDetailClassValue = nodeCost.data.recommend7;
              } else if (RECOMMEND + node.data.timing === "recommend8") {
                inspectionDetailClassValue = nodeCost.data.recommend8;
              } else if (RECOMMEND + node.data.timing === "recommend9") {
                inspectionDetailClassValue = nodeCost.data.recommend9;
              } else if (RECOMMEND + node.data.timing === "recommend10") {
                inspectionDetailClassValue = nodeCost.data.recommend10;
              } else if (RECOMMEND + node.data.timing === "recommend11") {
                inspectionDetailClassValue = nodeCost.data.recommend11;
              } else if (RECOMMEND + node.data.timing === "recommend12") {
                inspectionDetailClassValue = nodeCost.data.recommend12;
              } else if (RECOMMEND + node.data.timing === "recommend13") {
                inspectionDetailClassValue = nodeCost.data.recommend13;
              } else if (RECOMMEND + node.data.timing === "recommend14") {
                inspectionDetailClassValue = nodeCost.data.recommend14;
              } else if (RECOMMEND + node.data.timing === "recommend15") {
                inspectionDetailClassValue = nodeCost.data.recommend15;
              } else if (RECOMMEND + node.data.timing === "recommend16") {
                inspectionDetailClassValue = nodeCost.data.recommend16;
              } else if (RECOMMEND + node.data.timing === "recommend17") {
                inspectionDetailClassValue = nodeCost.data.recommend17;
              } else if (RECOMMEND + node.data.timing === "recommend18") {
                inspectionDetailClassValue = nodeCost.data.recommend18;
              } else if (RECOMMEND + node.data.timing === "recommend19") {
                inspectionDetailClassValue = nodeCost.data.recommend19;
              } else if (RECOMMEND + node.data.timing === "recommend20") {
                inspectionDetailClassValue = nodeCost.data.recommend20;
              } else if (RECOMMEND + node.data.timing === "recommend21") {
                inspectionDetailClassValue = nodeCost.data.recommend21;
              } else if (RECOMMEND + node.data.timing === "recommend22") {
                inspectionDetailClassValue = nodeCost.data.recommend22;
              } else if (RECOMMEND + node.data.timing === "recommend23") {
                inspectionDetailClassValue = nodeCost.data.recommend23;
              } else if (RECOMMEND + node.data.timing === "recommend24") {
                inspectionDetailClassValue = nodeCost.data.recommend24;
              } else if (RECOMMEND + node.data.timing === "recommend25") {
                inspectionDetailClassValue = nodeCost.data.recommend25;
              } else if (RECOMMEND + node.data.timing === "recommend26") {
                inspectionDetailClassValue = nodeCost.data.recommend26;
              } else if (RECOMMEND + node.data.timing === "recommend27") {
                inspectionDetailClassValue = nodeCost.data.recommend27;
              } else if (RECOMMEND + node.data.timing === "recommend28") {
                inspectionDetailClassValue = nodeCost.data.recommend28;
              } else if (RECOMMEND + node.data.timing === "recommend29") {
                inspectionDetailClassValue = nodeCost.data.recommend29;
              } else if (RECOMMEND + node.data.timing === "recommend30") {
                inspectionDetailClassValue = nodeCost.data.recommend30;
              }
            }

            if (index === RowTypes.Summary) {
              const cost = () => {
                switch (inspectionDetailClassValue) {
                  case INSPECTION_DETAIL_BOTH:
                    return (
                      (dockCounryCost +
                        autoMachineCost +
                        dataLoggerCost +
                        acbMitsubishiCost +
                        acbTerasakiCost ?? 0) +
                      (workCostTotal ?? 0) +
                      (recommendPartsCost ?? 0)
                    );
                  case INSPECTION_DETAIL_INSPECTION:
                    return (
                      dockCounryCost +
                        autoMachineCost +
                        dataLoggerCost +
                        acbMitsubishiCost +
                        acbTerasakiCost ?? 0
                    );
                  case INSPECTION_DETAIL_PARTS:
                    return (workCostTotal ?? 0) + (recommendPartsCost ?? 0);
                  default:
                    return undefined;
                }
              };

              nodeCost.setDataValue(
                RECOMMEND + node.data.timing,
                cost() !== 0 ? cost() : undefined
              );
            }
          });

          // 見積番号=NULL、もしくはステータスが1の時、点検金額見積りの作業費用計を設定
          if (
            !node.data.quotationNo ||
            node.data.quotationStatusClassValue === QuotationStatus.REQUESTED
          ) {
            let quotationPartsCost: number = 0;

            // 点検金額側の見積作業費計を設定
            gridRefMaintenanceCost.current?.api.forEachNode(
              (nodeCost, index) => {
                if (index === RowTypes.InspectionCost) {
                  nodeCost.setDataValue(
                    QUOTATION + node.data.timing,
                    dockCounryCost +
                      autoMachineCost +
                      dataLoggerCost +
                      acbMitsubishiCost +
                      acbTerasakiCost
                  );
                }

                if (index === RowTypes.TotalWorkCost) {
                  nodeCost.setDataValue(
                    QUOTATION + node.data.timing,
                    workCostTotal
                  );
                }

                if (index === RowTypes.PartsCost) {
                  if (QUOTATION + node.data.timing === "quotation1") {
                    quotationPartsCost = nodeCost.data.quotation1;
                  } else if (QUOTATION + node.data.timing === "quotation2") {
                    quotationPartsCost = nodeCost.data.quotation2;
                  } else if (QUOTATION + node.data.timing === "quotation3") {
                    quotationPartsCost = nodeCost.data.quotation3;
                  } else if (QUOTATION + node.data.timing === "quotation4") {
                    quotationPartsCost = nodeCost.data.quotation4;
                  } else if (QUOTATION + node.data.timing === "quotation5") {
                    quotationPartsCost = nodeCost.data.quotation5;
                  } else if (QUOTATION + node.data.timing === "quotation6") {
                    quotationPartsCost = nodeCost.data.quotation6;
                  } else if (QUOTATION + node.data.timing === "quotation7") {
                    quotationPartsCost = nodeCost.data.quotation7;
                  } else if (QUOTATION + node.data.timing === "quotation8") {
                    quotationPartsCost = nodeCost.data.quotation8;
                  } else if (QUOTATION + node.data.timing === "quotation9") {
                    quotationPartsCost = nodeCost.data.quotation9;
                  } else if (QUOTATION + node.data.timing === "quotation10") {
                    quotationPartsCost = nodeCost.data.quotation10;
                  } else if (QUOTATION + node.data.timing === "quotation11") {
                    quotationPartsCost = nodeCost.data.quotation11;
                  } else if (QUOTATION + node.data.timing === "quotation12") {
                    quotationPartsCost = nodeCost.data.quotation12;
                  } else if (QUOTATION + node.data.timing === "quotation13") {
                    quotationPartsCost = nodeCost.data.quotation13;
                  } else if (QUOTATION + node.data.timing === "quotation14") {
                    quotationPartsCost = nodeCost.data.quotation14;
                  } else if (QUOTATION + node.data.timing === "quotation15") {
                    quotationPartsCost = nodeCost.data.quotation15;
                  } else if (QUOTATION + node.data.timing === "quotation16") {
                    quotationPartsCost = nodeCost.data.quotation16;
                  } else if (QUOTATION + node.data.timing === "quotation17") {
                    quotationPartsCost = nodeCost.data.quotation17;
                  } else if (QUOTATION + node.data.timing === "quotation18") {
                    quotationPartsCost = nodeCost.data.quotation18;
                  } else if (QUOTATION + node.data.timing === "quotation19") {
                    quotationPartsCost = nodeCost.data.quotation19;
                  } else if (QUOTATION + node.data.timing === "quotation20") {
                    quotationPartsCost = nodeCost.data.quotation20;
                  } else if (QUOTATION + node.data.timing === "quotation21") {
                    quotationPartsCost = nodeCost.data.quotation21;
                  } else if (QUOTATION + node.data.timing === "quotation22") {
                    quotationPartsCost = nodeCost.data.quotation22;
                  } else if (QUOTATION + node.data.timing === "quotation23") {
                    quotationPartsCost = nodeCost.data.quotation23;
                  } else if (QUOTATION + node.data.timing === "quotation24") {
                    quotationPartsCost = nodeCost.data.quotation24;
                  } else if (QUOTATION + node.data.timing === "quotation25") {
                    quotationPartsCost = nodeCost.data.quotation25;
                  } else if (QUOTATION + node.data.timing === "quotation26") {
                    quotationPartsCost = nodeCost.data.quotation26;
                  } else if (QUOTATION + node.data.timing === "quotation27") {
                    quotationPartsCost = nodeCost.data.quotation27;
                  } else if (QUOTATION + node.data.timing === "quotation28") {
                    quotationPartsCost = nodeCost.data.quotation28;
                  } else if (QUOTATION + node.data.timing === "quotation29") {
                    quotationPartsCost = nodeCost.data.quotation29;
                  } else if (QUOTATION + node.data.timing === "quotation30") {
                    quotationPartsCost = nodeCost.data.quotation30;
                  }
                }

                if (index === RowTypes.Summary) {
                  const cost = () => {
                    switch (inspectionDetailClassValue) {
                      case INSPECTION_DETAIL_BOTH:
                        return (
                          (dockCounryCost +
                            autoMachineCost +
                            dataLoggerCost +
                            acbMitsubishiCost +
                            acbTerasakiCost ?? 0) +
                          (workCostTotal ?? 0) +
                          (quotationPartsCost ?? 0)
                        );
                      case INSPECTION_DETAIL_INSPECTION:
                        return (
                          dockCounryCost +
                            autoMachineCost +
                            dataLoggerCost +
                            acbMitsubishiCost +
                            acbTerasakiCost ?? 0
                        );
                      case INSPECTION_DETAIL_PARTS:
                        return (workCostTotal ?? 0) + (quotationPartsCost ?? 0);
                      default:
                        return undefined;
                    }
                  };

                  nodeCost.setDataValue(
                    QUOTATION + node.data.timing,
                    cost() !== 0 ? cost() : undefined
                  );
                }
              }
            );
          }
        }
      });
    } else if (changeType === CHANGE_TYPE_GOODS) {
      let quotaionSubtotal = 0;
      let quotationLargeSubtotal = 0;
      let quotationMiddleSubtotal = 0;

      const setQuotationPrice = (node: RowNode<any>, no: string) => {
        const name = "quotationSubtotal" + no;
        const hasQuotation = node.data["quotation" + no];
        const recommendPrice = node.data["recommendPrice" + no];

        if (hasQuotation) {
          quotaionSubtotal += recommendPrice;

          node.setDataValue(name, recommendPrice);
          if (node.data.largeClassName === largeClassName) {
            quotationLargeSubtotal += recommendPrice;
          }

          if (
            node.data.largeClassName === largeClassName &&
            node.data.middleClassName === middleClassName
          ) {
            quotationMiddleSubtotal += recommendPrice;
          }
        } else {
          node.setDataValue(name, undefined);
        }
      };

      gridRefRecommendProduct.current?.api.forEachNode((node) => {
        if (name) {
          const timing = getTimingFromQuotationCol(name);
          if (timing) {
            setQuotationPrice(node, timing);

            // 合計行を修正
            vesselProductSum.forEach((it) => {
              const key = ("quotationSubtotal" +
                timing) as keyof GridRecommendProductType;
              setProperty(it, key, quotaionSubtotal);
            });
          }

          const resetVesselProductSum = [...vesselProductSum];

          // 大分類を修正
          largeClassData.forEach((it) => {
            if (it.largeClassName === largeClassName) {
              if (timing) {
                const key = ("quotationSubtotal" +
                  timing) as keyof GridRecommendProductType;
                setProperty(it, key, quotationLargeSubtotal);
              }
            }
          });
          const resetLargeClassData = [...largeClassData];

          // 中分類を修正
          middleClassData.forEach((it) => {
            if (
              it.largeClassName === largeClassName &&
              it.middleClassName === middleClassName
            ) {
              if (timing) {
                const key = ("quotationSubtotal" +
                  timing) as keyof GridRecommendProductType;
                setProperty(it, key, quotationMiddleSubtotal);
              }
            }
          });
          const resetMiddleClassData = [...middleClassData];

          switch (selectedSummaryDisplay) {
            case DISPLAY_LARGE:
              handleDisplayRecord(
                resetVesselProductSum,
                resetLargeClassData,
                resetMiddleClassData,
                gridVesselProductList ? [...gridVesselProductList] : null,
                costVisible,
                true
              );
              break;
            case DISPLAY_MIDDLE:
              handleDisplayRecord(
                resetVesselProductSum,
                resetLargeClassData,
                resetMiddleClassData,
                gridVesselProductList ? [...gridVesselProductList] : null,
                costVisible,
                false,
                true
              );
              break;
            default:
              handleDisplayRecord(
                resetVesselProductSum,
                resetLargeClassData,
                resetMiddleClassData,
                gridVesselProductList ? [...gridVesselProductList] : null,
                costVisible,
                false,
                false,
                true
              );
              break;
          }
        }
      });

      // 点検金額側の基本料金を設定
      let inspecntionCost = 0;
      let totalWorkCost = 0;
      let inspectionDetail: string | undefined = undefined;

      const maintenanceData = props.maintenaceScheduleDataList.find(
        (it) => it.inspectionYearClassValue === name?.replace(QUOTATION, "")
      );
      if (
        !maintenanceData ||
        (maintenanceData &&
          (!maintenanceData.quotationNo ||
            maintenanceData.quotationStatusClassValue ===
              QuotationStatus.REQUESTED))
      ) {
        gridRefMaintenanceCost.current?.api.forEachNode((nodeCost, index) => {
          const timing = getTimingFromQuotationCol(name);
          if (timing) {
            // 定期点検基本料金を取得
            if (index === RowTypes.InspectionCost) {
              inspecntionCost = nodeCost.data["quotation" + timing];
            }

            if (index === RowTypes.TotalWorkCost) {
              totalWorkCost = nodeCost.data["quotation" + timing];
            }

            if (index === RowTypes.InspectionDetail) {
              inspectionDetail = nodeCost.data["recommend" + timing];
            }
          }

          // 部品費用を更新
          if (index === RowTypes.PartsCost) {
            // 見積番号=NULL、もしくはステータスが1の時、点検金額見積りの作業費用計を設定
            if (name) {
              nodeCost.setDataValue(name, quotaionSubtotal);
            }
          }

          if (index === RowTypes.Summary) {
            if (inspectionDetail && name) {
              const summaryCost =
                inspectionDetail === INSPECTION_DETAIL_BOTH
                  ? inspecntionCost + totalWorkCost + quotaionSubtotal
                  : inspectionDetail === INSPECTION_DETAIL_INSPECTION
                  ? inspecntionCost
                  : totalWorkCost + quotaionSubtotal;

              nodeCost.setDataValue(name, summaryCost);
            }
          }
        });
      }
    }
  }

  // 定期点検・部品交換情報エリアのコンボ変更
  function handleChangeSelect(
    name: string | undefined,
    e: SingleValue<SelectOption<string | undefined>>,
    rowIndex: number,
    timing: number
  ) {
    // 選択値をセット
    const value = e?.value;
    // 定期点検基本料金のセルのロック設定
    const lockType = name !== "dockCountryClassValue" ? "addChange" : undefined;
    props.setFormChanged(true);
    gridRefMaitenanceSchedule.current?.api.forEachNode((node, index) => {
      if (
        !node.data.quotationNo ||
        node.data.quotationStatusClassValue === QuotationStatus.REQUESTED ||
        node.data.quotationStatusClassValue === QuotationStatus.DRAFT
      ) {
        if (allSettingMaintenanceScheduleFlg || index === rowIndex) {
          if (name) {
            node.setDataValue(name, value);
          }
        }

        // 点検金額グリッドの点検内訳を設定
        if (name === "inspectionDetailClassValue") {
          gridRefMaintenanceCost.current?.api.forEachNode((nodeCost, index) => {
            if (
              index === RowTypes.InspectionDetail &&
              (allSettingMaintenanceScheduleFlg || timing === node.data.timing)
            ) {
              nodeCost.setDataValue(RECOMMEND + node.data.timing, value);
            }
          });
        }
      }
    });

    if (name === "inspectionCost") {
    }

    // 定期点検基本料金、作業費用計の再計算
    calcCost(lockType, rowIndex, CHANGE_TYPE_SCHEDULE);
  }

  // 定期点検・部品交換情報エリアの入力コントロール変更
  function handleChangeInput(
    name: string | undefined,
    e: ChangeEvent<HTMLInputElement>,
    rowIndex: number
  ) {
    // 選択値をセット
    const value = e?.target.value;
    // 定期点検基本料金のセルのロック設定
    const lockType = name === "inspectionCost" ? "unChange" : "addChange";
    //文字数制限を超えるようなら値を更新しない
    if (e.target.maxLength > 0 && value.length > e.target.maxLength) {
      return;
    }
    props.setFormChanged(true);

    gridRefMaitenanceSchedule.current?.api.forEachNode((node, index) => {
      if (
        !node.data.quotationNo ||
        node.data.quotationStatusClassValue === QuotationStatus.DRAFT ||
        node.data.quotationStatusClassValue === QuotationStatus.REQUESTED
      ) {
        if (allSettingMaintenanceScheduleFlg || index === rowIndex) {
          if (name) {
            node.setDataValue(name, value);
          }
        }
      }
    });

    // 定期点検基本料金、作業費用計の再計算
    calcCost(lockType, rowIndex, CHANGE_TYPE_SCHEDULE);
  }

  function handleChangeTextArea(
    name: string | undefined,
    e: ChangeEvent<HTMLTextAreaElement>,
    rowIndex: number
  ) {
    // 選択値をセット
    const value = e?.target.value;
    props.setFormChanged(true);
    gridRefMaitenanceSchedule.current?.api.forEachNode((node, index) => {
      if (
        (!node.data.quotationNo ||
          node.data.quotationStatusClassValue === QuotationStatus.DRAFT ||
          node.data.quotationStatusClassValue === QuotationStatus.REQUESTED ||
          node.data.quotationStatusClassValue === QuotationStatus.ANSWERED ||
          node.data.quotationStatusClassValue === QuotationStatus.ACCEPTED) &&
        (!node.data.orderNo ||
          node.data.orderStatusClassValue === OrderStatus.ORDERED ||
          node.data.orderStatusClassValue === OrderStatus.ARRANGING ||
          node.data.orderStatusClassValue === OrderStatus.REQUESTED_CHANGE)
      ) {
        if (allSettingMaintenanceScheduleFlg || index === rowIndex) {
          if (name) {
            node.setDataValue(name, value);
          }
        }
      }
    });

    // 定期点検基本料金、作業費用計の再計算
    calcCost("unChange", rowIndex, CHANGE_TYPE_SCHEDULE);
  }

  // ドックスケジュールカレンダーの入力変更
  function handleChangeDate(
    name: string | undefined,
    e: ChangeEvent<HTMLInputElement>,
    rowIndex: number
  ) {
    // 選択値をセット
    const value = e?.target.value;

    props.setFormChanged(true);
    gridRefMaitenanceSchedule.current?.api.forEachNode((node, index) => {
      if (
        (!node.data.quotationNo ||
          node.data.quotationStatusClassValue === QuotationStatus.DRAFT ||
          node.data.quotationStatusClassValue === QuotationStatus.REQUESTED ||
          node.data.quotationStatusClassValue === QuotationStatus.ANSWERED ||
          node.data.quotationStatusClassValue === QuotationStatus.ACCEPTED) &&
        (!node.data.orderNo ||
          node.data.orderStatusClassValue === OrderStatus.ORDERED ||
          node.data.orderStatusClassValue === OrderStatus.ARRANGING ||
          node.data.orderStatusClassValue === OrderStatus.REQUESTED_CHANGE)
      ) {
        if (allSettingMaintenanceScheduleFlg || index === rowIndex) {
          if (name) {
            node.setDataValue(name, value);
          }
        }
      }
    });
  }

  // 定期点検・部品交換情報エリアのトグルボタンの変更
  function handleChangeToggle(
    name: string | undefined,
    e: ChangeEvent<HTMLInputElement>,
    rowIndex: number
  ) {
    // 選択値をセット
    const value = e?.target.checked;
    props.setFormChanged(true);
    gridRefMaitenanceSchedule.current?.api.forEachNode((node, index) => {
      if (
        !node.data.quotationNo ||
        node.data.quotationStatusClassValue === QuotationStatus.DRAFT ||
        node.data.quotationStatusClassValue === QuotationStatus.REQUESTED
      ) {
        if (allSettingMaintenanceScheduleFlg || index === rowIndex) {
          if (name) {
            node.setDataValue(name, value);
          }
        }
      }
    });

    // 定期点検基本料金、作業費用計の再計算
    calcCost("addChange", rowIndex, CHANGE_TYPE_SCHEDULE);
  }

  // 部品一覧のチェックボックスの変更
  function handleChangeCheckBox(
    name: string,
    e: ChangeEvent<HTMLInputElement>,
    rowIndex?: number
  ) {
    // 選択値をセット
    const value = e?.target.checked;
    props.setFormChanged(true);

    if (typeof rowIndex === "undefined") {
      // 全選択の場合
      gridRefRecommendProduct.current?.api.forEachNode((node) => {
        // 定期点検基本料金、作業費用計の再計算
        calcCost(
          "unChange",
          0, // 使用されない
          CHANGE_TYPE_GOODS,
          name,
          node.data["largeClassName"],
          node.data["middleClassName"]
        );
      });
    } else {
      // チェック対象行を特定
      const node =
        gridRefRecommendProduct.current?.api.getDisplayedRowAtIndex(rowIndex)!;
      node.setDataValue(name, value);

      //部品交換内容エリアのスクロール復元用行番号を設定
      setScrollindex(rowIndex);

      // 定期点検基本料金、作業費用計の再計算
      calcCost(
        "unChange",
        rowIndex,
        CHANGE_TYPE_GOODS,
        name,
        node.data["largeClassName"],
        node.data["middleClassName"]
      );
    }
  }

  // 金額表示切替
  function handleChangeCostDisplayToggle(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const showsDueDateBemac = e.target.checked;
    TIMINGS.forEach((it) => {
      gridRefRecommendProduct.current?.columnApi.setColumnVisible(
        RECOMMEND_SUBTOTAL + it,
        showsDueDateBemac
      );
      gridRefRecommendProduct.current?.columnApi.setColumnVisible(
        DONE_SUBTOTAL + it,
        showsDueDateBemac
      );
      gridRefRecommendProduct.current?.columnApi.setColumnVisible(
        QUOTATION_SUBTOTAL + it,
        showsDueDateBemac
      );
    });

    switch (selectedSummaryDisplay) {
      case DISPLAY_LARGE:
        handleDisplayRecord(
          vesselProductSum,
          largeClassData,
          middleClassData,
          gridVesselProductList,
          e.target.checked,
          true
        );
        break;
      case DISPLAY_MIDDLE:
        handleDisplayRecord(
          vesselProductSum,
          largeClassData,
          middleClassData,
          gridVesselProductList,
          e.target.checked,
          false,
          true
        );
        break;
      default:
        handleDisplayRecord(
          vesselProductSum,
          largeClassData,
          middleClassData,
          gridVesselProductList,
          e.target.checked,
          false,
          false,
          true
        );
        break;
    }

    setCostVisible(e.target.checked);
  }

  // 集約表示ボタンクリック
  function handleClickSummaryDisplayButton(display: string) {
    setSelectedSummaryDisplay(display);
    // 大分類・中分類クリック時は金額表示をONにする
    const newCostVisible =
      display === DISPLAY_LARGE || display === DISPLAY_MIDDLE
        ? true
        : costVisible;
    setCostVisible(newCostVisible);

    // グリッド再設定
    resetRecommendProductGrid(
      display,
      newCostVisible,
      vesselProductSum,
      largeClassData,
      middleClassData,
      gridVesselProductList
    );
  }

  // 部品交換内容グリッド再設定
  function resetRecommendProductGrid(
    paraSelectedSummaryDisplay: string,
    paramCostVisible: boolean,
    paramVesselProductSum: GridRecommendProductType[],
    paramLargeClassData: GridRecommendProductType[],
    paramMiddleClassData: GridRecommendProductType[],
    paramGridVesselProductList: GridRecommendProductType[] | null
  ) {
    let productFlg = true;
    let middleFlg = true;
    switch (paraSelectedSummaryDisplay) {
      case DISPLAY_LARGE:
        handleDisplayRecord(
          paramVesselProductSum,
          paramLargeClassData,
          paramMiddleClassData,
          paramGridVesselProductList,
          paramCostVisible,
          true
        );
        productFlg = false;
        middleFlg = false;
        break;
      case DISPLAY_MIDDLE:
        handleDisplayRecord(
          paramVesselProductSum,
          paramLargeClassData,
          paramMiddleClassData,
          paramGridVesselProductList,
          paramCostVisible,
          false,
          true
        );
        productFlg = false;
        break;
      default:
        handleDisplayRecord(
          paramVesselProductSum,
          paramLargeClassData,
          paramMiddleClassData,
          paramGridVesselProductList,
          paramCostVisible,
          false,
          false,
          true
        );
        break;
    }

    // 選択値に応じて列の表示・非表示を設定
    gridRefRecommendProduct.current?.columnApi?.setColumnVisible(
      MIDDLE_CLASS_VALUE,
      middleFlg
    );

    gridRefRecommendProduct.current?.columnApi?.setColumnVisible(
      PRODUCT_GOODS_CODE,
      productFlg
    );

    gridRefRecommendProduct.current?.columnApi?.setColumnVisible(
      PRODUCT_GROUP_NAME,
      productFlg
    );

    gridRefRecommendProduct.current?.columnApi?.setColumnVisible(
      SPEC,
      productFlg
    );

    gridRefRecommendProduct.current?.columnApi?.setColumnVisible(
      QUANTITY,
      productFlg
    );

    gridRefRecommendProduct.current?.columnApi?.setColumnVisible(
      DISCONTINUED_FLG,
      productFlg
    );

    gridRefRecommendProduct.current?.columnApi?.setColumnVisible(
      REPLACEMENT_FLG,
      productFlg
    );

    TIMINGS.forEach((it) => {
      gridRefRecommendProduct.current?.columnApi?.setColumnVisible(
        RECOMMEND + it,
        productFlg
      );
      gridRefRecommendProduct.current?.columnApi?.setColumnVisible(
        DONE + it,
        productFlg
      );
      gridRefRecommendProduct.current?.columnApi?.setColumnVisible(
        QUOTATION + it,
        productFlg
      );
    });
  }

  // 表示レコード設定
  function handleDisplayRecord(
    resetVesselProductSum: GridRecommendProductType[],
    resetLargeClassData: GridRecommendProductType[],
    resetMiddleClassData: GridRecommendProductType[],
    resetGridVesselProductList: GridRecommendProductType[] | null,
    costDisplay?: boolean,
    largeClassDisplay?: boolean,
    middleClassDisplay?: boolean,
    codeDisplay?: boolean
  ) {
    // 合計行を先頭に追加
    if (costDisplay !== undefined) {
      if (
        costDisplay &&
        resetGridVesselProductList &&
        resetGridVesselProductList.length > 0
      ) {
        gridRefRecommendProduct.current?.api?.setPinnedTopRowData(
          resetVesselProductSum
        );
      } else {
        gridRefRecommendProduct.current?.api?.setPinnedTopRowData([]);
      }
    }

    // 大分類行を追加したレコードを設定
    if (largeClassDisplay && resetGridVesselProductList) {
      gridRefRecommendProduct.current?.api?.setRowData([
        ...resetLargeClassData,
        ...resetGridVesselProductList,
      ]);
    }

    // 中分類行を追加したレコードを設定
    if (middleClassDisplay && resetGridVesselProductList) {
      gridRefRecommendProduct.current?.api?.setRowData([
        ...resetMiddleClassData,
        ...resetGridVesselProductList,
      ]);
    }

    if (codeDisplay && resetGridVesselProductList) {
      gridRefRecommendProduct.current?.api?.setRowData([
        ...resetGridVesselProductList,
      ]);
    } else {
      // Code表示以外の場合は、明細行の高さを0にして隠す
      gridRefRecommendProduct.current?.api?.forEachNode((node, index) => {
        if (!node.data.display) {
          node.setRowHeight(0);
        }
      });
    }
  }

  // 点検内容登録ボタン押下処理
  function handleClickInpectionSaveButton() {
    // 正常処理後の動作
    const saveFunc = () => {
      saveData();
    };

    // 登録確認
    showDialog({
      id: "I061",
      confirm: true,
      callback: (isOk) => {
        if (isOk) {
          saveFunc();
        }
      },
    });
  }

  // データ保存
  function saveData() {
    // 登録値を編集
    const postRequest = gridMaintenaceScheduleList?.map((row) => {
      return {
        companyCode: row.companyCode,
        imoNo: row.imoNo,
        inspectionYearClassValue: row.inspectionYearClassValue,
        dockCountryClassValue: row.dockCountryClassValue,
        dockName: row.dockName,
        dockSchedule:
          !row.dockScheduleStart && !row.dockScheduleEnd
            ? ""
            : formatDate(row.dockScheduleStart, "YYYY/MM/DD") +
              "～" +
              formatDate(row.dockScheduleEnd, "YYYY/MM/DD"),
        dockScheduleStart: row.dockScheduleStart,
        dockScheduleEnd: row.dockScheduleEnd,
        autoMachineFlg: row.autoMachineFlg,
        dataLoggerFlg: row.dataLoggerFlg,
        acbMitsubishiQuantity: row.acbMitsubishiQuantity,
        acbTerasakiQuantity: row.acbTerasakiQuantity,
        inspectionCost: row.inspectionCost,
        doneInspectionCost: row.doneInspectionCost,
        quotationInspectionCost: row.quotationInspectionCost,
        inspectionUserId: row.inspectionUserId,
        planTariffPerHour: row.planTariffPerHour,
        planInspectionTime: row.planInspectionTime,
        planTotalWorkCost: row.planTotalWorkCost,
        planTariffPerHourWeekOut: row.planTariffPerHourWeekOut,
        planInspectionTimeWeekOut: row.planInspectionTimeWeekOut,
        planTotalWorkCostWeekOut: row.planTotalWorkCostWeekOut,
        planTariffPerHourHol: row.planTariffPerHourHol,
        planInspectionTimeHol: row.planInspectionTimeHol,
        planTotalWorkCostHol: row.planTotalWorkCostHol,
        planTariffPerHourHolOut: row.planTariffPerHourHolOut,
        planInspectionTimeHolOut: row.planInspectionTimeHolOut,
        planTotalWorkCostHolOut: row.planTotalWorkCostHolOut,
        planTariffPerHourForeign: row.planTariffPerHourForeign,
        planInspectionTimeForeign: row.planInspectionTimeForeign,
        planTotalWorkCostForeign: row.planTotalWorkCostForeign,
        doneTariffPerHour: row.doneTariffPerHour,
        doneInspectionTime: row.doneInspectionTime,
        doneTotalWorkCost: row.doneTotalWorkCost,
        doneTariffPerHourWeekOut: row.doneTariffPerHourWeekOut,
        doneInspectionTimeWeekOut: row.doneInspectionTimeWeekOut,
        doneTotalWorkCostWeekOut: row.doneTotalWorkCostWeekOut,
        doneTariffPerHourHol: row.doneTariffPerHourHol,
        doneInspectionTimeHol: row.doneInspectionTimeHol,
        doneTotalWorkCostHol: row.doneTotalWorkCostHol,
        doneTariffPerHourHolOut: row.doneTariffPerHourHolOut,
        doneInspectionTimeHolOut: row.doneInspectionTimeHolOut,
        doneTotalWorkCostHolOut: row.doneTotalWorkCostHolOut,
        doneTariffPerHourForeign: row.doneTariffPerHourForeign,
        doneInspectionTimeForeign: row.doneInspectionTimeForeign,
        doneTotalWorkCostForeign: row.doneTotalWorkCostForeign,
        quotationTariffPerHour: row.quotationTariffPerHour,
        quotationInspectionTime: row.quotationInspectionTime,
        quotationTotalWorkCost: row.quotationTotalWorkCost,
        quotationTariffPerHourWeekOut: row.quotationTariffPerHourWeekOut,
        quotationInspectionTimeWeekOut: row.quotationInspectionTimeWeekOut,
        quotationTotalWorkCostWeekOut: row.quotationTotalWorkCostWeekOut,
        quotationTariffPerHourHol: row.quotationTariffPerHourHol,
        quotationInspectionTimeHol: row.quotationInspectionTimeHol,
        quotationTotalWorkCostHol: row.quotationTotalWorkCostHol,
        quotationTariffPerHourHolOut: row.quotationTariffPerHourHolOut,
        quotationInspectionTimeHolOut: row.quotationInspectionTimeHolOut,
        quotationTotalWorkCostHolOut: row.quotationTotalWorkCostHolOut,
        quotationTariffPerHourForeign: row.quotationTariffPerHourForeign,
        quotationInspectionTimeForeign: row.quotationInspectionTimeForeign,
        quotationTotalWorkCostForeign: row.quotationTotalWorkCostForeign,
        planPartsCost: row.planPartsCost,
        donePartsCost: row.donePartsCost,
        quotationPartsCost: row.quotationPartsCost,
        expenses: row.expenses,
        doneExpenses: row.doneExpenses,
        quotationExpenses: row.quotationExpenses,
        inspecitonDetailClassValue: row.inspectionDetailClassValue,
        quotationNo: row.quotationNo,
        orderNo: row.orderNo,
        quotationUserId: row.quotationUserId,
        updateDateTime: row.updateDateTime,
      };
    });

    // メンテナンススケジュール登録API実行
    api
      .post(
        `/api/v1/vessels/${props.imoNo}/companies/${props.companyCode}/maintenance-schedules`,
        postRequest
      )
      .then((it) => {
        splashMessage.show("I035");
        props.setFormChanged(false);
        props.search();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          showDialog({ id: "E027" });
        } else {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E026" });
          }
        }
      });
  }

  // 定期点検・部品交換情報エリアの使用可否判定
  function editableMaintenanceScheduleArea() {
    if ((auth.user().bemacFlg && !auth.isEngineer()) || !auth.user().bemacFlg) {
      // BEMAC営業・顧客の場合のみ使用可
      return true;
    } else {
      return false;
    }
  }

  // 部品交換内容エリアの使用可否判定
  function editableRecommendProductsArea() {
    if (auth.user().bemacFlg && !auth.isEngineer()) {
      // BEMAC営業の場合のみ使用可
      return true;
    } else {
      return false;
    }
  }

  // 点検金額エリアの使用可否判定
  function editableInspectionCostArea() {
    if (auth.user().bemacFlg && !auth.isEngineer()) {
      // BEMAC営業の場合のみ使用可
      return true;
    } else {
      return false;
    }
  }

  // 初回レンダリングのみ実行
  useEffect(() => {
    // データ無しの初期値を設定
    setNoRowsText(SELECT_VESSEL);
    // ドック国コンボボックス
    api.get(`/api/v1/class/dock_country/values`).then((response) => {
      const result = response.data.map((row: ClassValuesGetResponse) => {
        return {
          label: row.className,
          value: row.classValue,
        };
      });

      setDockCountryOptions(result);
    });

    // 点検内訳コンボボックス
    api.get(`/api/v1/class/inspeciton_detail/values`).then((response) => {
      const result = response.data.map((row: ClassValuesGetResponse) => {
        return {
          label: row.className,
          value: row.classValue,
        };
      });

      setInspectionDetailOptions(result);
    });

    if (props.directFlg) {
      // メンテスケジュール検索処理はLCMトップ側で行うが、直接部品交換リスト画面に遷移した場合
      // メンテスケジュールのコンポーネントがレンダリングされる前にLCM検索処理が先に完了するこ
      // とがあり想定の制御にならない。(検索処理は非同期なため事象が発生したりしなかったりする)
      // そこで、直接遷移した場合はメンテスケジュールのレンダリング後に検索処理を実行する。
      props.search();
    }
  }, []);

  // 親コンポーネントからのプロパティ更新時に実行
  useEffect(() => {
    if (props.yearCheckValue !== yearCheckValue) {
      setYearCheckValue(props.yearCheckValue);
    }
    if (
      searchStartFlg !== props.searchStartFlg ||
      props.yearCheckValue !== yearCheckValue
    ) {
      setSearchStartFlg(props.searchStartFlg);
      if (props.imoNo) {
        api.get(`/api/v1/vessels/${props.imoNo}`).then((response) => {
          setCompletedYear(Number(response.data.year ?? 0));
        });
      } else {
        setCompletedYear(0);
      }
      if (props.searchStartFlg) {
        setGridVesselProductList(null);
        setGridMaintenaceScheduleList(null);
        setGridMaintenanceCostList(null);
      } else {
        const gridRecommendProductList = getGridRecommenProductList(
          props.vesselProductsGetResponse,
          props.yearCheckValue
        );
        setGridVesselProductList(gridRecommendProductList);
        setNoRowsText(props.imoNo ? t(TextConst.GRID_NO_DATA) : SELECT_VESSEL);
        const gridRecommenProductLargeClassList =
          getGridRecommenProductLargeClassList(gridRecommendProductList);
        setLargClassData(gridRecommenProductLargeClassList);

        const gridRecommenProductMiddleClassList =
          getGridRecommenProductMiddleClassList(gridRecommendProductList);
        setMiddleClassData(gridRecommenProductMiddleClassList);

        const summaryTitle = tc("合計");
        const gridRecommenProductSumList = getGridRecommenProductSumList(
          gridRecommendProductList,
          summaryTitle
        );
        setRecommendProductSum(gridRecommenProductSumList);

        // 部品交換内容グリッドの設定
        resetRecommendProductGrid(
          selectedSummaryDisplay,
          costVisible,
          gridRecommenProductSumList,
          gridRecommenProductLargeClassList,
          gridRecommenProductMiddleClassList,
          gridRecommendProductList
        );

        const gridMaintenanceData = () =>
          getGridMaintenanceScheduleList(
            gridRecommenProductSumList[0],
            props.maintenaceScheduleDataList,
            props.imoNo,
            completedYear,
            props.companyCode,
            props.yearCheckValue,
            tc("年("),
            tc("年)")
          );
        setGridMaintenaceScheduleList(gridMaintenanceData());

        // メンテナンススケジュールの初期状態を保持
        setGridMaintenaceScheduleListInitial(gridMaintenanceData());

        setGridMaintenanceCostList(
          getGridMaintenanceCostList(
            props.maintenaceScheduleDataList,
            gridRecommenProductSumList[0],
            props.imoNo,
            auth.user().bemacFlg
          )
        );

        if (
          props.vesselProductsGetResponse &&
          props.vesselProductsGetResponse.length > 0
        ) {
          let lastImportDateTime = "";
          if (props.vesselProductsGetResponse?.[0].updateDateTime) {
            let dt = new Date(
              props.vesselProductsGetResponse?.[0].updateDateTime.replace(
                "+00:00",
                ""
              ) + "Z"
            );

            lastImportDateTime =
              formatDate(dt, "YYYY/MM/DD") +
              " " +
              ("0" + dt.getHours()).slice(-2) +
              ":" +
              ("0" + dt.getMinutes()).slice(-2) +
              ":" +
              ("0" + dt.getSeconds()).slice(-2);
          }
          setLastImportCsvDateTIme(lastImportDateTime);
        }
      }
    }
  }, [props]);

  // レンダリング
  return (
    <>
      <div style={{ marginTop: "24px", marginBottom: "10px" }}>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {tc("部品交換リスト")}
        </span>
      </div>
      <div data-cy="定期点検・部品交換情報エリア">
        <RepairScheduleMaintenanceSchedule
          imoNo={props.imoNo}
          allSettingMaintenanceScheduleFlg={allSettingMaintenanceScheduleFlg}
          gridMaintenaceScheduleList={gridMaintenaceScheduleList}
          noRowsText={noRowsText}
          dockCountryOptions={dockCountryOptions}
          inspectionDetailOptions={inspectionDetailOptions}
          gridRefMaitenanceSchedule={gridRefMaitenanceSchedule}
          setAllSettingMaintenanceScheduleFlg={
            setAllSettingMaintenanceScheduleFlg
          }
          editableRecommendProductsArea={editableRecommendProductsArea}
          editableMaintenanceScheduleArea={editableMaintenanceScheduleArea}
          handleClickInpectionSaveButton={handleClickInpectionSaveButton}
          handleChangeSelect={handleChangeSelect}
          handleChangeInput={handleChangeInput}
          handleChangeDate={handleChangeDate}
          handleChangeTextArea={handleChangeTextArea}
          handleChangeToggle={handleChangeToggle}
          yearCheckValue={Number(yearCheckValue)}
        />
      </div>
      <div>
        <div data-cy="部品交換内容エリア">
          <RepairScheduleRecommendProduct
            gridVesselProductList={gridVesselProductList}
            costVisible={costVisible}
            selectedSummaryDisplay={selectedSummaryDisplay}
            lastImportCsvDateTIme={lastImportCsvDateTIme}
            completedYear={completedYear}
            noRowsText={noRowsText}
            maintenaceScheduleDataList={props.maintenaceScheduleDataList}
            gridRefRecommendProduct={gridRefRecommendProduct}
            handleChangeCostDisplayToggle={handleChangeCostDisplayToggle}
            handleClickSummaryDisplayButton={handleClickSummaryDisplayButton}
            handleChangeCheckBox={handleChangeCheckBox}
            editableRecommendProductsArea={editableRecommendProductsArea}
            scrollIndex={scrollIndex}
            setScrollindex={setScrollindex}
            yearCheckValue={Number(yearCheckValue)}
          />
        </div>

        <div>
          <RepairScheduleMaintenanceCost
            completedYear={completedYear}
            gridMaintenanceCostList={gridMaintenanceCostList}
            noRowsText={noRowsText}
            inspectionDetailOptions={inspectionDetailOptions}
            maintenaceScheduleDataList={props.maintenaceScheduleDataList}
            gridMaintenaceScheduleListInitial={
              gridMaintenaceScheduleListInitial
            }
            gridMaintenaceScheduleList={gridMaintenaceScheduleList}
            gridVesselProductList={gridVesselProductList}
            companyCode={props.companyCode}
            companyName={props.companyName}
            imoNo={props.imoNo}
            vesselName={props.vesselName}
            vesselBuilder={props.vesselBuilder}
            yardNo={props.yardNo}
            gridRefMaintenanceCost={gridRefMaintenanceCost}
            editableInspectionCostArea={editableInspectionCostArea}
            editableRecommendProductsArea={editableRecommendProductsArea}
            handleClickSaveButton={handleClickSaveButton}
            yearCheckValue={Number(yearCheckValue)}
          />
        </div>
      </div>
    </>
  );
}

export default RepairSchedule;
