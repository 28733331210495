import {
  ICellRendererParams,
  IsRowSelectable,
  RowNode,
  ValueGetterParams,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Select, { SelectInstance, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { VesselGetResponse } from "src/model/api/response/VesselGetResponse";
import { containsStoredUrls, storeUrl } from "src/util/UrlUtil";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { CompanySuggestionsGetResponse } from "../../model/api/response/CompanySuggestionsGetResponse";
import { QuotationsGetResponse } from "../../model/api/response/QuotationsGetResponse";
import { UserSuggestionsGetResponse } from "../../model/api/response/UserSuggestionsGetResponse";
import { VesselSuggestionsGetResponse } from "../../model/api/response/VesselSuggestionsGetResponse";
import { agYmdFormatter, defaultColDef } from "../../util/AgGridUtil";
import {
  QUOTATION_NO_ALL_ZERO,
  QuotationStatus,
  TextConst,
} from "../../util/Constant";
import { SelectOption, defaultSelectStyle } from "../../util/SelectUtil";
import {
  convertQuotationStatusName,
  formatQuotationNo,
  isEnglish,
} from "../../util/ecUtil";
import PageSizeSelector from "../common/PageSizeSelector";
import SearchArea from "../common/SearchArea";
import QuotationDetail, { QuotationDetailHandles } from "./QuotationDetail";

/** 見積一覧コンポーネント */
function QuotationList() {
  // クエリパラメータ取り出し
  const location = useLocation();
  const locationSearch = location.search;
  const query = new URLSearchParams(locationSearch);
  const queryStatus = query.get("status"); // ステータス
  const queryImono = query.get("imono") ?? undefined; // IMO番号
  const queryCreate = query.get("create") ?? ""; // 新規作成モード
  const queryShow = query.get("show") ?? ""; // 詳細表示モード
  const queryQuotationNo = query.get("quotation-no") ?? ""; // 見積番号

  // 参照
  const quotationUserRef = useRef<SelectInstance<number>>(null); // 見積依頼者コンボ
  const replyUserRef = useRef<SelectInstance<number>>(null); // BEMAC担当者コンボ
  const gridRef = useRef<AgGridReact<QuotationsGetResponse>>(null); // 明細
  const quotationDetailRef = useRef<QuotationDetailHandles>(null); // 見積詳細

  // ダイアログ使用宣言
  const showDialog = useDialog();
  // API使用宣言
  const api = useApi();
  // ユーザ情報
  const auth = useAuthUserContext();
  // メッセージ
  const { t } = useTranslation();
  // 翻訳
  const { t: tc } = useTranslation("QuotationList");

  // state
  // 明細0件時のテキスト
  const noRowsTextTrans = t(TextConst.GRID_INITIAL);
  const [noRowsText, setNoRowsText] = useState(noRowsTextTrans);
  // 検索条件アコーディオン開閉状態
  const [showConditionAccordion, setShowConditionAccordion] = useState(true);

  // ステータス
  const includesStautsInQuery = (status: string) => {
    const statusList = queryStatus?.split(",");
    return statusList?.includes(status) ?? false;
  };
  // 作成中
  const [checkedDraft, setCheckedDraft] = useState(
    includesStautsInQuery(QuotationStatus.DRAFT)
  );
  // 依頼中
  const [checkedRequested, setCheckedRequested] = useState(
    includesStautsInQuery(QuotationStatus.REQUESTED)
  );
  // 回答確認中
  const [checkedAnswered, setCheckedAnswered] = useState(
    includesStautsInQuery(QuotationStatus.ANSWERED)
  );
  // 成約
  const [checkedAccepted, setCheckedAccepted] = useState(
    includesStautsInQuery(QuotationStatus.ACCEPTED)
  );
  // 取消済・否成約
  const [checkedDeleted, setCheckedDeleted] = useState(
    includesStautsInQuery(QuotationStatus.DELETED)
  );

  // 検索条件（コンボはundefinedが入る）
  type SearchValueType = {
    quotationNo: string;
    companyCode: string | undefined;
    imoNo: string | undefined;
    vesselName: string | undefined; // 検索には使用しない。画面遷移による初期値設定に使用。
    quotationDateFrom: string;
    quotationDateTo: string;
    quotationUserId: number | undefined;
    replyUserId: number | undefined;
  };
  const [searchValues, setSearchValues] = useState<SearchValueType>({
    quotationNo: formatQuotationNo(queryQuotationNo),
    companyCode: undefined,
    imoNo: queryImono,
    vesselName: undefined,
    quotationDateFrom: "",
    quotationDateTo: "",
    quotationUserId: undefined,
    replyUserId: undefined,
  });
  // 一覧データ
  const [gridData, setGridData] = useState<QuotationsGetResponse[]>([]);

  // 依頼者コンボ
  const [quotationUserOptions, setQuotationUserOptions] = useState<
    SelectOption<number>[]
  >([]);
  // BEMAC担当者コンボ
  const [replyUserOptions, setReplyUserOptions] = useState<
    SelectOption<number>[]
  >([]);
  // 船名コンボ（船名キー入力ごとにAPI通信）
  const vesselLoadOptions = (inputValue: string) => {
    const companyCode = searchValues.companyCode ?? "";
    const vesselName = encodeURIComponent(inputValue);
    return api
      .get(
        `/api/v1/vessel-suggestions?companyCode=${companyCode}&vesselName=${vesselName}`
      )
      .then((it) => {
        return it.data.map((row: VesselSuggestionsGetResponse) => {
          return {
            label: row.shipName,
            value: row.imoNo,
          };
        });
      });
  };
  // 得意先コンボ（得意名キー入力ごとにAPI通信）
  const companyOptions = (inputValue: string) => {
    const companyName = encodeURIComponent(inputValue);
    return api
      .get(
        `/api/v1/company-suggestions?companyName=${companyName}&searchDelCompanyFlg=true`
      )
      .then((it) => {
        return it.data.map((row: CompanySuggestionsGetResponse) => {
          return {
            label: row.companyName,
            value: row.companyCode,
          };
        });
      });
  };

  // ステータスリスト
  const statusList = [
    { value: QuotationStatus.DRAFT, text: tc("作成中") },
    { value: QuotationStatus.REQUESTED, text: tc("依頼中") },
    { value: QuotationStatus.ANSWERED, text: tc("回答確認中") },
    { value: QuotationStatus.ACCEPTED, text: tc("成約") },
    { value: QuotationStatus.DELETED, text: tc("取消・否成約") },
  ];

  // 依頼者コンボ再設定
  function refreshQuotationUserOptions(companyCode: string | undefined) {
    const value = companyCode ?? "";
    // 依頼者コンボ
    api
      .get(`/api/v1/user-suggestions?companyCode=${value}`)
      .then((response) => {
        const users = response.data.map((row: UserSuggestionsGetResponse) => {
          return {
            label: row.userName,
            value: row.userId,
            delFlg: row.delFlg,
          };
        });
        setQuotationUserOptions(users);
        // 見積依頼者クリア
        quotationUserRef.current?.clearValue();
      });
  }
  // BEMAC担当者コンボ再設定
  function refreshReplyUserOptions(companyCode: string | undefined) {
    const value = companyCode ?? "";
    // BEMAC担当者コンボ（会社選択時絞り込む）
    api
      .get(`/api/v1/user-suggestions?userClass=sales&companyCode=${value}`)
      .then((response) => {
        const users = response.data.map((row: UserSuggestionsGetResponse) => {
          return {
            label: row.userName,
            value: row.userId,
            delFlg: row.delFlg,
          };
        });
        setReplyUserOptions(users);
        // BEMAC担当者クリア
        replyUserRef.current?.clearValue();
      });
  }
  // 検索処理
  function search(afterProc?: (data: QuotationsGetResponse[]) => void) {
    const queryParams: string[] = [];
    const quotationNoNum = strToNum(searchValues.quotationNo);
    if (quotationNoNum) {
      queryParams.push(`quotationNo=${quotationNoNum}`);
    }
    if (searchValues.companyCode) {
      queryParams.push(`companyCode=${searchValues.companyCode}`);
    }
    if (searchValues.imoNo) {
      queryParams.push(`imoNo=${searchValues.imoNo}`);
    }
    if (searchValues.quotationDateFrom) {
      queryParams.push(`quotationDateFrom=${searchValues.quotationDateFrom}`);
    }
    if (searchValues.quotationDateTo) {
      queryParams.push(`quotationDateTo=${searchValues.quotationDateTo}`);
    }
    if (searchValues.quotationUserId) {
      queryParams.push(`quotationUserId=${searchValues.quotationUserId}`);
    }
    if (searchValues.replyUserId) {
      queryParams.push(`replyUserId=${searchValues.replyUserId}`);
    }
    const query = queryParams.join("&");
    api
      .get<QuotationsGetResponse[]>(`/api/v1/quotations?${query}`)
      .then((response) => {
        // グリッドテキスト変更
        setNoRowsText(t(TextConst.GRID_NO_DATA));
        // 検索結果をセット
        setGridData(response.data);
        // 検索条件アコーディオンを閉じる
        if (filteredData(response.data).length > 0) {
          setShowConditionAccordion(false);
        }
        // 後処理が指定されていたら実行
        if (afterProc) {
          afterProc(response.data);
        }
      });
  }
  // 明細(grid内)をステータスによってフィルタした結果を取得
  function filteredData(data: QuotationsGetResponse[]) {
    const statusAll =
      !checkedDraft &&
      !checkedRequested &&
      !checkedAnswered &&
      !checkedAccepted &&
      !checkedDeleted;
    return data.filter(
      (it) =>
        statusAll ||
        (checkedDraft && it.statusClassValue === QuotationStatus.DRAFT) ||
        // 依頼中
        (checkedRequested &&
          it.statusClassValue === QuotationStatus.REQUESTED) ||
        // 回答確認中
        (checkedAnswered && it.statusClassValue === QuotationStatus.ANSWERED) ||
        // 成約
        (checkedAccepted && it.statusClassValue === QuotationStatus.ACCEPTED) ||
        // 取消済・否成約
        (checkedDeleted &&
          (it.statusClassValue === QuotationStatus.DELETED ||
            it.statusClassValue === QuotationStatus.DENIED))
    );
  }
  // 初回レンダリングのみ実行
  useEffect(() => {
    // 見積依頼者コンボ
    refreshQuotationUserOptions("");
    // BEMAC担当者コンボ
    refreshReplyUserOptions("");

    // 詳細表示モードの場合は詳細画面を開く
    if (queryShow && queryShow === "true" && queryQuotationNo) {
      // ブラウザバックで詳細画面を開かないために検査
      const url = location.pathname + location.search;
      if (!containsStoredUrls(url)) {
        // 検索後処理を定義
        const afterProc = (data: QuotationsGetResponse[]) => {
          // 検索結果にクエリパラメータの見積番号がある場合のみ詳細モーダルを開く
          const quotationNo = parseInt(queryQuotationNo);
          if (data.find((row) => row.quotationNo === quotationNo)) {
            quotationDetailRef.current?.show({ quotationNo });
          }
        };
        // 検索を実行
        search(afterProc);
        // ブラウザバックで詳細画面を開かないように記憶させる
        storeUrl(url);
        return;
      }
    }

    //検索
    search();
    // 新規作成モードの場合は詳細画面を開く
    if (queryCreate && queryCreate === "true") {
      quotationDetailRef.current?.show({ initialData: { imoNo: queryImono } });
    }
    // IMOがクエリパラメータにあれば船名コンボの初期値を設定
    if (queryImono) {
      api
        .get<VesselGetResponse>(`/api/v1/vessels/${queryImono}`)
        .then((response) => {
          setSearchValues({
            ...searchValues,
            imoNo: response.data.imoNo,
            vesselName: response.data.vesselName,
          });
        })
        .catch((error) => {
          showDialog({ error });
        });
    }
  }, []);

  // 見積番号のIME入力中フラグ
  let imeInputFlag = false;
  // 検索エリアテキストボックス変更
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "quotationNo") {
      // 見積番号はフォーマットしてセット
      const quotationNo = formatQuotationNo(e.target.value);
      // 半角英数字のみを許容
      var reg = new RegExp(/^[0-9]*$/);
      if (reg.test(value ?? "") && !imeInputFlag) {
        setSearchValues({ ...searchValues, quotationNo });
      } else {
        setSearchValues({ ...searchValues, [name]: searchValues.quotationNo });
      }
    } else {
      // 入力値をセット
      setSearchValues({ ...searchValues, [name]: value });
    }
  }
  // 検索エリアコンボ変更
  function handleChangeSelect(
    name: string,
    e: SingleValue<SelectOption<string | number>>
  ) {
    // 選択値をセット
    const value = e?.value;
    setSearchValues((prev) => {
      return { ...prev, [name]: value };
    });

    // 会社コンボが変更されたら依頼者リスト、BEMAC担当者も取り直す
    if (name === "companyCode") {
      refreshQuotationUserOptions(value?.toString());
      refreshReplyUserOptions(value?.toString());
    }
    // 船名コンボが変更されたらラベルも保持しておく
    if (name === "imoNo") {
      const vesselName = e?.label;
      setSearchValues((prev) => {
        return { ...prev, vesselName };
      });
    }
  }

  // 検索ボタンクリック
  function handleClickSearchButton() {
    search();
  }

  // ステータスボタンクリック
  function handleClickStatusButton(status: string) {
    switch (status) {
      // 作成中
      case QuotationStatus.DRAFT:
        setCheckedDraft(!checkedDraft);
        break;
      // 依頼中
      case QuotationStatus.REQUESTED:
        setCheckedRequested(!checkedRequested);
        break;
      // 回答確認中
      case QuotationStatus.ANSWERED:
        setCheckedAnswered(!checkedAnswered);
        break;
      // 成約
      case QuotationStatus.ACCEPTED:
        setCheckedAccepted(!checkedAccepted);
        break;
      // 取消済・否成約
      case QuotationStatus.DELETED:
        setCheckedDeleted(!checkedDeleted);
        break;
    }
  }

  // defaultchecked用
  function isChecked(status: string) {
    switch (status) {
      // 作成中
      case QuotationStatus.DRAFT:
        return checkedDraft;
      // 依頼中
      case QuotationStatus.REQUESTED:
        return checkedRequested;
      // 回答確認中
      case QuotationStatus.ANSWERED:
        return checkedAnswered;
      // 成約
      case QuotationStatus.ACCEPTED:
        return checkedAccepted;
      // 取消済・否成約
      case QuotationStatus.DELETED:
        return checkedDeleted;
    }
  }

  // 見積作成ボタン
  function handleClickCreateQuotation() {
    if (queryImono) {
      api
        .get<VesselGetResponse>(`/api/v1/vessels/${queryImono}`)
        .then((response) => {
          quotationDetailRef.current?.show({
            initialData: {
              imoNo: response.data.imoNo,
              vesselName: response.data.vesselName,
              vesselBuilder: response.data.vesselBuilder,
              yardNo: response.data.yardNo,
            },
          });
        })
        .catch((error) => {
          showDialog({ error });
        });
    } else {
      quotationDetailRef.current?.show({});
    }
  }

  // 削除ボタンクリック
  function handleClickDeleteButton() {
    // 選択された行を抽出
    const quotationNoList = gridRef.current?.api
      .getSelectedRows()
      .map((row) => row.quotationNo);

    const count = quotationNoList ? quotationNoList.length : 0;

    //  1件も選択されてなければエラー
    if (count === 0) {
      showDialog({ id: "E023" });
      return;
    }

    // 確認ダイアログを表示
    showDialog({
      id: "I003",
      args: [count],
      confirm: true,
      callback(isOk) {
        if (isOk) {
          // OKが押された場合
          var request = {
            quotationNoList,
          };
          // API実行
          api.post(`/api/v1/quotations:delete`, request).then((response) => {
            showDialog({
              id: "I036",
              callback() {
                // 再検索
                search();
              },
            });
          });
        }
      },
    });
  }

  // 詳細画面保存ボタンクリック
  function handleClickSaveButton(buttonName: string) {
    // 再検索
    search();
  }

  // 見積番号列レンダラ
  const quotationNoCellRenderer = (params: ICellRendererParams) => {
    return (
      <a
        href="#"
        className="text-link"
        onClick={() =>
          quotationDetailRef.current?.show({ quotationNo: params.value })
        }
      >
        {formatQuotationNo(params.value)}
      </a>
    );
  };

  // 列ごとの定義（権限によって並びが異なるため個別に定義しておく）
  const colCheckbox = {
    headerName: "",
    colId: "select",
    width: 50,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    sortable: false,
  };
  const colStatus = {
    headerName: tc("ステータス"),
    field: "statusClassValue",
    width: 120,
    valueGetter: (params: ValueGetterParams) => {
      const text = convertQuotationStatusName(params.data.statusClassValue);
      return isEnglish() ? t("QuotationStatus." + text) : text;
    },
  };
  const colCompanyName = {
    headerName: tc("会社名"),
    field: "companyName",
    width: 180,
  };
  const colVesselName = {
    headerName: tc("船名"),
    field: "vesselName",
    width: 180,
  };
  const colQuotationNo = {
    headerName: tc("見積番号"),
    field: "quotationNo",
    width: 110,
    cellRenderer: quotationNoCellRenderer,
  };
  const colQuotationDate = {
    headerName: tc("見積依頼日"),
    field: "quotationDateTime",
    width: 130,
    valueFormatter: agYmdFormatter,
  };
  const colQuotationUserName = {
    headerName: tc("見積依頼者（氏名）"),
    field: "quotationUserName",
    width: 180,
  };
  const colReplyUserName = {
    headerName: tc("BEMAC担当者"),
    field: "replyUserName",
    width: 170,
  };
  const colQuotationClassname = {
    headerName: tc("種別"),
    field: "quotationClassName",
    width: 150,
  };
  // グリッドの列定義
  const columnDefs = () => {
    if (auth.user().bemacFlg) {
      return [
        colStatus,
        colCompanyName,
        colVesselName,
        colQuotationNo,
        colQuotationDate,
        colQuotationUserName,
        colReplyUserName,
        colQuotationClassname,
      ];
    }
    if (auth.user().suFlg) {
      return [
        colCheckbox,
        colStatus,
        colQuotationNo,
        colVesselName,
        colCompanyName,
        colQuotationDate,
        colQuotationUserName,
        colQuotationClassname,
      ];
    }
    return [
      colCheckbox,
      colStatus,
      colQuotationNo,
      colVesselName,
      colQuotationDate,
      colQuotationUserName,
      colQuotationClassname,
    ];
  };
  // 選択可能行の定義
  const isRowSelectable = useMemo<IsRowSelectable>(() => {
    return (rowNode: RowNode) => {
      const selectableStatus = [
        QuotationStatus.DRAFT,
        QuotationStatus.DENIED,
        QuotationStatus.DELETED,
      ];
      return rowNode.data
        ? selectableStatus.includes(rowNode.data.statusClassValue)
        : false;
    };
  }, []);

  function strToNum(val: string | undefined) {
    if (!val) return undefined;
    const num = Number(val);
    return num;
  }

  const colSingle = "col-4 d-flex";
  const colDouble = auth.user().bemacFlg ? "col-4 d-flex" : "col-6 d-flex";
  const colQuoUser = auth.user().bemacFlg ? "col-4 d-flex" : "col-5 d-flex";

  // 検索条件エリア
  function searchArea() {
    return (
      <div>
        <div className="row">
          <div className={colSingle}>
            <span className="col-form-label px-2 text-nowrap">
              {tc("見積番号")}
            </span>
            <input
              type="text"
              name="quotationNo"
              className="form-control border-0"
              placeholder={QUOTATION_NO_ALL_ZERO}
              value={searchValues.quotationNo}
              onChange={handleChangeInput}
              onCompositionStart={() => (imeInputFlag = true)}
              onCompositionEnd={() => (imeInputFlag = false)}
              data-cy="見積番号テキスト"
            />
          </div>
          <div className={colSingle}>
            <span className="col-form-label px-2 text-nowrap">
              {tc("船名")}
            </span>
            <span className="flex-grow-1" data-cy="船名コンボ">
              <AsyncSelect
                styles={defaultSelectStyle}
                placeholder={TextConst.COMBO_PLACEHOLDER}
                isClearable
                cacheOptions
                defaultOptions
                key={searchValues.companyCode}
                loadOptions={vesselLoadOptions}
                value={
                  searchValues.imoNo
                    ? {
                        value: searchValues.imoNo,
                        label: searchValues.vesselName,
                      }
                    : undefined
                }
                onChange={(e) => {
                  handleChangeSelect("imoNo", e);
                }}
              />
            </span>
          </div>
          {(auth.user().bemacFlg || auth.user().suFlg) && (
            <div className={colSingle}>
              <span className="col-form-label px-2 text-nowrap">
                {tc("会社名")}
              </span>
              <span className="flex-grow-1" data-cy="会社名コンボ">
                <AsyncSelect
                  styles={defaultSelectStyle}
                  placeholder={TextConst.COMBO_PLACEHOLDER}
                  isClearable
                  cacheOptions
                  defaultOptions
                  loadOptions={companyOptions}
                  onChange={(e) => {
                    handleChangeSelect("companyCode", e);
                  }}
                />
              </span>
            </div>
          )}
        </div>
        <div className="row mt-3">
          <div className={colDouble}>
            <span className="col-form-label px-2 text-nowrap">
              {tc("見積依頼日")}
            </span>
            <div className="input-group">
              <input
                type="date"
                name="quotationDateFrom"
                className="form-control border-0"
                value={searchValues.quotationDateFrom}
                onChange={handleChangeInput}
                data-cy="見積依頼日（開始）テキスト"
              />
              <span className="input-group-text border-0">～</span>
              <input
                type="date"
                name="quotationDateTo"
                className="form-control border-0"
                value={searchValues.quotationDateTo}
                onChange={handleChangeInput}
                data-cy="見積依頼日（終了）テキスト"
              />
            </div>
          </div>
          <div className={colQuoUser}>
            <span className="col-form-label px-2 text-nowrap">
              {tc("見積依頼者（氏名）")}
            </span>
            <span className="flex-grow-1" data-cy="見積依頼者（氏名）コンボ">
              <Select
                styles={defaultSelectStyle}
                placeholder={TextConst.COMBO_PLACEHOLDER}
                isClearable
                options={quotationUserOptions}
                onChange={(e) => {
                  handleChangeSelect("quotationUserId", e);
                }}
                ref={quotationUserRef}
              />
            </span>
          </div>
          {auth.user().bemacFlg && (
            <div className={colSingle}>
              <span className="col-form-label px-2 text-nowrap">
                {tc("BEMAC担当者")}
              </span>
              <span className="flex-grow-1" data-cy="BEMAC担当者コンボ">
                <Select
                  styles={defaultSelectStyle}
                  placeholder={TextConst.COMBO_PLACEHOLDER}
                  isClearable
                  options={replyUserOptions}
                  onChange={(e) => {
                    handleChangeSelect("replyUserId", e);
                  }}
                  ref={replyUserRef}
                />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  // レンダリング
  return (
    <div className="b-container-list">
      <div style={{ marginBottom: "20px" }}>
        <div className="title-area-style">
          <span>{tc("見積一覧")}</span>
        </div>
        <SearchArea
          show={showConditionAccordion}
          onChangeShow={(value) => {
            setShowConditionAccordion(value);
          }}
        >
          {searchArea()}
          <div className="text-center">
            <input
              type="button"
              className="btn b-btn-primary"
              value={tc("検索")}
              onClick={handleClickSearchButton}
              data-cy="検索ボタン"
            />
          </div>
        </SearchArea>
        <div
          className="d-flex align-items-center"
          style={{ margin: "0px 30px 10px 30px" }}
        >
          <div>{tc("ステータス")}</div>
          <div className="ms-4">
            {statusList.map((it) => {
              return (
                <span key={it.value}>
                  <input
                    id={`checkStatus${it.value}`}
                    type="checkbox"
                    className="form-check-input me-1"
                    defaultChecked={isChecked(it.value)}
                    onClick={(e) => handleClickStatusButton(it.value)}
                    data-cy={`${it.text}チェックボックス`}
                  />
                  <label htmlFor={`checkStatus${it.value}`} className="me-3">
                    {it.text}
                  </label>
                </span>
              );
            })}
          </div>
        </div>
        <div
          className="ag-theme-alpine b-ag-deleted-row b-header-row-white"
          style={{ position: "relative", margin: "10px 30px 0px 30px" }}
          data-cy="見積一覧グリッド"
        >
          <AgGridReact
            domLayout="autoHeight"
            defaultColDef={{ sortable: true, ...defaultColDef }}
            columnDefs={columnDefs()}
            rowSelection="multiple"
            suppressRowClickSelection
            pagination={true}
            paginationPageSize={20}
            rowData={filteredData(gridData)}
            overlayNoRowsTemplate={noRowsText}
            isRowSelectable={isRowSelectable}
            ref={gridRef}
          />
          <PageSizeSelector grid={gridRef.current} />
        </div>
        {!auth.isEngineer() && (
          <div className="text-center my-2">
            <input
              type="button"
              className="btn b-btn-primary"
              value={tc("見積作成")}
              onClick={handleClickCreateQuotation}
              data-cy="見積作成ボタン"
            />
            {!auth.user().bemacFlg && (
              <input
                type="button"
                className="btn btn-secondary rounded-pill ms-3"
                style={{ width: "120px" }}
                value={tc("削除")}
                onClick={handleClickDeleteButton}
                data-cy="削除ボタン"
              />
            )}
          </div>
        )}
        <QuotationDetail
          ref={quotationDetailRef}
          onClickSaveButton={handleClickSaveButton}
        />
      </div>
    </div>
  );
}

export default QuotationList;
