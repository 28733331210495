import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { containsStoredUrls, storeUrl } from "src/util/UrlUtil";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { RecommendsGetResponse } from "../../model/api/response/RecommendsGetResponse";
import { agJapanCurrencyFormatter, defaultColDef } from "../../util/AgGridUtil";
import { RecommendStatusClassValue, TextConst } from "../../util/Constant";
import { formatDate, getBizError } from "../../util/ecUtil";
import PageSizeSelector from "../common/PageSizeSelector";
import RecommendDetail, { RecommendDetailHandles } from "./RecommendDetail";

function RecommendList(props: {
  isDefaultTab: boolean;
  gridData: RecommendsGetResponse[];
  gridText?: string;
  search: () => void;
  searchValues: any;
  searchValueLabels: any;
  searchStartFlg: boolean;
}) {
  //ダイアログ使用宣言
  const showDialog = useDialog();
  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();
  //API使用宣言
  const api = useApi();
  // メッセージ
  const { t } = useTranslation();
  // 翻訳
  const { t: tc } = useTranslation("RecommendList");

  // 参照
  const gridRef = useRef<AgGridReact<RecommendsGetResponse>>(null); // グリッド
  const recomendDetailRef = useRef<RecommendDetailHandles>(null); // 見積詳細

  // クエリパラメータ
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryRecommendId = query.get("recommend-id") ?? undefined; // 初期表示するレコメンドID

  // 親要素での検索状態
  const [searchStartFlg, setSearchStartFlg] = useState(
    props.isDefaultTab || false
  );

  // レコメンドリスト0件時のテキスト
  const SELECT_VESSEL = t("message.E067");
  const [noRowsImportVesselParts, setNoRowsImportVesselParts] =
    useState(SELECT_VESSEL);

  // グリッドデータ
  const [gridData, setGridData] = useState<RecommendsGetResponse[] | null>([]);

  //Bemac技師か否か
  const isBemacEngineer = useAuthUserContext().isEngineer();

  // グリッドの列定義
  let columnDefs = [
    {
      headerName: "",
      field: "recommendId",
      hide: true,
    },
    {
      headerName: "",
      colId: "select",
      width: 50,
      checkboxSelection: isActiveCheckBox,
      headerCheckboxSelection: true,
      hide: !isBemacEngineer,
    },
    {
      headerName: tc("提案日"),
      field: "recommendDateTime",
      width: 160,
      sortable: true,
      cellRenderer: (params: any) => {
        return (
          <>
            {isDisplayQuatationInfo(params.data.statusClassName) &&
              formatDate(params.value)}
            {!isDisplayQuatationInfo(params.data.statusClassName) && ""}
          </>
        );
      },
    },
    {
      headerName: tc("件名"),
      field: "title",
      width: 300,
      cellRenderer: (params: any) => {
        return (
          <a
            href="#"
            className="text-link"
            onClick={() => {
              recomendDetailRef.current?.show(
                props.gridData[params.rowIndex].recommendId,
                props.searchValues,
                props.searchValueLabels
              );
            }}
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: tc("実施状況"),
      field: "statusClassName",
      width: 100,
      sortable: true,
    },
    {
      headerName: tc("作業予定時期"),
      field: "dispatchDate",
      width: 140,
      sortable: true,
      cellRenderer: (params: any) => {
        return (
          <>
            {isDisplayQuatationInfo(params.data.statusClassName) &&
              formatDate(params.value)}
            {!isDisplayQuatationInfo(params.data.statusClassName) && ""}
          </>
        );
      },
    },
    {
      headerName: tc("作業場所"),
      field: "dispatchPlace",
      width: 150,
      sortable: true,
      cellRenderer: (params: any) => {
        return (
          <>
            {isDisplayQuatationInfo(params.data.statusClassName) &&
              params.value}
            {!isDisplayQuatationInfo(params.data.statusClassName) && ""}
          </>
        );
      },
    },
    {
      headerName: tc("提案合計金額"),
      field: "quotationTotalPrice",
      width: 130,
      sortable: true,
      cellStyle: { textAlign: "right" },
      valueFormatter: agJapanCurrencyFormatter,
      cellRenderer: (params: any) => {
        return (
          <>
            {isDisplayQuatationInfo(params.data.statusClassName) &&
              params.value !== null &&
              params.value.toLocaleString(undefined, {
                style: "currency",
                currency: "JPY",
              })}
            {!isDisplayQuatationInfo(params.data.statusClassName) && ""}
          </>
        );
      },
    },
    {
      headerName: tc("実施金額"),
      field: "orderTotalPrice",
      width: 100,
      sortable: true,
      cellStyle: { textAlign: "right" },
      valueFormatter: agJapanCurrencyFormatter,
      cellRenderer: (params: any) => {
        return (
          <>
            {isDsiplayTotalPrice(params.data.statusClassName) &&
              params.value !== null &&
              params.value.toLocaleString(undefined, {
                style: "currency",
                currency: "JPY",
              })}
            {!isDsiplayTotalPrice(params.data.statusClassName) && ""}
          </>
        );
      },
    },
  ];

  //実施状況によってチェックボックスの活性/非活性(表示/非表示)を変更する処理
  function isActiveCheckBox(params: any): boolean {
    return (params.data.statusClassValue === RecommendStatusClassValue.DRAFT ||
      params.data.statusClassValue ===
        RecommendStatusClassValue.UNRECOMMENDED) &&
      params.data.quotationTotalPrice === null
      ? true
      : false;
  }

  //実施状況によって実施金額の表示を変更する処理
  function isDsiplayTotalPrice(statusClasValue: string) {
    return statusClasValue === RecommendStatusClassValue.COMPLETED
      ? true
      : false;
  }

  //実施状況によって提案内容の表示を変更する処理
  function isDisplayQuatationInfo(statusClasValue: string) {
    return statusClasValue !== RecommendStatusClassValue.DRAFT &&
      statusClasValue !== RecommendStatusClassValue.UNRECOMMENDED
      ? true
      : false;
  }

  //削除ボタンクリック
  function handleClickDeleteButton() {
    // 選択された行を抽出
    const recommendIdList = gridRef.current?.api
      .getSelectedRows()
      .map((row) => row.recommendId);

    const count = recommendIdList ? recommendIdList.length : 0;

    //  1件も選択されてなければエラー
    if (count === 0) {
      showDialog({ id: "E023" });
      return;
    }

    // 確認ダイアログを表示
    showDialog({
      id: "I003",
      args: [count],
      confirm: true,
      callback: (isOk) => {
        if (isOk) {
          var request = {
            recommendIdList,
          };
          // API実行
          api
            .post(`/api/v1/recommends:delete`, request)
            .then((response) => {
              splashMessage.show("I036");
              props.search();
            })
            .catch((error) => {
              if (error.response.status === 404) {
                showDialog({ id: "E027" });
              } else {
                if (getBizError(error)) {
                  //対応するエラーメッセージを表示
                  showDialog({ error });
                } else {
                  showDialog({ id: "E026" });
                }
              }
            });
        }
      },
    });
  }

  //登録ボタン押下時の処理
  function onClickRegistButton() {
    recomendDetailRef.current?.show(
      undefined,
      props.searchValues,
      props.searchValueLabels
    );
  }

  // 親コンポーネントからのプロパティ更新時に実行
  useEffect(() => {
    if (searchStartFlg !== props.searchStartFlg) {
      setSearchStartFlg(props.searchStartFlg);
      if (props.searchStartFlg) {
        setGridData(null);
      } else {
        setGridData(
          props.gridData.map((row) => {
            row.orderTotalPrice = row.orderTotalPrice - row.cancelTotalPrice;
            return row;
          })
        );
        setNoRowsImportVesselParts(
          (props.searchValues.imoNo as string | undefined)
            ? t(TextConst.GRID_NO_DATA)
            : SELECT_VESSEL
        );
      }
    }
    if (queryRecommendId) {
      // ブラウザバックで詳細画面を開かないために検査
      const url = location.pathname + location.search;
      if (!containsStoredUrls(url)) {
        // 検索結果にクエリパラメータのレコメンドIDがある場合のみ詳細モーダルを開く
        const recommendId = parseInt(queryRecommendId);
        if (props.gridData.find((it) => it.recommendId === recommendId)) {
          recomendDetailRef.current?.show(
            recommendId,
            props.searchValues,
            props.searchValueLabels
          );
        }
        // ブラウザバックで詳細画面を開かないように記憶させる
        storeUrl(url);
      }
    }
  }, [props]);

  //レンダリング
  return (
    <>
      <div style={{ marginTop: "24px", marginBottom: "10px" }}>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {tc("レコメンド")}
        </span>
      </div>
      <div
        className={"ag-theme-alpine b-ag-deleted-row b-header-row-white"}
        style={{ position: "relative" }}
        data-cy="レコメンドグリッド"
      >
        <AgGridReact
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowSelection="multiple"
          suppressRowClickSelection
          rowData={gridData}
          overlayNoRowsTemplate={noRowsImportVesselParts}
          isRowSelectable={isActiveCheckBox}
          pagination
          paginationPageSize={20}
          ref={gridRef}
        />
        <PageSizeSelector grid={gridRef.current} />
      </div>
      {isBemacEngineer &&
        props.searchValues?.companyCode &&
        props.searchValues?.imoNo && (
          <div className="text-center my-2">
            <input
              type="button"
              className="btn b-btn-primary"
              value="新規レコメンド登録"
              onClick={onClickRegistButton}
              data-cy="新規レコメンド登録ボタン"
            />
            <input
              type="button"
              className="btn b-btn-delete ms-3"
              value="削除"
              onClick={handleClickDeleteButton}
              data-cy="削除ボタン"
            />
          </div>
        )}
      <div>
        <RecommendDetail
          ref={recomendDetailRef}
          search={() => props.search()}
          searchValues={props.searchValues}
          searchValueLabels={props.searchValueLabels}
        />
      </div>
    </>
  );
}

export default RecommendList;
