import { MaintenanceSchedulesGetResponse } from "src/model/api/response/MaintenanceSchedulesGetResponse";
import { getProperty } from "src/util/ecUtil";

import {
  GridMaintenanceScheduleType,
  GridRecommendProductType,
} from "../../component/lcm/RepairSchedule";
import {
  ACB_MITUBISHI_COST,
  ACB_TERASAKI_COST,
  AUTO_MACHINE_COST,
  DATA_LOGGER_COST,
  TIMINGS,
  TIMING_MAIN,
  TIMING_SUBS,
} from "./LcmConst";

// 定期点検・部品交換情報用グリッドデータ取得
export function getGridMaintenanceScheduleList(
  recommendPriceSum: GridRecommendProductType,
  maintenaceScheduleDataList: MaintenanceSchedulesGetResponse[],
  imoNo: string | undefined,
  completedYear: number,
  companyCode: string | undefined,
  yearCheckValue: number,
  yearTitle1: string,
  yearTitle2: string
) {
  const gridMaintenanceScheduleList: GridMaintenanceScheduleType[] =
    maintenaceScheduleDataList
      .sort(
        (a, b) =>
          Number(a.inspectionYearClassValue) -
          Number(b.inspectionYearClassValue)
      )
      .map((row: MaintenanceSchedulesGetResponse) => {
        return {
          timing: Number(row.inspectionYearClassValue),
          inspectionYear:
            (yearCheckValue === 0
              ? TIMING_SUBS.includes(Number(row.inspectionYearClassValue))
                ? Number(row.inspectionYearClassValue) + 0.5
                : row.inspectionYearClassValue
              : row.inspectionYearClassValue) +
            yearTitle1 +
            (completedYear + Number(row.inspectionYearClassValue)) +
            yearTitle2,
          companyCode: row.companyCode,
          imoNo: row.imoNo,
          inspectionYearClassValue: row.inspectionYearClassValue,
          dockCountryClassValue: row.dockCountryClassValue,
          dockName: row.dockName,
          dockSchedule: row.dockSchedule,
          dockScheduleStart: row.dockScheduleStart,
          dockScheduleEnd: row.dockScheduleEnd,
          autoMachineFlg: row.autoMachineFlg,
          dataLoggerFlg: row.dataLoggerFlg,
          acbMitsubishiQuantity: row.acbMitsubishiQuantity,
          acbTerasakiQuantity: row.acbTerasakiQuantity,
          inspectionCost: row.inspectionCost,
          inspectionCostSub: !row.inspectionCost
            ? undefined
            : row.inspectionCost -
              ((row.autoMachineFlg ? AUTO_MACHINE_COST : 0) +
                (row.dataLoggerFlg ? DATA_LOGGER_COST : 0) +
                (row.acbMitsubishiQuantity
                  ? ACB_MITUBISHI_COST * Number(row.acbMitsubishiQuantity)
                  : 0) +
                (row.acbTerasakiQuantity
                  ? ACB_TERASAKI_COST * Number(row.acbTerasakiQuantity)
                  : 0)),
          doneInspectionCost: row.doneInspectionCost,
          quotationInspectionCost: row.quotationInspectionCost,
          inspectionUserId: row.inspectionUserId,
          planTariffPerHour: row.planTariffPerHour,
          planTariffPerHourWeekOut: row.planTariffPerHourWeekOut,
          planTariffPerHourHol: row.planTariffPerHourHol,
          planTariffPerHourHolOut: row.planTariffPerHourHolOut,
          planTariffPerHourForeign: row.planTariffPerHourForeign,
          planInspectionTime: row.planInspectionTime,
          planInspectionTimeWeekOut: row.planInspectionTimeWeekOut,
          planInspectionTimeHol: row.planInspectionTimeHol,
          planInspectionTimeHolOut: row.planInspectionTimeHolOut,
          planInspectionTimeForeign: row.planInspectionTimeForeign,
          planTotalWorkCost: row.planTotalWorkCost,
          planTotalWorkCostWeekOut: row.planTotalWorkCostWeekOut,
          planTotalWorkCostHol: row.planTotalWorkCostHol,
          planTotalWorkCostHolOut: row.planTotalWorkCostHolOut,
          planTotalWorkCostForeign: row.planTotalWorkCostForeign,
          doneTariffPerHour: row.doneTariffPerHour,
          doneTariffPerHourWeekOut: row.doneTariffPerHourWeekOut,
          doneTariffPerHourHol: row.doneTariffPerHourHol,
          doneTariffPerHourHolOut: row.doneTariffPerHourHolOut,
          doneTariffPerHourForeign: row.doneTariffPerHourForeign,
          doneInspectionTime: row.doneInspectionTime,
          doneInspectionTimeWeekOut: row.doneInspectionTimeWeekOut,
          doneInspectionTimeHol: row.doneInspectionTimeHol,
          doneInspectionTimeHolOut: row.doneInspectionTimeHolOut,
          doneInspectionTimeForeign: row.doneInspectionTimeForeign,
          doneTotalWorkCost: row.doneTotalWorkCost,
          doneTotalWorkCostWeekOut: row.doneTotalWorkCostWeekOut,
          doneTotalWorkCostHol: row.doneTotalWorkCostHol,
          doneTotalWorkCostHolOut: row.doneTotalWorkCostHolOut,
          doneTotalWorkCostForeign: row.doneTotalWorkCostForeign,
          quotationTariffPerHour: row.quotationTariffPerHour,
          quotationTariffPerHourWeekOut: row.quotationTariffPerHourWeekOut,
          quotationTariffPerHourHol: row.quotationTariffPerHourHol,
          quotationTariffPerHourHolOut: row.quotationTariffPerHourHolOut,
          quotationTariffPerHourForeign: row.quotationTariffPerHourForeign,
          quotationInspectionTime: row.quotationInspectionTime,
          quotationInspectionTimeWeekOut: row.quotationInspectionTimeWeekOut,
          quotationInspectionTimeHol: row.quotationInspectionTimeHol,
          quotationInspectionTimeHolOut: row.quotationInspectionTimeHolOut,
          quotationInspectionTimeForeign: row.quotationInspectionTimeForeign,
          quotationTotalWorkCost: row.quotationTotalWorkCost,
          quotationTotalWorkCostWeekOut: row.quotationTotalWorkCostWeekOut,
          quotationTotalWorkCostHol: row.quotationTotalWorkCostHol,
          quotationTotalWorkCostHolOut: row.quotationTotalWorkCostHolOut,
          quotationTotalWorkCostForeign: row.quotationTotalWorkCostForeign,
          planPartsCost: row.planPartsCost,
          donePartsCost: row.donePartsCost,
          quotationPartsCost: row.quotationPartsCost,
          expenses: row.expenses,
          doneExpenses: row.doneExpenses,
          quotationExpenses: row.quotationExpenses,
          inspectionDetailClassValue: row.inspecitonDetailClassValue,
          quotationNo: row.quotationNo,
          quotationStatusClassValue: row.quotationStatusClassValue,
          orderNo: row.orderNo,
          orderStatusClassValue: row.orderStatusClassValue,
          quotationUserId: row.quotationUserId as number | undefined,
          updateDateTime: row.updateDateTime as string | undefined,
          orderCreateUserId: row.orderCreateUserId,
        } as GridMaintenanceScheduleType;
      });

  // 不足している年度分を追加
  const tmp: GridMaintenanceScheduleType[] = [];

  // IMOが指定されている場合のみ不足している年のデータを追加
  if (imoNo) {
    (yearCheckValue === 0 ? TIMING_MAIN : TIMINGS).forEach((timing) => {
      const existData = gridMaintenanceScheduleList.find((it) => {
        return Number(it.inspectionYearClassValue) === timing;
      });
      if (existData) {
        tmp.push(existData);
      } else {
        // 年度ごとの予定部品代を算出
        const recommendPrice = () => {
          const key = ("recommendSubtotal" +
            timing) as keyof GridRecommendProductType;
          return getProperty(recommendPriceSum, key) !== 0
            ? getProperty(recommendPriceSum, key)?.toString()
            : undefined;
        };
        tmp.push({
          timing: timing,
          inspectionYear:
            (yearCheckValue === 0
              ? TIMING_SUBS.includes(timing)
                ? timing + 0.5
                : timing
              : timing) +
            yearTitle1 +
            (completedYear + timing) +
            yearTitle2,
          companyCode: companyCode ?? "",
          imoNo: imoNo ?? "",
          inspectionYearClassValue: timing.toString(),
          dockCountryClassValue: undefined,
          dockName: undefined,
          dockSchedule: undefined,
          dockScheduleStart: undefined,
          dockScheduleEnd: undefined,
          autoMachineFlg: false,
          dataLoggerFlg: false,
          acbMitsubishiQuantity: undefined,
          acbTerasakiQuantity: undefined,
          inspectionCost: undefined,
          inspectionCostSub: undefined,
          doneInspectionCost: undefined,
          quotationInspectionCost: undefined,
          inspectionUserId: undefined,
          planTariffPerHour: undefined,
          planTariffPerHourWeekOut: undefined,
          planTariffPerHourHol: undefined,
          planTariffPerHourHolOut: undefined,
          planTariffPerHourForeign: undefined,
          planInspectionTime: undefined,
          planInspectionTimeWeekOut: undefined,
          planInspectionTimeHol: undefined,
          planInspectionTimeHolOut: undefined,
          planInspectionTimeForeign: undefined,
          planTotalWorkCost: undefined,
          planTotalWorkCostWeekOut: undefined,
          planTotalWorkCostHol: undefined,
          planTotalWorkCostHolOut: undefined,
          planTotalWorkCostForeign: undefined,
          doneTariffPerHour: undefined,
          doneTariffPerHourWeekOut: undefined,
          doneTariffPerHourHol: undefined,
          doneTariffPerHourHolOut: undefined,
          doneTariffPerHourForeign: undefined,
          doneInspectionTime: undefined,
          doneInspectionTimeWeekOut: undefined,
          doneInspectionTimeHol: undefined,
          doneInspectionTimeHolOut: undefined,
          doneInspectionTimeForeign: undefined,
          doneTotalWorkCost: undefined,
          doneTotalWorkCostWeekOut: undefined,
          doneTotalWorkCostHol: undefined,
          doneTotalWorkCostHolOut: undefined,
          doneTotalWorkCostForeign: undefined,
          quotationTariffPerHour: undefined,
          quotationTariffPerHourWeekOut: undefined,
          quotationTariffPerHourHol: undefined,
          quotationTariffPerHourHolOut: undefined,
          quotationTariffPerHourForeign: undefined,
          quotationInspectionTime: undefined,
          quotationInspectionTimeWeekOut: undefined,
          quotationInspectionTimeHol: undefined,
          quotationInspectionTimeHolOut: undefined,
          quotationInspectionTimeForeign: undefined,
          quotationTotalWorkCost: undefined,
          quotationTotalWorkCostWeekOut: undefined,
          quotationTotalWorkCostHol: undefined,
          quotationTotalWorkCostHolOut: undefined,
          quotationTotalWorkCostForeign: undefined,
          planPartsCost: recommendPrice(),
          donePartsCost: undefined,
          quotationPartsCost: undefined,
          expenses: undefined,
          doneExpenses: undefined,
          quotationExpenses: undefined,
          inspectionDetailClassValue: undefined,
          quotationNo: undefined,
          quotationStatusClassValue: undefined,
          orderNo: undefined,
          orderStatusClassValue: undefined,
          quotationUserId: undefined,
          updateDateTime: undefined,
        });
      }
    });
  }
  return tmp;
}
