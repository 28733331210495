import {
  ColGroupDef,
  ICellRendererParams,
  IHeaderParams,
  RowClassParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, RefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthUserContext } from "src/context/AuthUser";
import { MaintenanceSchedulesGetResponse } from "src/model/api/response/MaintenanceSchedulesGetResponse";
import { agJapanCurrencyFormatter } from "src/util/AgGridUtil";
import { QuotationStatus } from "src/util/Constant";

import {
  DISCONTINUED_FLG,
  DISPLAY_CODE,
  DISPLAY_LARGE,
  DISPLAY_MIDDLE,
  DONE,
  DONE_SUBTOTAL,
  MIDDLE_CLASS_VALUE,
  PRODUCT_GOODS_CODE,
  PRODUCT_GROUP_NAME,
  QUANTITY,
  QUOTATION,
  QUOTATION_SUBTOTAL,
  RECOMMEND,
  RECOMMEND_SUBTOTAL,
  REPLACEMENT_FLG,
  SPEC,
  TIMINGS,
  TIMING_MAIN,
  TIMING_SUBS,
} from "../../util/lcm/LcmConst";
import { GridRecommendProductType } from "./RepairSchedule";
import RepairScheduleAccordionArea from "./RepairScheduleAccordionArea";

type Props = {
  gridVesselProductList: GridRecommendProductType[] | null;
  costVisible: boolean;
  selectedSummaryDisplay: string;
  lastImportCsvDateTIme: string | undefined;
  completedYear: number;
  noRowsText: string;
  maintenaceScheduleDataList: MaintenanceSchedulesGetResponse[];

  gridRefRecommendProduct: RefObject<AgGridReact<any>>;

  handleChangeCostDisplayToggle: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleClickSummaryDisplayButton: (display: string) => void;
  handleChangeCheckBox: (
    name: string,
    e: ChangeEvent<HTMLInputElement>,
    rowIndex?: number
  ) => void;

  editableRecommendProductsArea: () => boolean;
  scrollIndex: number | undefined;
  setScrollindex: (value: number | undefined) => void;
  yearCheckValue: number;
};

function RepairScheduleRecommendProduct(props: Props) {
  // ユーザ情報
  const auth = useAuthUserContext();
  // 翻訳
  const { t: tc } = useTranslation("RepairScheduleRecommendProduct");

  // 集約表示リスト
  const displayList = auth.user().bemacFlg
    ? [
        { value: DISPLAY_CODE, text: "Code" },
        { value: DISPLAY_LARGE, text: tc("大分類") },
        { value: DISPLAY_MIDDLE, text: tc("中分類") },
      ]
    : [
        { value: DISPLAY_LARGE, text: tc("大分類") },
        { value: DISPLAY_MIDDLE, text: tc("中分類") },
        { value: DISPLAY_CODE, text: "Code" },
      ];

  // 部品交換内容情報のアコーディオン開閉状態
  const [showGoodsAccordion, setGoodsAccordion] = useState(true);
  // 見積列ヘッダのチェックボックスの選択状態（必要な列数分falseで埋めた配列）
  const [quotationHeaderSelectList, setQuotationHeaderSelectList] = useState(
    TIMINGS.map(() => false)
  );

  //レンダリング完了後、１度だけ実行(再レンダリングも含む)
  //scrollIndexを初期化しているのは同じチェックボックスを連続でクリックした際でも動くようにするため。
  useEffect(() => {
    if (props.scrollIndex) {
      //指定行番号が中央に来るようにスクロールする
      props.gridRefRecommendProduct.current?.api.ensureIndexVisible(
        props.scrollIndex,
        "middle"
      );
      //行番号を初期化
      props.setScrollindex(undefined);
    }
  });

  // スタイル
  function statusButtonStyle(status: string) {
    // 選択中のステータスと一致していたらオレンジにする
    if (status === props.selectedSummaryDisplay) {
      return { backgroundColor: "#fd6b00", color: "white" };
    } else {
      return { backgroundColor: "white", color: "black" };
    }
  }

  // 部品交換内容エリアのチェックボックスセルレンダラー
  function checkBoxValueCell(params: ICellRendererParams) {
    if (params.data.display) {
      //大分類、中分類、合計の列の場合は空白で返す
      return <></>;
    } else {
      let checked: boolean;
      let disabledFlg = false;
      let inspectionYearClassValue = "";

      switch (params.column?.getColId()) {
        case QUOTATION + "1":
          checked = params.data.quotation1;
          inspectionYearClassValue = "1";
          break;
        case QUOTATION + "2":
          checked = params.data.quotation2;
          inspectionYearClassValue = "2";
          break;
        case QUOTATION + "3":
          checked = params.data.quotation3;
          inspectionYearClassValue = "3";
          break;
        case QUOTATION + "4":
          checked = params.data.quotation4;
          inspectionYearClassValue = "4";
          break;
        case QUOTATION + "5":
          checked = params.data.quotation5;
          inspectionYearClassValue = "5";
          break;
        case QUOTATION + "6":
          checked = params.data.quotation6;
          inspectionYearClassValue = "6";
          break;
        case QUOTATION + "7":
          checked = params.data.quotation7;
          inspectionYearClassValue = "7";
          break;
        case QUOTATION + "8":
          checked = params.data.quotation8;
          inspectionYearClassValue = "8";
          break;
        case QUOTATION + "9":
          checked = params.data.quotation9;
          inspectionYearClassValue = "9";
          break;
        case QUOTATION + "10":
          checked = params.data.quotation10;
          inspectionYearClassValue = "10";
          break;
        case QUOTATION + "11":
          checked = params.data.quotation11;
          inspectionYearClassValue = "11";
          break;
        case QUOTATION + "12":
          checked = params.data.quotation12;
          inspectionYearClassValue = "12";
          break;
        case QUOTATION + "13":
          checked = params.data.quotation13;
          inspectionYearClassValue = "13";
          break;
        case QUOTATION + "14":
          checked = params.data.quotation14;
          inspectionYearClassValue = "14";
          break;
        case QUOTATION + "15":
          checked = params.data.quotation15;
          inspectionYearClassValue = "15";
          break;
        case QUOTATION + "16":
          checked = params.data.quotation16;
          inspectionYearClassValue = "16";
          break;
        case QUOTATION + "17":
          checked = params.data.quotation17;
          inspectionYearClassValue = "17";
          break;
        case QUOTATION + "18":
          checked = params.data.quotation18;
          inspectionYearClassValue = "18";
          break;
        case QUOTATION + "19":
          checked = params.data.quotation19;
          inspectionYearClassValue = "19";
          break;
        case QUOTATION + "20":
          checked = params.data.quotation20;
          inspectionYearClassValue = "20";
          break;
        case QUOTATION + "21":
          checked = params.data.quotation21;
          inspectionYearClassValue = "21";
          break;
        case QUOTATION + "22":
          checked = params.data.quotation22;
          inspectionYearClassValue = "22";
          break;
        case QUOTATION + "23":
          checked = params.data.quotation23;
          inspectionYearClassValue = "23";
          break;
        case QUOTATION + "24":
          checked = params.data.quotation24;
          inspectionYearClassValue = "24";
          break;
        case QUOTATION + "25":
          checked = params.data.quotation25;
          inspectionYearClassValue = "25";
          break;
        case QUOTATION + "26":
          checked = params.data.quotation26;
          inspectionYearClassValue = "26";
          break;
        case QUOTATION + "27":
          checked = params.data.quotation27;
          inspectionYearClassValue = "27";
          break;
        case QUOTATION + "28":
          checked = params.data.quotation28;
          inspectionYearClassValue = "28";
          break;
        case QUOTATION + "29":
          checked = params.data.quotation29;
          inspectionYearClassValue = "29";
          break;
        case QUOTATION + "30":
          checked = params.data.quotation30;
          inspectionYearClassValue = "30";
          break;
        default:
          checked = false;
          break;
      }

      // 見積作成中且つ、見積依頼中でない場合非活性にする
      disabledFlg = isQuotationReplied(inspectionYearClassValue);
      const maintenance = props.maintenaceScheduleDataList.find(
        (it) => it.inspectionYearClassValue === inspectionYearClassValue
      );
      return (
        <div
          className="form-check  mt-1"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.editableRecommendProductsArea() && (
            <input
              className="form-check-input"
              type="checkbox"
              disabled={disabledFlg}
              checked={checked}
              onChange={(e) => {
                props.handleChangeCheckBox(
                  params.colDef!.field!,
                  e,
                  params.rowIndex
                );
              }}
              data-cy="見積チェックボックス"
            />
          )}
          {(auth.isEngineer() ||
            (!auth.user().bemacFlg &&
              maintenance?.quotationStatusClassValue !==
                QuotationStatus.DRAFT)) && (
            <label data-cy="見積チェック">{checked ? "✓" : ""}</label>
          )}
        </div>
      );
    }
  }

  function getRowStyle(params: RowClassParams<GridRecommendProductType>) {
    if (params.data?.discontinuedFlg) {
      return { backgroundColor: "#b7bdc7" };
    }
    return undefined;
  }

  // 指定年度の見積が回答済みかどうか
  function isQuotationReplied(inspectionYearClassValue: string) {
    // 該当年度におけるメンテナンススケジュール(定期点検・部品交換情報を暗唱)
    const maintenance = props.maintenaceScheduleDataList.find(
      (it) => it.inspectionYearClassValue === inspectionYearClassValue
    );

    // 点検内訳に応じた明細の追加
    if (maintenance) {
      if (
        (maintenance.quotationNo &&
          maintenance.quotationStatusClassValue !== QuotationStatus.REQUESTED &&
          maintenance.quotationStatusClassValue !== QuotationStatus.DRAFT) ||
        maintenance?.orderCreateUserId === -1000
      ) {
        // 見積作成中且つ、見積依頼中でない場合非活性にする
        return true;
      }
    }
    return false;
  }

  // 見積列のヘッダーコンポーネント
  interface QuotationHeaderProps extends IHeaderParams {
    year: number;
  }
  const QuotationHeader: React.FC<QuotationHeaderProps> = (param) => {
    function handleChange(e: ChangeEvent<HTMLInputElement>) {
      // 選択状態をstateに保存
      setQuotationHeaderSelectList(
        quotationHeaderSelectList.map((it, index) => {
          if (TIMINGS.indexOf(param.year) === index) {
            return e.target.checked;
          } else {
            return it;
          }
        })
      );

      param.api.forEachNode((node) => {
        if (e.target.checked) {
          if (node.data[RECOMMEND + param.year]) {
            // チェックされたら予定に●の行を選択状態にする
            node.setDataValue(QUOTATION + param.year, true);
          }
        } else {
          // チェックが外されたらすべて未選択状態にする
          node.setDataValue(QUOTATION + param.year, false);
        }
      });

      // 再計算
      props.handleChangeCheckBox(QUOTATION + param.year, e);
    }

    // レンダリング
    return (
      <span className="ag-header-cell-label">
        {tc("見積")}
        {props.editableRecommendProductsArea() &&
          !isQuotationReplied(param.year.toString()) && (
            <input
              type="checkbox"
              className="form-check-input ms-2"
              checked={quotationHeaderSelectList[TIMINGS.indexOf(param.year)]}
              onChange={handleChange}
            />
          )}
      </span>
    );
  };

  // 部品交換内容グリッドの列定義
  const columnDefsRecommendProduct: ColGroupDef[] = [
    {
      headerName: tc("部品一覧"),
      children: [
        {
          headerName: tc("大分類"),
          field: "largeClassName",
          width: 100,
          resizable: true,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: "largeClassValue",
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          pinned: true,
        },
        {
          headerName: tc("中分類"),
          field: MIDDLE_CLASS_VALUE,
          width: 100,
          resizable: true,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: MIDDLE_CLASS_VALUE,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          pinned: true,
        },
        {
          headerName: tc("廃盤品"),
          field: "discontinuedFlg",
          width: 100,
          resizable: true,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: DISCONTINUED_FLG,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          cellRenderer: (params: any) => {
            return <>{params.data.discontinuedFlg ? "●" : ""}</>;
          },
        },
        {
          headerName: tc("代替"),
          field: "replacementFlg",
          width: 100,
          resizable: true,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: REPLACEMENT_FLG,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          cellRenderer: (params: any) => {
            return <>{params.data.replacementFlg ? "●" : ""}</>;
          },
        },
        {
          headerName: "Code",
          field: PRODUCT_GOODS_CODE,
          width: 100,
          resizable: true,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: PRODUCT_GOODS_CODE,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          pinned: true,
        },
        {
          headerName: tc("品名"),
          field: PRODUCT_GROUP_NAME,
          width: 200,
          resizable: true,
          lockPosition: "left",
          headerClass: [
            "b-header-cell-center",
            "b-cell-border-right-solid-thin-gray",
          ],
          colId: PRODUCT_GROUP_NAME,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          pinned: true,
        },
        {
          headerName: tc("型式"),
          field: SPEC,
          width: 300,
          resizable: true,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: SPEC,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          pinned: true,
        },
        {
          headerName: tc("数量"),
          field: QUANTITY,
          width: 80,
          resizable: true,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: QUANTITY,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          pinned: true,
        },
      ],
      headerClass: ["b-cell-border-right-solid-thin-gray"],
    },
  ];

  (props.yearCheckValue === 0 ? TIMING_MAIN : TIMINGS).forEach((it) => {
    columnDefsRecommendProduct.push({
      headerName:
        props.yearCheckValue === 0
          ? (TIMING_SUBS.includes(it) ? it + 0.5 : it) +
            tc("年(") +
            (props.completedYear + it) +
            tc("年)")
          : it + tc("年(") + (props.completedYear + it) + tc("年)"),
      children: [
        {
          headerName: tc("予定"),
          field: RECOMMEND + it,
          width: 100,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: RECOMMEND + it,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
        },
        {
          headerName: tc("実施"),
          field: DONE + it,
          width: 100,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: DONE + it,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
        },
        {
          headerName: tc("見積"),
          field: QUOTATION + it,
          width: 100,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          headerComponent: QuotationHeader,
          headerComponentParams: { year: it },
          colId: QUOTATION + it,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          cellRenderer: checkBoxValueCell,
        },
        {
          headerName: tc("予定金額"),
          field: RECOMMEND_SUBTOTAL + it,
          width: 150,
          resizable: true,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: RECOMMEND_SUBTOTAL + it,
          hide: !props.costVisible,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          valueFormatter: agJapanCurrencyFormatter,
        },
        {
          headerName: tc("実施金額"),
          field: DONE_SUBTOTAL + it,
          width: 150,
          resizable: true,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: DONE_SUBTOTAL + it,
          hide: !props.costVisible,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          valueFormatter: agJapanCurrencyFormatter,
        },
        {
          headerName: tc("見積金額"),
          field: QUOTATION_SUBTOTAL + it,
          width: 150,
          resizable: true,
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          colId: QUOTATION_SUBTOTAL + it,
          hide: !props.costVisible,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
          cellClassRules: {
            "b-cell-backgroupd-color-gray": (params: any) =>
              params.data.display === "SUM",
          },
          valueGetter: (params) => {
            // 実績移行データの場合は非表示にする
            const maintenance = props.maintenaceScheduleDataList.find(
              (row) => row.inspectionYearClassValue === String(it)
            );
            if (
              maintenance?.orderCreateUserId === -1000 ||
              (!auth.user().bemacFlg &&
                maintenance?.quotationStatusClassValue == QuotationStatus.DRAFT)
            ) {
              return "";
            } else {
              if (it === 1) {
                return params.data.quotationSubtotal1;
              } else if (it === 2) {
                return params.data.quotationSubtotal2;
              } else if (it === 3) {
                return params.data.quotationSubtotal3;
              } else if (it === 4) {
                return params.data.quotationSubtotal4;
              } else if (it === 5) {
                return params.data.quotationSubtotal5;
              } else if (it === 6) {
                return params.data.quotationSubtotal6;
              } else if (it === 7) {
                return params.data.quotationSubtotal7;
              } else if (it === 8) {
                return params.data.quotationSubtotal8;
              } else if (it === 9) {
                return params.data.quotationSubtotal9;
              } else if (it === 10) {
                return params.data.quotationSubtotal10;
              } else if (it === 11) {
                return params.data.quotationSubtotal11;
              } else if (it === 12) {
                return params.data.quotationSubtotal12;
              } else if (it === 13) {
                return params.data.quotationSubtotal13;
              } else if (it === 14) {
                return params.data.quotationSubtotal14;
              } else if (it === 15) {
                return params.data.quotationSubtotal15;
              } else if (it === 16) {
                return params.data.quotationSubtotal16;
              } else if (it === 17) {
                return params.data.quotationSubtotal17;
              } else if (it === 18) {
                return params.data.quotationSubtotal18;
              } else if (it === 19) {
                return params.data.quotationSubtotal19;
              } else if (it === 20) {
                return params.data.quotationSubtotal20;
              } else if (it === 21) {
                return params.data.quotationSubtotal21;
              } else if (it === 22) {
                return params.data.quotationSubtotal22;
              } else if (it === 23) {
                return params.data.quotationSubtotal23;
              } else if (it === 24) {
                return params.data.quotationSubtotal24;
              } else if (it === 25) {
                return params.data.quotationSubtotal25;
              } else if (it === 26) {
                return params.data.quotationSubtotal26;
              } else if (it === 27) {
                return params.data.quotationSubtotal27;
              } else if (it === 28) {
                return params.data.quotationSubtotal28;
              } else if (it === 29) {
                return params.data.quotationSubtotal29;
              } else if (it === 30) {
                return params.data.quotationSubtotal30;
              }
            }
          },
          valueFormatter: agJapanCurrencyFormatter,
        },
      ],
      headerClass: ["b-cell-border-right-solid-thin-gray"],
    });
  });

  return (
    <>
      <RepairScheduleAccordionArea
        className="w-100 mt-3"
        show={showGoodsAccordion}
        onChangeShow={(value) => {
          setGoodsAccordion(value);
        }}
        title={tc("部品交換内容")}
      >
        <div>
          <>
            {props.gridVesselProductList &&
              props.gridVesselProductList.length > 0 && (
                <div className="mx-3 mt-3">
                  <div className="input-group">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {tc("金額表示")}
                    </span>
                    <div
                      className="form-check form-switch"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "1.5",
                      }}
                    >
                      <input
                        className="form-check-input"
                        style={{ marginLeft: "-2rem" }}
                        type="checkbox"
                        name="replyInstallmentDeliveryFlg"
                        checked={props.costVisible}
                        onChange={props.handleChangeCostDisplayToggle}
                        data-cy="金額表示チェックボックス"
                      />
                    </div>
                  </div>
                </div>
              )}
          </>
          <>
            {props.gridVesselProductList &&
              props.gridVesselProductList.length > 0 && (
                <div className="mx-3 mt-3">
                  <div className="d-flex align-items-center">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {tc("集約表示")}
                    </span>
                    <div className="ms-2">
                      {displayList.map((it) => {
                        return (
                          <input
                            key={it.value}
                            type="button"
                            className="btn rounded-pill me-2"
                            style={statusButtonStyle(it.value)}
                            value={it.text}
                            onClick={(e) =>
                              props.handleClickSummaryDisplayButton(it.value)
                            }
                            data-cy="集約表示ボタン"
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className="w-100 text-end"
                    data-cy="交換推奨部品リスト取込日時エリア"
                  >
                    {tc("交換推奨部品リスト取込日時")}:
                    {props.lastImportCsvDateTIme}
                  </div>
                </div>
              )}
          </>
          <div
            className="ag-theme-alpine w-100 mx-auto b-grid-outer"
            style={{ height: 450 }}
            data-cy="部品交換内容グリッド"
          >
            <AgGridReact
              columnDefs={columnDefsRecommendProduct}
              ref={props.gridRefRecommendProduct}
              rowDragManaged={true}
              overlayNoRowsTemplate={props.noRowsText}
              rowData={props.gridVesselProductList}
              getRowStyle={getRowStyle}
            />
          </div>
        </div>
      </RepairScheduleAccordionArea>
    </>
  );
}

export default RepairScheduleRecommendProduct;
