import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ServiceNewsList from "./ServiceNewsList";

// 公開する関数の定義
export interface ServiceNewsListModalHandles {
  show(): void;
}

type Props = {
  onClosed?: () => void;
};

/**サービスニュースモーダルコンポーネント */

const ServiceNewsListModal = forwardRef<ServiceNewsListModalHandles, Props>(
  (props, ref) => {
    // 翻訳
    const { t: tc } = useTranslation("ServiceNewsListModal");

    // 公開する関数の実装
    useImperativeHandle(ref, () => ({
      show() {
        setShow(true);
      },
    }));

    // state
    const [show, setShow] = useState(false);

    // モーダルクローズハンドラ
    const handleClose = () => {
      if (props.onClosed) {
        //Top情報取得API実行
        props.onClosed();
      }
      setShow(false);
    };

    // レンダリング
    return (
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        scrollable
        data-cy="サービスニュース一覧モーダル"
      >
        <Modal.Header closeButton>
          <Modal.Title>{tc("ServiceNews一覧")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{<ServiceNewsList isModal />}</Modal.Body>

        <Modal.Footer>
          <Button
            className="b-btn-close"
            onClick={handleClose}
            data-cy="Closeボタン"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default ServiceNewsListModal;
